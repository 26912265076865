import React, { createContext, useState, useReducer } from 'react'
import PaperForm from '../forms/PaperForm'
import NormaForm from '../forms/NormaForm'
import PlataformaForm from '../forms/PlataformaForm'
import AudioVisualForm from '../forms/AudioVisualForm'
import OrganizacionPacienteForm from '../forms/OrganizacionPacienteForm'
import ProfesionalForm from '../forms/ProfesionalForm'
import InvestigacionForm from '../forms/InvestigacionForm'
import EditList from '../edit/EditList'
import styled from 'styled-components'

const TitleWelcome = styled.h1`
    font-size:2rem;
    font-family:"Poppins"

`
 

const AdminContext = createContext();

const AdminDispatch = (state, action) =>{

    switch(action.type){
        case "Papers":
            if(action.payload.editID === "Papers"){
                return   <EditList srcData={action.payload.editID}></EditList>
            }
            return   <PaperForm></PaperForm>
        break
        case "Normas":
            if(action.payload.editID === "Normas"){
                return   <EditList srcData={action.payload.editID}></EditList>
            }
            return <NormaForm></NormaForm>
        break
        case "Plataformas":
            if(action.payload.editID === "Plataformas"){
                return   <EditList srcData={action.payload.editID}></EditList>
            }
            return <PlataformaForm></PlataformaForm>
        break
        case "AudioVisual":
            if(action.payload.editID === "AudioVisuales"){
                return   <EditList srcData={action.payload.editID}></EditList>
            }
            return <AudioVisualForm></AudioVisualForm>
        break
        case "OrganizacionPacientes":
            if(action.payload.editID === "OrganizacionPacientes"){
                return   <EditList srcData={action.payload.editID}></EditList>
            }
            return <OrganizacionPacienteForm></OrganizacionPacienteForm>
        break
        case "Profesionales":
            if(action.payload.editID === "Profesionales"){
                return   <EditList srcData={action.payload.editID}></EditList>
            }
            return <ProfesionalForm></ProfesionalForm>
        break
        case "Investigacion":
            if(action.payload.editID === "Investigacion"){
                return   <EditList srcData={action.payload.editID}></EditList>
            }
            return <InvestigacionForm></InvestigacionForm>
        break
        case "Home": 
            return (<div>
                <h1>Bienvenido Administrador</h1>
                <p>Por favor escoge una de las opciones en la barra lateral</p>
                </div>)
        default:
            return state
        break
    }
}
const AdminStatus = props =>{
    const [results, dispatch] = useReducer(AdminDispatch)
    return(
        <AdminContext.Provider value = {{
            results: results,
            resultsDispatch: dispatch,
            }}>
                {props.children}
        </AdminContext.Provider>
    )
}

export { AdminStatus, AdminContext}