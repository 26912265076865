import React from 'react'
import styled from 'styled-components'

const InputBar = styled.div`
    width: ${props=>props.widthbar || "10px"};
    height: ${props=>props.heightbar || "auto"};
    margin: ${props=>props.marginbar || "none"};
    padding: ${props=>props.paddingbar || "none"};
    position: relative;
    display: inline-flex;
    background-color: ${props=>props.backgroundColor || "#6C0E60"} !important;
    color:${props=>props.color || "#6C0E60"};
    border-color: ${props=>props.borderColor || "#6C0E60"};

    @media only screen and (max-width: 600px) {
        display:${props=>props.displaybar || "block"}
    }
`
const InputContent = styled.input`
    width: 99%;
    display: inline-flex;
    height:50px;
    font-size:1.3rem;
    @media only screen and (max-width: 600px) {
        width: 99%;
        display: inline-flex;
        height:30px;
        font-size:1rem;
    }
`
const Label = styled.label`
    font-family: 'Roboto';
    line-height: 2;
    text-align: left;
    display: block;
    margin-bottom: 13px;
    margin-top: 20px;
    font-size: 14px;
    font-weight: 700;
`
const LabelBiblioteca = styled.label`
    font-family: 'Roboto';
    line-height: 2;
    text-align: ${props => props.textAlign || "left"};
    display: ${props => props.displayLabel || "inline-flex"};
    font-size: 14px;
    font-weight: 700;
    margin: 0 20px;
`

const InputSubmit = styled.input`
  width:${props => props.width || "200px"};
  height:${props => props.height || "100px"};
  background-color:${props=>props.background || "#6C0E60"};
  color:white;
  border-radius: 3px;
  border: ${props => props.borderdata || "2px solid #6C0E60"};
  margin: ${props => props.margin || "3rem 1rem"}; 
  padding: 0.25em 1em;
  &:hover{
    background: transparent;
    color: #6C0E60;
    border: 2px solid #6C0E60;
  }
  @media only screen and (max-width: 600px) {
    width:${props => props.width || "200px"};
    height:${props => props.height || "50px"};
    background-color:${props=>props.background || "#6C0E60"};
    color:white;
    border-radius: 3px;
    border: ${props => props.borderdata || "2px solid #6C0E60"};
    margin: ${props => props.margin || "3rem 1rem"}; 
    padding: 0.25em 1em;
    &:hover{
        background: transparent;
        color: #6C0E60;
        border: 2px solid #6C0E60;
    }
 }
`

const SelectElement = styled.select`
    width:${props => props.size || "99%"};
    background-color:white;
    height:50px;
`
const SelectOptionElement = styled.option`
    width:${props => props.size || "99%"};
    background-color:white;
`
const TextAreaElement = styled.textarea`
    width:${props => props.size || "99%"};
`

const InputCheckbox = styled.input.attrs({ type: 'checkbox' })`

`
const InputBase =   <div style={{display:'inline-flex'}} className="slide-in-elliptic-top-fwd">
                    <InputBar/>
                    <InputContent/>
                    </div>

export {
    InputContent,
    InputBar,
    Label,
    InputSubmit,
    SelectElement,
    TextAreaElement,
    SelectOptionElement,
    InputCheckbox,
    LabelBiblioteca
}