import React, {useContext} from 'react'
import { withAuthenticator } from '@aws-amplify/ui-react'
import LayoutAdmin from '../layout/LayoutAdmin'

function HomeAdmin(props){
    return(
        <>
        <LayoutAdmin style={{margin:"0", padding:"0"}}>
        </LayoutAdmin>
        </>
    )
}

export default HomeAdmin /* withAuthenticator(HomeAdmin) */