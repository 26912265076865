import React, {useEffect, useState} from 'react'
import {Typography, Divider, Button, Row, Col, notification} from 'antd'
import Amplify, { API, graphqlOperation, Storage } from 'aws-amplify'
import { getNewOrganizacionPaciente, listNewOrganizacionPacientes } from '../../../graphql/queries'
import {MainTitle, ErrorForm} from '../../Datadisplay'
import Paper  from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import styled from 'styled-components'
import {DoubleRightOutlined} from '@ant-design/icons'
import { updateNewOrganizacionPaciente } from '../../../graphql/mutations'
import { useForm } from "react-hook-form";
import {InputBar, InputContent,Label,InputSubmit, SelectElement,TextAreaElement,SelectOptionElement} from '../../Input'
import { v4 as uuid } from 'uuid'
import config from '../../../aws-exports'
import '../../../styles/styles.css'

const {
    aws_user_files_s3_bucket_region: region,
    aws_user_files_s3_bucket: bucket
  } = config

const TitleDetail = styled.h1`
 font-size:2rem;
 font-family: "Roboto";
 color: #6C0E60;
 text-align: left;
`
const SubtitleDetail = styled.p`
 font-size:1rem;
 font-family: "Roboto";
 text-align: left;
 font-weight: 200
 font-variant: italic
`
const InfoDetailedTitle = styled.p`
 font-size:1rem;
 font-family: "Roboto";
 color: #6C0E60;
 text-align: left;
 font-weight: 700;
`

const dataOrganizacion = 
{
    id:"",
    Nombre: "Cargando..",
    Nombre_orig: "",
    Logo: "",
    Logo_orig: "",
    LogoS3: "",
    LogoS3_orig: "",
    Ciudad: "",
    Ciudad_orig: "",
    URL: "",
    URL_orig: "",
    Tipo: "",
    Tipo_orig: ""
}


const OrganizacionEditForm= (props)=>{
    const {register, handleSubmit, errors} = useForm()
    const [organizacionID, setorganizacionID] = useState(props.idOrganizacion)
    let [info, setinfo] = useState(dataOrganizacion)
    const [alert, setAlert] = useState()
    let [organizacionElement,setorganizacionElement] = useState({
        Nombre: "",
        Nombre_orig: "",
        Logo: "",
        Logo_orig: "",
        LogoS3: "",
        LogoS3_orig: "",
        Ciudad: "",
        Ciudad_orig: "",
        URL: "",
        URL_orig: "",
        Tipo: "",
        Tipo_orig: ""
    })
    const [file, updateFile] = useState(null)
    const [OrganizacionName, updateOrganizacionName] = useState('')
    const [Organizacions, updateOrganizacions] = useState([])
    let [FilePreviewImg, setFilePreviewImg] = useState()
    let [extensionName, setextensionName] = useState('')

    const SuccessAlert = () => {notification["success"]({
        message: 'Enhorabuena!',
        description:
          'Se ha creado el paper con exito',
    });
    }

    useEffect(() => {
        listOrganizaciones()
      }, [])
    
    async function listOrganizaciones() {
        const organizaciones = await API.graphql(graphqlOperation(listNewOrganizacionPacientes))
        updateOrganizacions(organizaciones.data.listNewOrganizacionPacientes.items)
    }

    function handleChange(event) {
        const filePreview = event.target.files[0]
        const filePreviewCreate = window.webkitURL.createObjectURL(filePreview)
        setFilePreviewImg(filePreviewCreate)
        const { target: { value, files } } = event
        const fileForUpload = files[0]
        updateOrganizacionName(fileForUpload.name.split(".")[0])
        setextensionName(fileForUpload.name)
        updateFile(fileForUpload || value)
      }
    
    useEffect(()=>{
        setorganizacionElement({
            Nombre: info.Nombre_orig,
            Logo: info.Logo_orig,
            LogoS3: info.LogoS3_orig,
            Ciudad: info.Ciudad_orig,
            URL: info.URL_orig,
            Tipo: info.Tipo_orig,
        })
    },[info])

    useEffect( async ()=>{
        const Dataorganizacion = await API.graphql(graphqlOperation(getNewOrganizacionPaciente, {id:organizacionID}))
        setinfo(Dataorganizacion.data.getNewOrganizacionPaciente)
      },[]) 

    const removeAccents = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    } 

    const UpdateData = async (data,e) => {
        let inputData = null
        if (file) {
            const extension = file.name.split(".")[1]
            const { type: mimeType } = file
            const key = `images/organizaciones/${uuid()}${OrganizacionName}.${extension}`      
            const url = `https://${bucket}.s3.${region}.amazonaws.com/public/${key}`
            inputData = { name: OrganizacionName , image: url }
    
            try {
                await Storage.put(key, file, {
                contentType: mimeType
                })
            } catch (err) {
                console.log('error: ', err)
            }
        }
      const organizacionUpdateID = organizacionID.toString()
      await API.graphql({query:updateNewOrganizacionPaciente, variables: { input: { 
        id:organizacionUpdateID,
        Nombre: removeAccents(data.Nombre).toLowerCase(),
        Nombre_orig: data.Nombre,
        Logo: removeAccents(inputData.image).toLowerCase(),
        Logo_orig: inputData.image,
        Ciudad: removeAccents(data.Ciudad).toLowerCase(),
        Ciudad_orig: data.Ciudad,
        URL: removeAccents(data.URL).toLowerCase(),
        URL_orig: data.URL,
        Tipo: removeAccents(data.Tipo).toLowerCase(),
        Tipo_orig: data.Tipo,
      }}})
      setAlert(SuccessAlert)
      e.target.reset()
    }
    
    return(
        <div style={{padding: "3%"}}>
            <Paper style={{padding: "3%"}}  elevation={3}>
            <Row>
                <Col span={12} style={{padding:"0 4% 0 0"}}>
                <img src={info.Logo_orig} style={{width:"50%"}}/>
                <TitleDetail>{info.Nombre_orig}</TitleDetail>
                <SubtitleDetail><i>{info.Tipo_orig}</i></SubtitleDetail>
                <SubtitleDetail><strong style={{color:"#6C0E60"}}>Ciudad</strong></SubtitleDetail>
                <Divider className="detailpaper-divider"/>
                <div>
                    <Row>
                        <Col span={24}><p style={{textAlign:"left"}}>{info.Ciudad_orig}</p></Col>
                    </Row> 
                </div>
                <div>
                    <Row>
                        <Col span={24}>
                            <List>
                                <ListItem>
                                    <Row className="detailpaper-row-list">
                                        <Col span={24}>
                                            <div style={{display: "inline-flex", alignContent:"center", alignItems:"baseline"}}>
                                                <DoubleRightOutlined style={{margin: "0px 10px 0px 0px"}} /><InfoDetailedTitle>URL</InfoDetailedTitle>
                                            </div>
                                            
                                            <p>{info.URL_orig}</p>
                                        </Col>
                                    </Row>
                                </ListItem>
                                <Divider className="detailpaper-divider-list"/>
                            </List>
                        </Col>
                        <Col span={6}><div></div></Col>
                    </Row> 
                </div>
                </Col>
                <Col span={12}>
                <div className="scale-in-hor-left">
                    <MainTitle size="4rem" aligntext="left">Formulario de Organización</MainTitle>
                    <form style={{paddingRight:"10%"}} onSubmit={handleSubmit(UpdateData)}>

                        <Label>Tipo de Organización</Label>

                <div style={{display:'inline-flex', width:"99%"}}>
                <InputBar/>
                    <SelectElement name="Tipo" onChange={(event)=>setorganizacionElement({...organizacionElement, Tipo:event.target.value})} value={organizacionElement.Tipo} ref={register}>
                        <SelectOptionElement value="Nacional">Nacional</SelectOptionElement>
                        <SelectOptionElement value="Internacional">Internacional</SelectOptionElement>
                    </SelectElement>
                </div>

                <Label>Nombre</Label>

                <div style={{display:'inline-flex', width:"99%"}}>
                    <InputBar/>
                    <InputContent name="Nombre" onChange={(event)=>setorganizacionElement({...organizacionElement, Nombre:event.target.value})} value={organizacionElement.Nombre} ref={register({ required: true })}/>
                </div>
                <div>{errors.titulo && <ErrorForm>Este campo es requerido</ErrorForm>}</div>

                <Label>Logo URL</Label>

                <div style={{display:'block', width:"99%"}}>
                <input
                        placeholder='Nombre del archivo'
                        value={OrganizacionName}
                        onChange={e => updateOrganizacionName(e.target.value)}
                        style={{height:"100%", background:"transparent", border: "none"}}
                />
                <input
                        type="file"
                        onChange={handleChange}
                        style={{margin: '10px 0px'}}
                        ref={register({ required: true })}
                        id="org-upload"
                    />
                <label htmlFor="org-upload">Cargar Archivo</label>
                </div>

                <img  src={FilePreviewImg} style={{width:"50%", marginTop:"3%"}}/>

                <Label>Ciudad</Label>

                <div style={{display:'inline-flex', width:"99%"}}>
                    <InputBar/>
                    <InputContent name="Ciudad"  onChange={(event)=>setorganizacionElement({...organizacionElement, Ciudad:event.target.value})} value={organizacionElement.Ciudad} ref={register}/>
                </div>

                <Label>URL</Label>

                <div style={{display:'inline-flex', width:"99%"}}>
                    <InputBar/>
                    <InputContent name="URL"  onChange={(event)=>setorganizacionElement({...organizacionElement, URL:event.target.value})} value={organizacionElement.URL} ref={register}/>
                </div>

                <InputSubmit width="30%" height="30%" type="submit" value="ACTUALIZAR ORGANIZACION" />

                    </form>
                </div>
                </Col>
            </Row>
            </Paper>
        </div>
    )
}

export default OrganizacionEditForm