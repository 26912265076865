/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://vdshpb2dbbbwpi4slw5fctm64a.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-3lm4z7xvyvdepc67wrzj7ezram",
    "aws_cognito_identity_pool_id": "us-east-2:374ea252-6ee0-409f-b124-f76d156b9273",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_QosxfT9tq",
    "aws_user_pools_web_client_id": "5pb1dkb7lkeq9vujtag2i1s8ao",
    "oauth": {},
    "aws_user_files_s3_bucket": "fecoerbucket120734-dev",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
