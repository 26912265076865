import React,{useEffect, useState} from 'react'
import Amplify, { API, graphqlOperation } from 'aws-amplify'
import { listNewPaperss, listNewNormas, listNewPlataformas, listNewAudiovisuals, listNewOrganizacionPacientes, listNewProfesionals, listNewInvestigacions } from '../../graphql/queries'
import {InputBar, InputContent, InputCheckbox, InputSubmit, LabelBiblioteca} from '../Input'
import {useForm} from 'react-hook-form'
import {CardEdit} from '../CardsDisplay'
import { Card, Col, Row, Divider, Modal, Empty } from 'antd';
import styled from 'styled-components'
import { LoadingOutlined  } from '@ant-design/icons';
import {Switch } from 'antd';
import {PalabrasClaveLoop, AbstractLoop, AutorLoop, TituloLoop, RevistaLoop, NombreNormaLoop, DescripcionLoop} from '../../components/Filters'


const MainEditTitle = styled.h1`
font-size:2rem;
font-weight:700;
font-family: "Poppins";
text-align:center;
color: #6C0E60;
`

export default function EditList(props){

  let [searchbarresults, setsearchbarresults] = useState([])
  const {register, handleSubmit, errors, control, defaultChecked, reset} = useForm()
  const [showresults, setshowresults] = useState(false)
  let [initialState, setInitialState] = useState(true)
  let [title, settitle] = useState()
  let [Loading, setLoading] = useState(false)
  let [advancedsearch, setadvancedsearch] = useState(false)


  function camelCase(str) { 
    var regex = /[A-Z\xC0-\xD6\xD8-\xDE]?[a-z\xDF-\xF6\xF8-\xFF]+|[A-Z\xC0-\xD6\xD8-\xDE]+(?![a-z\xDF-\xF6\xF8-\xFF])|\d+/g;
    var match = str.match(regex);
    let result = "";
    

      for(let i = 0 , len = match.length; i < len; i++) {
        let currentStr = match[i];
        let tempStr = currentStr.toLowerCase();
        if(i != 0 || i != 1) {
          // convert first letter to upper case (the word is in lowercase) 
            tempStr = tempStr.substr(0, 1).toUpperCase() + tempStr.substr(1);
        }
        result +=tempStr;
      }
      return result.replace(/([a-z])([A-Z])/g, '$1 $2')
      //.replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3')
  } 

  const ResetSystem = (e) =>{
    setsearchbarresults([])
  }

  function getUniqueListBy(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()]
  }



  const onSubmitEdit = async (data) =>{
    setLoading(true)
    let Total = []
    const searchinputData = data.searchbar.toLowerCase().split(" ")
    let searchinputDataCamel = camelCase(data.searchbar).split(" ")
    let searchinputDataUpper = data.searchbar.toUpperCase().split(" ")
    let searchsplitedGeneral = data.searchbar.split(" ")
  
    switch(props.srcData){
      //------------------------------------------------------Papers------------------------------------------------------------------------------------------------
      case "Papers":
        if(advancedsearch === false){
        const PalabrasClave_Comparacion_Lower = await API.graphql({query:listNewPaperss, variables : { filter : { PalabrasClave : { contains : searchinputData[0]} }}})
        const PalabrasClave_Comparacion_Camel = await API.graphql({query:listNewPaperss, variables : { filter : { PalabrasClave : { contains : searchinputDataCamel[0]} }}})
        const PalabrasClave_Comparacion_Upper = await API.graphql({query:listNewPaperss, variables : { filter : { PalabrasClave : { contains : searchinputDataUpper[0]} }}})
        const PalabrasClave_Comparacion_Lower_Orig = await API.graphql({query:listNewPaperss, variables : { filter : { PalabrasClave_orig : { contains : searchinputData[0]} }}})
        const PalabrasClave_Comparacion_Camel_Orig = await API.graphql({query:listNewPaperss, variables : { filter : { PalabrasClave_orig : { contains : searchinputDataCamel[0]} }}})
        const PalabrasClave_Comparacion_Upper_Orig = await API.graphql({query:listNewPaperss, variables : { filter : { PalabrasClave_orig : { contains : searchinputDataUpper[0]} }}})

        let PalabrasClave_Comparacion = PalabrasClave_Comparacion_Lower.data.listNewPaperss.items.concat(
          PalabrasClave_Comparacion_Camel.data.listNewPaperss.items, 
          PalabrasClave_Comparacion_Upper.data.listNewPaperss.items,
          PalabrasClave_Comparacion_Lower_Orig.data.listNewPaperss.items,
          PalabrasClave_Comparacion_Camel_Orig.data.listNewPaperss.items,
          PalabrasClave_Comparacion_Upper_Orig.data.listNewPaperss.items
        )

        let PalabrasClave_Comparacion_Puro = getUniqueListBy(PalabrasClave_Comparacion, 'id')
        console.log(PalabrasClave_Comparacion_Puro)

        const Abstract_Comparacion_Lower = await API.graphql({query:listNewPaperss, variables : { filter : { Abstract : { contains : searchinputData[0]} }}})
        const Abstract_Comparacion_Camel = await API.graphql({query:listNewPaperss, variables : { filter : { Abstract : { contains : searchinputDataCamel[0]} }}})
        const Abstract_Comparacion_Upper = await API.graphql({query:listNewPaperss, variables : { filter : { Abstract : { contains : searchinputDataUpper[0]} }}})
        const Abstract_Comparacion_Lower_Orig = await API.graphql({query:listNewPaperss, variables : { filter : { Abstract_orig : { contains : searchinputData[0]} }}})
        const Abstract_Comparacion_Camel_Orig = await API.graphql({query:listNewPaperss, variables : { filter : { Abstract_orig : { contains : searchinputDataCamel[0]} }}})
        const Abstract_Comparacion_Upper_Orig = await API.graphql({query:listNewPaperss, variables : { filter : { Abstract_orig : { contains : searchinputDataUpper[0]} }}})
          
        let Abstract_Comparacion = Abstract_Comparacion_Lower.data.listNewPaperss.items.concat(
          Abstract_Comparacion_Camel.data.listNewPaperss.items, 
          Abstract_Comparacion_Upper.data.listNewPaperss.items,
          Abstract_Comparacion_Lower_Orig.data.listNewPaperss.items,
          Abstract_Comparacion_Camel_Orig.data.listNewPaperss.items,
          Abstract_Comparacion_Upper_Orig.data.listNewPaperss.items,
        )

        let Abstract_Comparacion_Puro = getUniqueListBy(Abstract_Comparacion, 'id')
        var idsPA = new Set(PalabrasClave_Comparacion_Puro.map(d => d.id));
        Total = [...PalabrasClave_Comparacion_Puro, ...Abstract_Comparacion_Puro.filter(d => !idsPA.has(d.id))];
        console.log(Total)
        }
        if(advancedsearch === true){
        if(data.Autores === "Autores"){ 

          let searchinputDataAutor = data.searchbar.toLowerCase().split(" ")
          let searchinputDataAutorCamel = camelCase(data.searchbar).split(" ")
          let searchinputDataAutorUpper = data.searchbar.toUpperCase().split(" ")
          let TotalAutorArray =  []
          let searchsplited = data.searchbar.toLowerCase().split(" ")
  
          const Autores_Comparacion = await API.graphql({query:listNewPaperss, variables : { filter : { Autores : { contains : searchinputDataAutor[0]} }}})
          const Autores_Comparacion_Camel = await API.graphql({query:listNewPaperss, variables : { filter : { Autores : { contains : searchinputDataAutorCamel[0]} }}})
          const Autores_Comparacion_Upper = await API.graphql({query:listNewPaperss, variables : { filter : { Autores : { contains : searchinputDataAutorUpper[0]} }}})
          
          let Autores_Comparacion_Arr = Autores_Comparacion.data.listNewPaperss.items.concat(Autores_Comparacion_Camel.data.listNewPaperss.items,Autores_Comparacion_Upper.data.listNewPaperss.items)
  
          let Autores_Comparacion_Arr_Puro = getUniqueListBy(Autores_Comparacion_Arr, 'id')
          
          if(searchinputDataAutor.length === 1 ){
            var idsTotalAutores = new Set(Total.map(d => d.id));
            Total = [...Total, ...Autores_Comparacion_Arr_Puro.filter(d => !idsTotalAutores.has(d.id))];
          }
          else{
            if(Autores_Comparacion_Arr_Puro.length === 0){
              return null
            }
              Autores_Comparacion_Arr_Puro.map((item)=>{
              let AutoresComparation = getUniqueListBy(AutorLoop(item, searchinputData), 'id')
              TotalAutorArray = [...TotalAutorArray, AutoresComparation] 
            })
  
            let TotalFilteredArray = TotalAutorArray.filter(item => item.length !== 0)
            let Final = getUniqueListBy(TotalFilteredArray)

            for (let index = 0; index < Final.length; index++) {
              const element = Final[index];
              let GenericArray = []
              let GeneralFinal = GenericArray.concat(element)
              Total = [...Total, ...GeneralFinal]
            }
          }
          } 
          
          if(data.Titulos === "Titulos"){
            let searchinputDataTitulos = data.searchbar.toLowerCase().split(" ")
            let searchinputDataTitulosCamel = camelCase(data.searchbar).split(" ")
            let searchinputDataTitulosUpper = data.searchbar.toUpperCase().split(" ")
            let TotalTituloArray =  []
  
            const Titulos_Comparacion_Lower = await API.graphql({query:listNewPaperss, variables : { filter : { titulo : { contains : searchinputDataTitulos[0]} }}})
            const Titulos_Comparacion_Camel = await API.graphql({query:listNewPaperss, variables : { filter : { titulo : { contains : searchinputDataTitulosCamel[0]} }}})
            const Titulos_Comparacion_Upper = await API.graphql({query:listNewPaperss, variables : { filter : { titulo : { contains : searchinputDataTitulosUpper[0]} }}})
            
            let Titulos_Comparacion_Arr = Titulos_Comparacion_Lower.data.listNewPaperss.items.concat(Titulos_Comparacion_Camel.data.listNewPaperss.items,Titulos_Comparacion_Upper.data.listNewPaperss.items)
            
            let Titulos_Comparacion_Arr_Puro = getUniqueListBy(Titulos_Comparacion_Arr, 'id')

            if(searchinputDataTitulos.length === 1 ){
              var idsTotalTitulos = new Set(Total.map(d => d.id));
              Total = [...Total, ...Titulos_Comparacion_Arr_Puro.filter(d => !idsTotalTitulos.has(d.id))];
            }

            else{

              if(Titulos_Comparacion_Arr_Puro.length === 0){
                return null
              }

                Titulos_Comparacion_Arr_Puro.map((item)=>{
                let TitulosComparation = getUniqueListBy(TituloLoop(item, searchinputData), 'id')
                TotalTituloArray = [...TotalTituloArray, TitulosComparation]                  
              })
              let TotalFilteredArray = TotalTituloArray.filter(item => item.length !== 0)
              let Final = getUniqueListBy(TotalFilteredArray)

              for (let index = 0; index < Final.length; index++) {
                const element = Final[index];
                let GenericArray = []
                let GeneralFinal = GenericArray.concat(element)
                Total = [...Total, ...GeneralFinal]
              }
            }
          } 
          
          if(data.Revistas === "Revistas"){ 
            let searchinputDataRevistas = data.searchbar.toLowerCase().split(" ")
            let searchinputDataRevistasCamel = camelCase(data.searchbar).split(" ")
            let searchinputDataRevistasUpper = data.searchbar.toUpperCase().split(" ")
            let TotalRevistaArray =  []
            
            const Revistas_Comparacion_Lower = await API.graphql({query:listNewPaperss, variables : { filter : { titulo_secundario : { contains : searchinputDataRevistas[0]} }}})
            const Revistas_Comparacion_Camel = await API.graphql({query:listNewPaperss, variables : { filter : { titulo_secundario : { contains : searchinputDataRevistasCamel[0]} }}})
            const Revistas_Comparacion_Upper = await API.graphql({query:listNewPaperss, variables : { filter : { titulo_secundario : { contains : searchinputDataRevistasUpper[0]} }}})

            let Revistas_Comparacion_Arr = Revistas_Comparacion_Lower.data.listNewPaperss.items.concat(Revistas_Comparacion_Camel.data.listNewPaperss.items, Revistas_Comparacion_Upper.data.listNewPaperss.items)

            let Revistas_Comparacion_Arr_Puro = getUniqueListBy(Revistas_Comparacion_Arr, 'id')
  
            if(searchinputDataRevistas.length === 1 ){

              var idsTotalRevistas = new Set(Total.map(d => d.id));
              Total = [...Total, ...Revistas_Comparacion_Arr_Puro.filter(d => !idsTotalRevistas.has(d.id))];

            }
            else{

                if(Revistas_Comparacion_Arr_Puro.length === 0){
                  return null
                }

                Revistas_Comparacion_Arr_Puro.map((item)=>{
                  let RevistasComparation = getUniqueListBy(RevistaLoop(item, searchinputData), 'id')
                  TotalRevistaArray = [...TotalRevistaArray, RevistasComparation]
                })

                let TotalFilteredArray = TotalRevistaArray.filter(item => item.length !== 0)
                let Final = getUniqueListBy(TotalFilteredArray)

                for (let index = 0; index < Final.length; index++) {
                  const element = Final[index];
                  let GenericArray = []
                  let GeneralFinal = GenericArray.concat(element)
                  Total = [...Total, ...GeneralFinal]
                }

            }
          }

          if(data.Abstract === "Abstract"){ 
            let searchinputDataAbstract = data.searchbar.toLowerCase().split(" ")
            let searchinputDataAbstractCamel = camelCase(data.searchbar).split(" ")
            let searchinputDataAbstractUpper = data.searchbar.toUpperCase().split(" ")
            let TotalAbstractArray =  []
            
            const Abstract_Comparacion_Lower = await API.graphql({query:listNewPaperss, variables : { filter : { Abstract : { contains : searchinputDataAbstract[0]} }}})
            const Abstract_Comparacion_Camel = await API.graphql({query:listNewPaperss, variables : { filter : { Abstract : { contains : searchinputDataAbstractCamel[0]} }}})
            const Abstract_Comparacion_Upper = await API.graphql({query:listNewPaperss, variables : { filter : { Abstract : { contains : searchinputDataAbstractUpper[0]} }}})

            let Abstract_Comparacion_Arr = Abstract_Comparacion_Lower.data.listNewPaperss.items.concat(Abstract_Comparacion_Camel.data.listNewPaperss.items, Abstract_Comparacion_Upper.data.listNewPaperss.items)

            let Abstract_Comparacion_Arr_Puro = getUniqueListBy(Abstract_Comparacion_Arr, 'id')
  
            if(searchinputDataAbstract.length === 1 ){

              var idsTotalAbstract = new Set(Total.map(d => d.id));
              Total = [...Total, ...Abstract_Comparacion_Arr_Puro.filter(d => !idsTotalAbstract.has(d.id))];

            }
            else{

                if(Abstract_Comparacion_Arr_Puro.length === 0){
                  return null
                }

                Abstract_Comparacion_Arr_Puro.map((item)=>{
                  let AbstractComparation = getUniqueListBy(AbstractLoop(item, searchinputData), 'id')
                  TotalAbstractArray = [...TotalAbstractArray, AbstractComparation]
                })

                let TotalFilteredArray = TotalAbstractArray.filter(item => item.length !== 0)
                let Final = getUniqueListBy(TotalFilteredArray)

                for (let index = 0; index < Final.length; index++) {
                  const element = Final[index];
                  let GenericArray = []
                  let GeneralFinal = GenericArray.concat(element)
                  Total = [...Total, ...GeneralFinal]
                }

            }
          }

          if(data.PalabrasClave === "PalabrasClave"){ 
            let searchinputDataPalabrasClave = data.searchbar.toLowerCase().split(" ")
            let searchinputDataPalabrasClaveCamel = camelCase(data.searchbar).split(" ")
            let searchinputDataPalabrasClaveUpper = data.searchbar.toUpperCase().split(" ")
            let TotalPalabrasClaveArray =  []
            
            const PalabrasClave_Comparacion_Lower = await API.graphql({query:listNewPaperss, variables : { filter : { PalabrasClave : { contains : searchinputDataPalabrasClave[0]} }}})
            const PalabrasClave_Comparacion_Camel = await API.graphql({query:listNewPaperss, variables : { filter : { PalabrasClave : { contains : searchinputDataPalabrasClaveCamel[0]} }}})
            const PalabrasClave_Comparacion_Upper = await API.graphql({query:listNewPaperss, variables : { filter : { PalabrasClave : { contains : searchinputDataPalabrasClaveUpper[0]} }}})

            let PalabrasClave_Comparacion_Arr = PalabrasClave_Comparacion_Lower.data.listNewPaperss.items.concat(PalabrasClave_Comparacion_Camel.data.listNewPaperss.items, PalabrasClave_Comparacion_Upper.data.listNewPaperss.items)

            let PalabrasClave_Comparacion_Arr_Puro = getUniqueListBy(PalabrasClave_Comparacion_Arr, 'id')
  
            if(searchinputDataPalabrasClave.length === 1 ){

              var idsTotalPalabrasClave = new Set(Total.map(d => d.id));
              Total = [...Total, ...PalabrasClave_Comparacion_Arr_Puro.filter(d => !idsTotalPalabrasClave.has(d.id))];

            }
            else{

                if(PalabrasClave_Comparacion_Arr_Puro.length === 0){
                  return null
                }

                PalabrasClave_Comparacion_Arr_Puro.map((item)=>{
                  let PalabrasClaveComparation = getUniqueListBy(PalabrasClaveLoop(item, searchinputData), 'id')
                  TotalPalabrasClaveArray = [...TotalPalabrasClaveArray, PalabrasClaveComparation]
                })

                let TotalFilteredArray = TotalPalabrasClaveArray.filter(item => item.length !== 0)
                let Final = getUniqueListBy(TotalFilteredArray)

                for (let index = 0; index < Final.length; index++) {
                  const element = Final[index];
                  let GenericArray = []
                  let GeneralFinal = GenericArray.concat(element)
                  Total = [...Total, ...GeneralFinal]
                }

            }
          }

          Total = getUniqueListBy(Total)
        }   
      break
      //------------------------------------------------------Normas------------------------------------------------------------------------------------------------
      case "Normas":
        //const searchinputDataProve = [data.searchbar, data.searchbar.toLowerCase(), data.searchbar.toUpperCase(), camelCase(data.searchbar).replace(/([a-z])([A-Z])/g, '$1 $2'), camelCase(data.searchbar).replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3') ]
        
        let TotalNormasArray =  []

        const PalabrasClave_Comparacion_Lower_Norm = await API.graphql({query:listNewNormas, variables : { filter : { PalabrasClave : { contains : searchinputData[0]} }}})
        const PalabrasClave_Comparacion_Camel_Norm = await API.graphql({query:listNewNormas, variables : { filter : { PalabrasClave : { contains : searchinputDataCamel[0]} }}})
        const PalabrasClave_Comparacion_Upper_Norm = await API.graphql({query:listNewNormas, variables : { filter : { PalabrasClave : { contains : searchinputDataUpper[0]} }}})
        const PalabrasClave_Comparacion_Lower_Norm_Orig = await API.graphql({query:listNewNormas, variables : { filter : { PalabrasClave_orig : { contains : searchinputData[0]} }}})
        const PalabrasClave_Comparacion_Camel_Norm_Orig = await API.graphql({query:listNewNormas, variables : { filter : { PalabrasClave_orig : { contains : searchinputDataCamel[0]} }}})
        const PalabrasClave_Comparacion_Upper_Norm_Orig = await API.graphql({query:listNewNormas, variables : { filter : { PalabrasClave_orig : { contains : searchinputDataUpper[0]} }}})

        let PalabrasClave_Comparacion_Norm = PalabrasClave_Comparacion_Lower_Norm.data.listNewNormas.items.concat(
          PalabrasClave_Comparacion_Camel_Norm.data.listNewNormas.items, 
          PalabrasClave_Comparacion_Upper_Norm.data.listNewNormas.items,
          PalabrasClave_Comparacion_Lower_Norm_Orig.data.listNewNormas.items,
          PalabrasClave_Comparacion_Camel_Norm_Orig.data.listNewNormas.items,
          PalabrasClave_Comparacion_Upper_Norm_Orig.data.listNewNormas.items,
        )
      
        let PalabrasClave_Comparacion_Puro_Norm = PalabrasClave_Comparacion_Norm.filter((valorActual, indiceActual, arreglo) => {
          return arreglo.findIndex(valorDelArreglo => JSON.stringify(valorDelArreglo) === JSON.stringify(valorActual)) === indiceActual
        });

        const Descripcion_Comparacion_Lower = await API.graphql({query:listNewNormas, variables : { filter : { Descripcion : { contains : searchinputData[0]} }}})
        const Descripcion_Comparacion_Camel = await API.graphql({query:listNewNormas, variables : { filter : { Descripcion : { contains : searchinputDataCamel[0]} }}})
        const Descripcion_Comparacion_Upper = await API.graphql({query:listNewNormas, variables : { filter : { Descripcion : { contains : searchinputDataUpper[0]} }}})
        const Descripcion_Comparacion_Lower_Orig = await API.graphql({query:listNewNormas, variables : { filter : { Descripcion_orig : { contains : searchinputData[0]} }}})
        const Descripcion_Comparacion_Camel_Orig = await API.graphql({query:listNewNormas, variables : { filter : { Descripcion_orig : { contains : searchinputDataCamel[0]} }}})
        const Descripcion_Comparacion_Upper_Orig = await API.graphql({query:listNewNormas, variables : { filter : { Descripcion_orig : { contains : searchinputDataUpper[0]} }}})
        
        let Descripcion_Comparacion = Descripcion_Comparacion_Lower.data.listNewNormas.items.concat(
          Descripcion_Comparacion_Camel.data.listNewNormas.items, 
          Descripcion_Comparacion_Upper.data.listNewNormas.items,
          Descripcion_Comparacion_Lower_Orig.data.listNewNormas.items,
          Descripcion_Comparacion_Camel_Orig.data.listNewNormas.items,
          Descripcion_Comparacion_Upper_Orig.data.listNewNormas.items,
        )

        let Descripcion_Comparacion_Puro = Descripcion_Comparacion.filter((valorActual, indiceActual, arreglo) => {
          return arreglo.findIndex(valorDelArreglo => JSON.stringify(valorDelArreglo) === JSON.stringify(valorActual)) === indiceActual
        });

        const Nombre_Comparacion_Lower_Norm = await API.graphql({query:listNewNormas, variables : { filter : { Nombre : { contains : searchinputData[0]} }}})
        const Nombre_Comparacion_Camel_Norm = await API.graphql({query:listNewNormas, variables : { filter : { Nombre : { contains : searchinputDataCamel[0]} }}})
        const Nombre_Comparacion_Upper_Norm = await API.graphql({query:listNewNormas, variables : { filter : { Nombre : { contains : searchinputDataUpper[0]} }}})
        const Nombre_Comparacion_Lower_Norm_Orig = await API.graphql({query:listNewNormas, variables : { filter : { Nombre_orig : { contains : searchinputData[0]} }}})
        const Nombre_Comparacion_Camel_Norm_Orig = await API.graphql({query:listNewNormas, variables : { filter : { Nombre_orig : { contains : searchinputDataCamel[0]} }}})
        const Nombre_Comparacion_Upper_Norm_Orig = await API.graphql({query:listNewNormas, variables : { filter : { Nombre_orig : { contains : searchinputDataUpper[0]} }}})
        
        let Nombre_Comparacion_Norm = Nombre_Comparacion_Lower_Norm.data.listNewNormas.items.concat(
          Nombre_Comparacion_Camel_Norm.data.listNewNormas.items, 
          Nombre_Comparacion_Upper_Norm.data.listNewNormas.items,
          Nombre_Comparacion_Lower_Norm_Orig.data.listNewNormas.items,
          Nombre_Comparacion_Camel_Norm_Orig.data.listNewNormas.items,
          Nombre_Comparacion_Upper_Norm_Orig.data.listNewNormas.items,
        )

        let Nombre_Comparacion_Puro_Norm = Nombre_Comparacion_Norm.filter((valorActual, indiceActual, arreglo) => {
          return arreglo.findIndex(valorDelArreglo => JSON.stringify(valorDelArreglo) === JSON.stringify(valorActual)) === indiceActual
        });

        var idsPAGeneral = new Set(PalabrasClave_Comparacion_Puro_Norm.map(d => d.id));
        let SubTotalGeneral = [...PalabrasClave_Comparacion_Puro_Norm, ...Descripcion_Comparacion_Puro.filter(d => !idsPAGeneral.has(d.id))];
        var idsDSGeneral = new Set(SubTotalGeneral.map(d => d.id))
        let PreTotal = [...SubTotalGeneral,...Nombre_Comparacion_Puro_Norm.filter(d => !idsDSGeneral.has(d.id))]; 

        if(searchinputData.length === 1 ){
          var idsPA = new Set(PalabrasClave_Comparacion_Puro_Norm.map(d => d.id));
          let SubTotal = [...PalabrasClave_Comparacion_Puro_Norm, ...Descripcion_Comparacion_Puro.filter(d => !idsPA.has(d.id))];
          var idsDS = new Set(SubTotal.map(d => d.id))
          Total = [...SubTotal,...Nombre_Comparacion_Puro_Norm.filter(d => !idsDS.has(d.id))]; 
        }
        else{
          PreTotal.map((item)=>{
            const NombreNorma = NombreNormaLoop(item, searchsplitedGeneral)
            const PalabrasClave = PalabrasClaveLoop(item, searchsplitedGeneral)
            const Descripcion = DescripcionLoop(item, searchsplitedGeneral)
            if(NombreNorma === 0 || PalabrasClave === 0 || Descripcion === 0 ){
              return null
            }
            TotalNormasArray = TotalNormasArray.concat(NombreNorma, PalabrasClave, Descripcion)
          })
          Total = TotalNormasArray
          
          
        }
 /* Resolucion 429 1328*/
      break
//------------------------------------------------------Plataformas------------------------------------------------------------------------------------------------
      case "Plataformas":
        
        const NombrePL_Comparacion_Lower = await API.graphql({query:listNewPlataformas, variables : { filter : { Nombre : { contains : searchinputData[0]} }}})
        const NombrePL_Comparacion_Camel = await API.graphql({query:listNewPlataformas, variables : { filter : { Nombre : { contains : searchinputDataCamel[0]} }}})
        const NombrePL_Comparacion_Lower_Orig = await API.graphql({query:listNewPlataformas, variables : { filter : { Nombre_orig : { contains : searchinputData[0]} }}})
        const NombrePL_Comparacion_Camel_Orig = await API.graphql({query:listNewPlataformas, variables : { filter : { Nombre_orig : { contains : searchinputDataCamel[0]} }}})

        let NombrePL_Comparacion = NombrePL_Comparacion_Lower.data.listNewPlataformas.items.concat(
          NombrePL_Comparacion_Camel.data.listNewPlataformas.items,
          NombrePL_Comparacion_Lower_Orig.data.listNewPlataformas.items,
          NombrePL_Comparacion_Camel_Orig.data.listNewPlataformas.items
        )

        let NombrePL_Comparacion_Puro = NombrePL_Comparacion.filter((valorActual, indiceActual, arreglo) => {
          return arreglo.findIndex(valorDelArreglo => JSON.stringify(valorDelArreglo) === JSON.stringify(valorActual)) === indiceActual
        });

        const DescripcionPL_Comparacion_Lower = await API.graphql({query:listNewPlataformas, variables : { filter : { Descripcion : { contains : searchinputData[0]} }}})
        const DescripcionPL_Comparacion_Camel = await API.graphql({query:listNewPlataformas, variables : { filter : { Descripcion : { contains : searchinputDataCamel[0]} }}})
        const DescripcionPL_Comparacion_Lower_Orig = await API.graphql({query:listNewPlataformas, variables : { filter : { Descripcion_orig : { contains : searchinputData[0]} }}})
        const DescripcionPL_Comparacion_Camel_Orig = await API.graphql({query:listNewPlataformas, variables : { filter : { Descripcion_orig : { contains : searchinputDataCamel[0]} }}})
        
        let DescripcionPL_Comparacion = DescripcionPL_Comparacion_Lower.data.listNewPlataformas.items.concat(
          DescripcionPL_Comparacion_Camel.data.listNewPlataformas.items,
          DescripcionPL_Comparacion_Lower_Orig.data.listNewPlataformas.items,
          DescripcionPL_Comparacion_Camel_Orig.data.listNewPlataformas.items,
        )

        let DescripcionPL_Comparacion_Puro = DescripcionPL_Comparacion.filter((valorActual, indiceActual, arreglo) => {
          return arreglo.findIndex(valorDelArreglo => JSON.stringify(valorDelArreglo) === JSON.stringify(valorActual)) === indiceActual
        });
        
        var idsPA = new Set(NombrePL_Comparacion_Puro.map(d => d.id));
        Total = [...NombrePL_Comparacion_Puro, ...DescripcionPL_Comparacion_Puro.filter(d => !idsPA.has(d.id))];
      break
      
//------------------------------------------------------Audiovisual------------------------------------------------------------------------------------------------

      case "AudioVisuales":

        const NombreAV_Comparacion_Lower = await API.graphql({query:listNewAudiovisuals, variables : { filter : { Nombre : { contains : searchinputData[0]} }}})
        const NombreAV_Comparacion_Camel = await API.graphql({query:listNewAudiovisuals, variables : { filter : { Nombre : { contains : searchinputDataCamel[0]} }}})
        const NombreAV_Comparacion_Lower_Orig = await API.graphql({query:listNewAudiovisuals, variables : { filter : { Nombre_orig : { contains : searchinputData[0]} }}})
        const NombreAV_Comparacion_Camel_Orig = await API.graphql({query:listNewAudiovisuals, variables : { filter : { Nombre_orig : { contains : searchinputDataCamel[0]} }}})
        
        let NombreAV_Comparacion = NombreAV_Comparacion_Lower.data.listNewAudiovisuals.items.concat(
          NombreAV_Comparacion_Camel.data.listNewAudiovisuals.items,
          NombreAV_Comparacion_Lower_Orig.data.listNewAudiovisuals.items,
          NombreAV_Comparacion_Camel_Orig.data.listNewAudiovisuals.items,
        )


        let NombreAV_Comparacion_Puro = NombreAV_Comparacion.filter((valorActual, indiceActual, arreglo) => {
          return arreglo.findIndex(valorDelArreglo => JSON.stringify(valorDelArreglo) === JSON.stringify(valorActual)) === indiceActual
        });

        Total = NombreAV_Comparacion_Puro
      break

//------------------------------------------------------Organizacion de Pacientes------------------------------------------------------------------------------------------------
      
      case "OrganizacionPacientes":
        const NombreOP_Comparacion_Lower = await API.graphql({query:listNewOrganizacionPacientes, variables : { filter : { Nombre : { contains : searchinputData[0]} }}})
        const NombreOP_Comparacion_Camel = await API.graphql({query:listNewOrganizacionPacientes, variables : { filter : { Nombre : { contains : searchinputDataCamel[0]} }}})
        const NombreOP_Comparacion_Lower_Orig = await API.graphql({query:listNewOrganizacionPacientes, variables : { filter : { Nombre_orig : { contains : searchinputData[0]} }}})
        const NombreOP_Comparacion_Camel_Orig = await API.graphql({query:listNewOrganizacionPacientes, variables : { filter : { Nombre_orig : { contains : searchinputDataCamel[0]} }}})
        
        let NombreOP_Comparacion = NombreOP_Comparacion_Lower.data.listNewOrganizacionPacientes.items.concat(
          NombreOP_Comparacion_Camel.data.listNewOrganizacionPacientes.items,
          NombreOP_Comparacion_Lower_Orig.data.listNewOrganizacionPacientes.items,
          NombreOP_Comparacion_Camel_Orig.data.listNewOrganizacionPacientes.items,
        )

        let NombreOP_Comparacion_Puro = NombreOP_Comparacion.filter((valorActual, indiceActual, arreglo) => {
          return arreglo.findIndex(valorDelArreglo => JSON.stringify(valorDelArreglo) === JSON.stringify(valorActual)) === indiceActual
        });
        
        Total= NombreOP_Comparacion_Puro
      break

      case "Profesionales":
        const NombrePR_Comparacion_Lower = await API.graphql({query:listNewProfesionals, variables : { filter : { Nombre : { contains : searchinputData[0]} }}})
        const NombrePR_Comparacion_Camel = await API.graphql({query:listNewProfesionals, variables : { filter : { Nombre : { contains : searchinputDataCamel[0]} }}})
        const NombrePR_Comparacion_Lower_Orig = await API.graphql({query:listNewProfesionals, variables : { filter : { Nombre_orig : { contains : searchinputData[0]} }}})
        const NombrePR_Comparacion_Camel_Orig = await API.graphql({query:listNewProfesionals, variables : { filter : { Nombre_orig : { contains : searchinputDataCamel[0]} }}})

        let NombrePR_Comparacion = NombrePR_Comparacion_Lower.data.listNewProfesionals.items.concat(
          NombrePR_Comparacion_Camel.data.listNewProfesionals.items,
          NombrePR_Comparacion_Lower_Orig.data.listNewProfesionals.items,
          NombrePR_Comparacion_Camel_Orig.data.listNewProfesionals.items,
        )

        let NombrePR_Comparacion_Puro = NombrePR_Comparacion.filter((valorActual, indiceActual, arreglo) => {
          return arreglo.findIndex(valorDelArreglo => JSON.stringify(valorDelArreglo) === JSON.stringify(valorActual)) === indiceActual
        });

        const DescripcionPR_Comparacion_Lower = await API.graphql({query:listNewProfesionals, variables : { filter : { Descripcion : { contains : searchinputData[0]} }}})
        const DescripcionPR_Comparacion_Camel = await API.graphql({query:listNewProfesionals, variables : { filter : { Descripcion : { contains : searchinputDataCamel[0]} }}})
        const DescripcionPR_Comparacion_Lower_Orig = await API.graphql({query:listNewProfesionals, variables : { filter : { Descripcion_orig : { contains : searchinputData[0]} }}})
        const DescripcionPR_Comparacion_Camel_Orig = await API.graphql({query:listNewProfesionals, variables : { filter : { Descripcion_orig : { contains : searchinputDataCamel[0]} }}})
        
        let DescripcionPR_Comparacion = DescripcionPR_Comparacion_Lower.data.listNewProfesionals.items.concat(
          DescripcionPR_Comparacion_Camel.data.listNewProfesionals.items,
          DescripcionPR_Comparacion_Lower_Orig.data.listNewProfesionals.items,
          DescripcionPR_Comparacion_Camel_Orig.data.listNewProfesionals.items,
        )

        let DescripcionPR_Comparacion_Puro = DescripcionPR_Comparacion.filter((valorActual, indiceActual, arreglo) => {
          return arreglo.findIndex(valorDelArreglo => JSON.stringify(valorDelArreglo) === JSON.stringify(valorActual)) === indiceActual
        });
        
        var idsPA = new Set(NombrePR_Comparacion_Puro.map(d => d.id));
        Total = [...NombrePR_Comparacion_Puro, ...DescripcionPR_Comparacion_Puro.filter(d => !idsPA.has(d.id))];
      break

      case "Investigacion":
        const NombreINV_Comparacion_Lower = await API.graphql({query:listNewInvestigacions, variables : { filter : { Nombre : { contains : searchinputData[0]} }}})
        const NombreINV_Comparacion_Camel = await API.graphql({query:listNewInvestigacions, variables : { filter : { Nombre : { contains : searchinputDataCamel[0]} }}})
        const NombreINV_Comparacion_Lower_Orig = await API.graphql({query:listNewInvestigacions, variables : { filter : { Nombre : { contains : searchinputData[0]} }}})
        const NombreINV_Comparacion_Camel_Orig = await API.graphql({query:listNewInvestigacions, variables : { filter : { Nombre : { contains : searchinputDataCamel[0]} }}})
        
        let NombreINV_Comparacion = NombreINV_Comparacion_Lower.data.listNewInvestigacions.items.concat(
          NombreINV_Comparacion_Camel.data.listNewInvestigacions.items,
          NombreINV_Comparacion_Lower_Orig.data.listNewInvestigacions.items,
          NombreINV_Comparacion_Camel_Orig.data.listNewInvestigacions.items,
        )

        let NombreINV_Comparacion_Puro = NombreINV_Comparacion.filter((valorActual, indiceActual, arreglo) => {
          return arreglo.findIndex(valorDelArreglo => JSON.stringify(valorDelArreglo) === JSON.stringify(valorActual)) === indiceActual
        });

        Total=NombreINV_Comparacion_Puro

      break
    }

    setsearchbarresults(Total)
    setInitialState(false)
    setshowresults(true)
    setLoading(false)
    /* reset(data); */
  }

/*   useEffect(() => {
    ResetSystem()
    reset()
  }, [props.srcData]) */

  useEffect(()=>{
    switch(props.srcData){
      case "Papers":
        settitle("Papers")
      break
      case "Normas":
        settitle("Marco Normativo")
      break
      case "Plataformas":
        settitle("Plataformas")
      break
      case "AudioVisuales":
        settitle("Recursos Audiovisuales")
      break
      case "OrganizacionPacientes":
        settitle("Organizaciones de Pacientes")
      break
      case "Profesionales":
        settitle("Profesionales de la salud")
      break
      case "Investigacion":
        settitle("Grupos de Investigacion")
      break
      default:
        settitle("Seleccione un area a editar")
      break 
    }
  },[props.srcData])
  
    return(
        <div style={{backgroundColor:"#EAEAEA"}}>
          <MainEditTitle>Bienvenido al editor de <span style={{color:"#FC53E3"}}>{title}</span></MainEditTitle>
          <form onSubmit={handleSubmit(onSubmitEdit)}>

            <div style={{display:'inline-flex',width:"80%"}}>
                    <InputBar/>
                    <InputContent width="80%" style={{borderRadius:"0px 15px 15px 0px", border:"none"}} name="searchbar" placeholder=" Busqueda" ref={register({ required: 'This is required' })}/>
            </div>
            <div>
            {
              props.srcData === "Papers" ?
            <div>
              <div>
                <Switch checked={advancedsearch} onChange={()=>{setadvancedsearch(!advancedsearch)}} />
                <LabelBiblioteca>Búsqueda Avanzada</LabelBiblioteca>
              </div>
              <div>
              {
                advancedsearch === true ?
                <div style={{display:'inline-flex', marginTop:"1%"}}>
                        <LabelBiblioteca>Buscar por:</LabelBiblioteca>
                      <div className="input-checkbox">
                        <LabelBiblioteca>Autores</LabelBiblioteca>
                        <input  name="Autores" value="Autores" type="checkbox" ref={register}/>
                      </div>
                      <div className="input-checkbox">
                        <LabelBiblioteca>Titulo de Articulo</LabelBiblioteca>
                        <input  name="Titulos" value="Titulos" type="checkbox" ref={register}/>
                      </div>
                      <div className="input-checkbox">
                        <LabelBiblioteca>Titulo de Revista</LabelBiblioteca>
                        <input  name="Revistas" value="Revistas" type="checkbox" ref={register}/>
                      </div>
                      <div className="input-checkbox">
                        <LabelBiblioteca>Abstract</LabelBiblioteca>
                        <input  name="Abstract" value="Abstract" type="checkbox" ref={register}/>
                      </div>
                      <div className="input-checkbox">
                        <LabelBiblioteca>Palabras Clave</LabelBiblioteca>
                        <input  name="PalabrasClave" value="PalabrasClave" type="checkbox" ref={register}/>
                      </div>
                </div>
                :
                null
              }
              </div>
            </div>
            :null
            }
            </div>
            <br/>
            <InputSubmit name="searchbarsubmit" margin="2rem 0" width="30%" height="30%" type="submit" value="BUSCAR" />
          </form>
          <div>
          {
            Loading === false ?
                     searchbarresults.length !== 0 ?
                        <div className="site-card-wrapper cardbox-layout-general">
                            <Row gutter={16}>                
                                  {searchbarresults.map((result)=>{
                                    return(
                                    <Col span={8}>
                                      <CardEdit info={result} typeData={props.srcData}></CardEdit>
                                    </Col>
                                    )
                                  })}
                            </Row>
                        </div>
                        :
                        showresults ?
                        <div>
                        <Empty className="empty-box"  
                        description={
                          <span>
                           No hay Resultados actualmente
                          </span>
                        } />
                        </div>
                        :
                        <div>Haz tu busqueda</div>
                        :
                        <div>
                          <LoadingOutlined />
                        </div>
                }
          </div>
        </div>
    )
}