import React, {useContext, useState, useEffect, useRef} from 'react'
import { Button, Form, Input, Tag, InputNumber } from 'antd'
import Amplify, { API, graphqlOperation } from 'aws-amplify'
import { Alert, notification } from 'antd';
import { useForm } from "react-hook-form";
import { createNewInvestigacion } from '../../graphql/mutations'
import {MainTitle, ErrorForm} from '../Datadisplay'
import {InputBar, InputContent,Label,InputSubmit, SelectElement,TextAreaElement,SelectOptionElement} from '../Input'

const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} no es un numero valido!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };
const { TextArea } = Input;

export default function InvestigacionForm(event){

    //States and Hooks
    const [alert, setAlert] = useState()
    const {register, handleSubmit, errors} = useForm()


    //Validaciones
    const SuccessAlert = () => {notification["success"]({
        message: 'Enhorabuena!',
        description:
          'Se ha creado el paper con exito',
    });
    }
    const ErrorAlert = () => {notification["error"]({
        message: 'Lo sentimos',
        description:
          'Verifica tu conexión de red o intentalo mas tarde',
    });
    }
    function onFinishFailed(errorInfo){
        ErrorAlert()
    };

    const removeAccents = (str) => {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }

    const GettingData = async (data,e) => {
        const result = {
            ...data,
            Nombre: removeAccents(data.Nombre).toLowerCase(),
            Nombre_orig: data.Nombre,
            Entidad: removeAccents(data.Entidad).toLowerCase(),
            Entidad_orig: data.Entidad,
            Ciudad: removeAccents(data.Ciudad).toLowerCase(),
            Ciudad_orig: data.Ciudad,
            URL: removeAccents(data.URL).toLowerCase(),
            URL_orig: data.URL,
        }
        await API.graphql(graphqlOperation(createNewInvestigacion, {input: result}))
        setAlert(SuccessAlert)
        e.target.reset()
    }
    
    return(
        <div className="scale-in-hor-left">
            <MainTitle size="4rem" aligntext="left">Formulario <br/>Grupos de Atención e Investigación</MainTitle>
            <form style={{paddingRight:"10%"}} onSubmit={handleSubmit(GettingData)}>

                <Label>Nombre</Label>

                <div style={{display:'inline-flex', width:"99%"}}>
                    <InputBar/>
                    <InputContent name="Nombre"  placeholder="Nombre del Grupo de Investigación" ref={register({ required: true })}/>
                </div>
                <div>{errors.titulo && <ErrorForm>Este campo es requerido</ErrorForm>}</div>

                <Label>Entidades</Label>

                <div style={{display:'inline-flex', width:"99%"}}>
                    <InputBar/>
                    <InputContent name="Entidad"  placeholder="Entidad" ref={register({ required: true })}/>
                </div>
                <div>{errors.titulo && <ErrorForm>Este campo es requerido</ErrorForm>}</div>


                <Label>Ciudad</Label>

                <div style={{display:'inline-flex', width:"99%"}}>
                    <InputBar/>
                    <InputContent name="Ciudad"  placeholder="Ciudad" ref={register}/>
                </div>

                <Label>URL</Label>

                <div style={{display:'inline-flex', width:"99%"}}>
                    <InputBar/>
                    <InputContent name="URL"  placeholder="url de sitio web y/o red social" ref={register}/>
                </div>

                <InputSubmit width="30%" height="30%" type="submit" value="PUBLICAR GRUPO DE INVESTIGACION" />
            </form>
        </div>
    )
}