import React, {useContext, useState, useEffect, useRef} from 'react'
import { Button, Form, Input, Tag, InputNumber } from 'antd'
import Amplify, { API, graphqlOperation, Storage } from 'aws-amplify'
import { Alert, notification } from 'antd';
import { useForm } from "react-hook-form";
import { createNewOrganizacionPaciente } from '../../graphql/mutations'
import { listNewOrganizacionPacientes } from '../../graphql/queries'
import {MainTitle, ErrorForm} from '../Datadisplay'
import { v4 as uuid } from 'uuid'
import config from '../../aws-exports'
import {InputBar, InputContent,Label,InputSubmit, SelectElement,TextAreaElement,SelectOptionElement} from '../Input'
import '../../styles/styles.css'

const {
    aws_user_files_s3_bucket_region: region,
    aws_user_files_s3_bucket: bucket
  } = config

const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} no es un numero valido!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };
const { TextArea } = Input;

export default function OrganizacionPacienteForm(event){

    //States and Hooks
    const [alert, setAlert] = useState()
    const {register, handleSubmit, errors} = useForm()
    const [file, updateFile] = useState(null)
    const [OrganizacionName, updateOrganizacionName] = useState('')
    const [Organizacions, updateOrganizacions] = useState([])
    let [FilePreviewImg, setFilePreviewImg] = useState()
    let [extensionName, setextensionName] = useState('')

    useEffect(() => {
        listOrganizaciones()
      }, [])
    
    async function listOrganizaciones() {
        const organizaciones = await API.graphql(graphqlOperation(listNewOrganizacionPacientes))
        updateOrganizacions(organizaciones.data.listNewOrganizacionPacientes.items)
    }

    function handleChange(event) {
        const filePreview = event.target.files[0]
        const filePreviewCreate = window.webkitURL.createObjectURL(filePreview)
        setFilePreviewImg(filePreviewCreate)
        const { target: { value, files } } = event
        const fileForUpload = files[0]
        updateOrganizacionName(fileForUpload.name.split(".")[0])
        setextensionName(fileForUpload.name)
        updateFile(fileForUpload || value)
      }

    //Validaciones
    const SuccessAlert = () => {notification["success"]({
        message: 'Enhorabuena!',
        description:
          'Se ha creado el paper con exito',
    });
    }
    const ErrorAlert = () => {notification["error"]({
        message: 'Lo sentimos',
        description:
          'Verifica tu conexión de red o intentalo mas tarde',
    });
    }
    function onFinishFailed(errorInfo){
        ErrorAlert()
    };

    const removeAccents = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }
    const GettingData = async (data,e) => {
        let inputData = null
        if (file) {
            const extension = file.name.split(".")[1]
            setextensionName(extension)
            const { type: mimeType } = file
            const key = `images/organizaciones/${uuid()}${OrganizacionName}.${extension}`      
            const url = `https://${bucket}.s3.${region}.amazonaws.com/public/${key}`
            inputData = { name: OrganizacionName , image: url }
    
            try {
                await Storage.put(key, file, {
                contentType: mimeType
                })
            } catch (err) {
                console.log('error: ', err)
            }
        }
        const result = {
            ...data,
            Nombre:removeAccents(data.Nombre.toLowerCase()),
            Nombre_orig: data.Nombre,
            Ciudad: removeAccents(data.Ciudad.toLowerCase()),
            Ciudad_orig: data.Ciudad,
            URL: removeAccents(data.URL.toLowerCase()),
            URL_orig: data.URL,
            Tipo: removeAccents(data.Tipo.toLowerCase()),
            Tipo_orig: data.Tipo,
            Logo: inputData.image,
            Logo_orig: inputData.image
        }
        await API.graphql(graphqlOperation(createNewOrganizacionPaciente, {input: result}))
        setAlert(SuccessAlert)
        e.target.reset()
    }
    
    return(
        <div className="scale-in-hor-left">
            <MainTitle size="4rem" aligntext="left">Formulario de Organizaciones de Pacientes</MainTitle>
            <form style={{paddingRight:"10%"}} onSubmit={handleSubmit(GettingData)}>

                <Label>Tipo de Organización</Label>

                <div style={{display:'inline-flex', width:"99%"}}>
                <InputBar/>
                    <SelectElement name="Tipo" defaultValue="Nacional" ref={register}>
                        <SelectOptionElement value="Nacional">Nacional</SelectOptionElement>
                        <SelectOptionElement value="Internacional">Internacional</SelectOptionElement>
                    </SelectElement>
                </div>

                <Label>Nombre</Label>

                <div style={{display:'inline-flex', width:"99%"}}>
                    <InputBar/>
                    <InputContent name="Nombre" placeholder="Nombre de la Organizacion" ref={register({ required: true })}/>
                </div>
                <div>{errors.titulo && <ErrorForm>Este campo es requerido</ErrorForm>}</div>

                <Label>Logo URL</Label>

                <div style={{display:"block",width:"99%"}}>
                <input
                        placeholder='Nombre del archivo'
                        value={`${extensionName}`}
                        onChange={e => updateOrganizacionName(e.target.files[0].name)}
                        style={{height:"100%", background:"transparent", border: "none"}}
                        name="LogoName"
                />
                <input
                        type="file"
                        onChange={handleChange}
                        style={{margin: '10px 0px'}}
                        ref={register({ required: true })}
                        id="org-upload"
                        name="Logo"
                    />
                <label htmlFor="org-upload">Cargar Archivo</label>
                </div>

                <img  src={FilePreviewImg} style={{width:"50%", marginTop:"3%"}}/>

                <Label>Ciudad</Label>

                <div style={{display:'inline-flex', width:"99%"}}>
                    <InputBar/>
                    <InputContent name="Ciudad"  placeholder="Ciudad" ref={register}/>
                </div>

                <Label>URL</Label>

                <div style={{display:'inline-flex', width:"99%"}}>
                    <InputBar/>
                    <InputContent name="URL"  placeholder="url de sitio web y/o red social" ref={register}/>
                </div>

                <InputSubmit width="30%" height="30%" type="submit" value="CREAR ORGANIZACION" />
            </form>
        </div>
    )
}