import React,{useEffect, useState} from 'react'
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import styled from 'styled-components'
import {CardProfesional} from '../../components/CardsDisplay'
import { useForm } from "react-hook-form";
import Amplify, { API, graphqlOperation } from 'aws-amplify'
import { listNewProfesionals, syncPapers } from '../../graphql/queries'
import {InputSubmit, InputBar, LabelBiblioteca} from '../../components/Input'
import {Row, Col} from 'antd'


const FilterArea = styled.div`
    width:100%;
    height:200px;
    background-color: black;
    padding:10px;
`
const MainTitleGeneral = styled.h1`
    font-family:"Poppins";
    font-size:2rem;
    font-weight:700;
    text-align:center;
    margin-top:5%;
`

export default function Profesionales(){

    const {register, handleSubmit, errors, control, defaultChecked, reset} = useForm()
    const [profesionalesData, setprofesionalesData] = useState()

    useEffect(async ()=>{
        const GeneralList = await API.graphql({query:listNewProfesionals})
        setprofesionalesData(GeneralList.data.listNewProfesionals.items)
    },[])

    return(
        <div className="slide-in-left" style={{marginTop:"10%"}}>
            <MainTitleGeneral>Profesionales de la Salud</MainTitleGeneral>
            <Divider style={{width:"80%", alignContent:"center", height:"6px", margin:"0 10%", backgroundColor:"#6C0E60" }} variant="middle" />
            <Grid container spacing={3} style={{padding:"5% 5%", width:"100%"}}>
                {/* <Grid item xs={12} sm={12} md={3} lg={3}  >
                <form onSubmit={handleSubmit(onSubmitOrganizacion)}>
                    <div style={{paddingLeft:"15%"}}>
                        <div style={{textAlign:"start"}} className="input-checkbox">
                        <input
                            ref={register()}
                            name="Nacional"
                            value={false}
                            type="checkbox"
                        />
                        <LabelBiblioteca>Nacional</LabelBiblioteca>
                        </div>

                        <div style={{textAlign:"start"}} className="input-checkbox">
                        <input
                            ref={register()}
                            name="Internacional"
                            value={false}
                            type="checkbox"
                        />
                        <LabelBiblioteca>Internacional</LabelBiblioteca>
                        </div>
                    </div>
                    <br/>
                    <InputSubmit name="searchbar" margin="2rem 0" width="30%" height="30%" type="submit" value="FILTRAR" />
                    </form>
                </Grid> 
                <Divider orientation="vertical" flexItem />
                */}
                <Grid item xs={12} sm={12} md={12} lg={12} >
                    {
                        profesionalesData !== undefined ?
                        <div>
                            <Row gutter={16}>                
                                  {profesionalesData.map((result)=>{
                                    return(
                                    <Col span={8}>
                                      <CardProfesional info={result}></CardProfesional>
                                    </Col>
                                    )
                                  })}
                            </Row>
                        </div>
                        :
                        <div>Cargando....</div>
                    } 
                </Grid>
            </Grid>
        </div>
    )
}
