import React, {useState, useEffect} from 'react'
import Amplify, { API, graphqlOperation } from 'aws-amplify'
import { listNewNormas, syncPapers } from '../../graphql/queries'
/* import awsmobile from '../../aws-exports' */
import {Link} from 'react-router-dom'
import { Card, Col, Row, Divider, Modal, Empty } from 'antd';
import {InputBar, InputContent, InputCheckbox, InputSubmit, LabelBiblioteca} from '../../components/Input'
import LogoCluster from '../../assets/img/LogoClusterEnfermedades.png'
import { useForm } from "react-hook-form";
import {CardNormas} from '../../components/CardsDisplay'
import Grid from '@material-ui/core/Grid';
import { RightOutlined, LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components'
import {DescripcionLoop, PalabrasClaveLoop, NombreNormaLoop} from '../../components/Filters'

const GeneralTitle = styled.p`
    font-family: "Roboto";
    font-size:2rem;
    font-weight:200;
    width:100%;
    text-align:left;
`
const MainTitle = styled.p`
  font-family:"Roboto";
  font-size:${props=>props.size || "2rem"};
  color: #6C0E60;
  margin:${props=>props.margin || "10px"};
  text-align:${props=>props.aligntext || "left"};
  font-weight:100;
  width:100%;
  display:inline-flex;
`
const GoNormas = styled.button`
    background: none;
    font-family: "Roboto";
    font-size:1rem;
    font-weight:200;
    width:100%;
    text-align:right;
    border: none;
    width:96%;
    margin: 2% 10% 0% 0%;
`
const TagFrecuent = styled.input`
  font-family:"Poppins";
  font-size:1rem;
  color: #440A78;
  text-align:center;
  font-weight:400;
  border: 2px solid #440A78;
  margin:0% 3%;
  &:hover{
    background-color: #440A78;
    color:white;
  }
`

export default function Biblioteca(){


    const {register, handleSubmit, errors, control, defaultChecked, reset} = useForm()
    const {
      register: register2, 
      handleSubmit: handleSubmit2, 
      errors: errors2, 
      control: control2, 
      defaultChecked: defaultChecked2, 
      reset: reset2 
    } = useForm()
    let [checked, setchecked] = useState(false)
    let [searchbarresults, setsearchbarresults] = useState([])
    const [showresults, setshowresults] = useState(false)
    const [newhook, setnewhook] = useState([])
    let [initialState, setInitialState] = useState(true)
    let [Loading, setLoading] = useState(false)
    let [searchbargeneral, setsearchgeneral] = useState("")
    let [documenttype , setdocumenttype] = useState(null)

    function camelCase(str) { 
        var regex = /[A-Z\xC0-\xD6\xD8-\xDE]?[a-z\xDF-\xF6\xF8-\xFF]+|[A-Z\xC0-\xD6\xD8-\xDE]+(?![a-z\xDF-\xF6\xF8-\xFF])|\d+/g;
        var match = str.match(regex);
        let result = "";
        

          for(let i = 0 , len = match.length; i < len; i++) {
            let currentStr = match[i];
            let tempStr = currentStr.toLowerCase();
            if(i != 0 || i != 1) {
              // convert first letter to upper case (the word is in lowercase) 
                tempStr = tempStr.substr(0, 1).toUpperCase() + tempStr.substr(1);
            }
            result +=tempStr;
          }
          return result.replace(/([a-z])([A-Z])/g, '$1 $2')
          //.replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3')
    } 
    const ResetSystem = () =>{
        searchbarresults = []
    }

    const onSubmitNormativa = async (data, e) =>{
        setLoading(true)
        setsearchgeneral(data.searchbar)

        const searchinputData = data.searchbar.toLowerCase().split(" ")
        let searchinputDataCamel = camelCase(data.searchbar).split(" ")
        let searchinputDataUpper = data.searchbar.toUpperCase().split(" ")
        let searchsplitedGeneral = data.searchbar.toLowerCase().split(" ")
        let TotalNormasArray =  []
        let Total = null

        const PalabrasClave_Comparacion_Lower = await API.graphql({query:listNewNormas, variables : { filter : { PalabrasClave : { contains : searchinputData[0]} }}})
        const PalabrasClave_Comparacion_Camel = await API.graphql({query:listNewNormas, variables : { filter : { PalabrasClave : { contains : searchinputDataCamel[0]} }}})
        const PalabrasClave_Comparacion_Upper = await API.graphql({query:listNewNormas, variables : { filter : { PalabrasClave : { contains : searchinputDataUpper[0]} }}})
        const PalabrasClave_Comparacion_Lower_Orig = await API.graphql({query:listNewNormas, variables : { filter : { PalabrasClave_orig : { contains : searchinputData[0]} }}})
        const PalabrasClave_Comparacion_Camel_Orig = await API.graphql({query:listNewNormas, variables : { filter : { PalabrasClave_orig : { contains : searchinputDataCamel[0]} }}})
        const PalabrasClave_Comparacion_Upper_Orig = await API.graphql({query:listNewNormas, variables : { filter : { PalabrasClave_orig : { contains : searchinputDataUpper[0]} }}})

        let PalabrasClave_Comparacion = PalabrasClave_Comparacion_Lower.data.listNewNormas.items.concat(
          PalabrasClave_Comparacion_Camel.data.listNewNormas.items, 
          PalabrasClave_Comparacion_Upper.data.listNewNormas.items,
          PalabrasClave_Comparacion_Lower_Orig.data.listNewNormas.items,
          PalabrasClave_Comparacion_Camel_Orig.data.listNewNormas.items,
          PalabrasClave_Comparacion_Upper_Orig.data.listNewNormas.items,
        )
      
        let PalabrasClave_Comparacion_Puro = PalabrasClave_Comparacion.filter((valorActual, indiceActual, arreglo) => {
          return arreglo.findIndex(valorDelArreglo => JSON.stringify(valorDelArreglo) === JSON.stringify(valorActual)) === indiceActual
        });

        const Descripcion_Comparacion_Lower = await API.graphql({query:listNewNormas, variables : { filter : { Descripcion : { contains : searchinputData[0]} }}})
        const Descripcion_Comparacion_Camel = await API.graphql({query:listNewNormas, variables : { filter : { Descripcion : { contains : searchinputDataCamel[0]} }}})
        const Descripcion_Comparacion_Upper = await API.graphql({query:listNewNormas, variables : { filter : { Descripcion : { contains : searchinputDataUpper[0]} }}})
        const Descripcion_Comparacion_Lower_Orig = await API.graphql({query:listNewNormas, variables : { filter : { Descripcion_orig : { contains : searchinputData[0]} }}})
        const Descripcion_Comparacion_Camel_Orig = await API.graphql({query:listNewNormas, variables : { filter : { Descripcion_orig : { contains : searchinputDataCamel[0]} }}})
        const Descripcion_Comparacion_Upper_Orig = await API.graphql({query:listNewNormas, variables : { filter : { Descripcion_orig : { contains : searchinputDataUpper[0]} }}})
        
        let Descripcion_Comparacion = Descripcion_Comparacion_Lower.data.listNewNormas.items.concat(
          Descripcion_Comparacion_Camel.data.listNewNormas.items, 
          Descripcion_Comparacion_Upper.data.listNewNormas.items,
          Descripcion_Comparacion_Lower_Orig.data.listNewNormas.items,
          Descripcion_Comparacion_Camel_Orig.data.listNewNormas.items,
          Descripcion_Comparacion_Upper_Orig.data.listNewNormas.items,
        )

        let Descripcion_Comparacion_Puro = Descripcion_Comparacion.filter((valorActual, indiceActual, arreglo) => {
          return arreglo.findIndex(valorDelArreglo => JSON.stringify(valorDelArreglo) === JSON.stringify(valorActual)) === indiceActual
        });
        
        const Nombre_Comparacion_Lower = await API.graphql({query:listNewNormas, variables : { filter : { Nombre : { contains : searchinputData[0]} }}})
        const Nombre_Comparacion_Camel = await API.graphql({query:listNewNormas, variables : { filter : { Nombre : { contains : searchinputDataCamel[0]} }}})
        const Nombre_Comparacion_Upper = await API.graphql({query:listNewNormas, variables : { filter : { Nombre : { contains : searchinputDataUpper[0]} }}})
        const Nombre_Comparacion_Lower_Orig = await API.graphql({query:listNewNormas, variables : { filter : { Nombre_orig : { contains : searchinputData[0]} }}})
        const Nombre_Comparacion_Camel_Orig = await API.graphql({query:listNewNormas, variables : { filter : { Nombre_orig : { contains : searchinputDataCamel[0]} }}})
        const Nombre_Comparacion_Upper_Orig = await API.graphql({query:listNewNormas, variables : { filter : { Nombre_orig : { contains : searchinputDataUpper[0]} }}})
        
        let Nombre_Comparacion = Nombre_Comparacion_Lower.data.listNewNormas.items.concat(
          Nombre_Comparacion_Camel.data.listNewNormas.items, 
          Nombre_Comparacion_Upper.data.listNewNormas.items,
          Nombre_Comparacion_Lower_Orig.data.listNewNormas.items,
          Nombre_Comparacion_Camel_Orig.data.listNewNormas.items,
          Nombre_Comparacion_Upper_Orig.data.listNewNormas.items,
        )

        let Nombre_Comparacion_Puro = Nombre_Comparacion.filter((valorActual, indiceActual, arreglo) => {
          return arreglo.findIndex(valorDelArreglo => JSON.stringify(valorDelArreglo) === JSON.stringify(valorActual)) === indiceActual
        });

        var idsPAGeneral = new Set(PalabrasClave_Comparacion_Puro.map(d => d.id));
        let SubTotalGeneral = [...PalabrasClave_Comparacion_Puro, ...Descripcion_Comparacion_Puro.filter(d => !idsPAGeneral.has(d.id))];
        var idsDSGeneral = new Set(SubTotalGeneral.map(d => d.id))
        let PreTotal = [...SubTotalGeneral,...Nombre_Comparacion_Puro.filter(d => !idsDSGeneral.has(d.id))]; 

        if(searchinputData.length === 1 ){
          var idsPA = new Set(PalabrasClave_Comparacion_Puro.map(d => d.id));
          let SubTotal = [...PalabrasClave_Comparacion_Puro, ...Descripcion_Comparacion_Puro.filter(d => !idsPA.has(d.id))];
          var idsDS = new Set(SubTotal.map(d => d.id))
          Total = [...SubTotal,...Nombre_Comparacion_Puro.filter(d => !idsDS.has(d.id))]; 
        }
        else{
          PreTotal.map((item)=>{
            const NombreNorma = NombreNormaLoop(item, searchsplitedGeneral)
            const PalabrasClave = PalabrasClaveLoop(item, searchsplitedGeneral)
            const Descripcion = DescripcionLoop(item, searchsplitedGeneral)
            if(NombreNorma === 0 || PalabrasClave === 0 || Descripcion === 0 ){
              return null
            }
            TotalNormasArray = TotalNormasArray.concat(NombreNorma, PalabrasClave, Descripcion)
          })
          Total = TotalNormasArray
          
          
        }
 
        /* if(searchinputData.length !== 1){
          let TotalPalabras = []
          let TotalDescripcion = []

          PalabrasClave_Comparacion.map((item)=>{
            const Element = PalabrasClaveLoop(item, searchsplitedGeneral)
            if(Element === 0){
              return null
            }
            TotalPalabrasArray = [...TotalPalabrasArray, Element]
          })

          TotalPalabrasArray.map((item)=>{
            TotalPalabras = TotalPalabras.concat(item)
          })


          Descripcion_Comparacion.map((item)=>{
            const Element = DescripcionLoop(item, searchsplitedGeneral)
            if(Element === 0){
              return null
            }
            TotalDescripcionArray = [...TotalDescripcionArray, Element]
          })

          TotalDescripcionArray.map((item)=>{
            TotalDescripcion = TotalDescripcion.concat(item)
          })

          var idsPA = new Set(TotalPalabras.map(d => d.id));
          Total = [...TotalPalabras, ...TotalDescripcion.filter(d => !idsPA.has(d.id))];

        } */
        
        setsearchbarresults(Total)
        setInitialState(false)
        setshowresults(true)
        setLoading(false)
       /*  e.target.reset(); */
      }

      useEffect(() => {
        /* ResetSystem() */
      }, [onSubmitNormativa])

      /* async function TagAppereance(e){
        setLoading(true)
        setsearchgeneral(e)
        const searchinputData = e.toLowerCase()
        let searchinputDataCamel = camelCase(e)
        //const searchinputDataProve = [e, e.toLowerCase(), e.toUpperCase(), camelCase(e).replace(/([a-z])([A-Z])/g, '$1 $2'), camelCase(e).replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3') ]
        
        const PalabrasClave_Comparacion_Lower = await API.graphql({query:listNewNormas, variables : { filter : { PalabrasClave : { contains : searchinputData} }}})
        const PalabrasClave_Comparacion_Camel = await API.graphql({query:listNewNormas, variables : { filter : { PalabrasClave : { contains : searchinputDataCamel} }}})
        let PalabrasClave_Comparacion = PalabrasClave_Comparacion_Lower.data.listNewNormas.items.concat(PalabrasClave_Comparacion_Camel.data.listNewNormas.items)

        let PalabrasClave_Comparacion = PalabrasClave_Comparacion_Lower.data.listNewPaperss.items.concat(PalabrasClave_Comparacion_Camel.data.listNewPaperss.items, PalabrasClave_Comparacion_Upper.data.listNewPaperss.items)

        let PalabrasClave_Comparacion_Puro = PalabrasClave_Comparacion.filter((valorActual, indiceActual, arreglo) => {
          return arreglo.findIndex(valorDelArreglo => JSON.stringify(valorDelArreglo) === JSON.stringify(valorActual)) === indiceActual
        });

        const Descripcion_Comparacion_Lower = await API.graphql({query:listNewNormas, variables : { filter : { Descripcion : { contains : searchinputData} }}})
        const Descripcion_Comparacion_Camel = await API.graphql({query:listNewNormas, variables : { filter : { Descripcion : { contains : searchinputDataCamel} }}})
        let Descripcion_Comparacion = Descripcion_Comparacion_Lower.data.listNewNormas.items.concat(Descripcion_Comparacion_Camel.data.listNewNormas.items)

        var idsPA = new Set(PalabrasClave_Comparacion_Puro.map(d => d.id));
        var Total = [...PalabrasClave_Comparacion_Puro, ...Descripcion_Comparacion.filter(d => !idsPA.has(d.id))];
 
        setsearchbarresults(Total)
        setInitialState(false)
        setshowresults(true)
        setLoading(false)
       /*  e.target.reset(); 
      } */

      const filterGeneral = (data) => {
        setdocumenttype(data.tipodedocumento)
      }
      const clearfilter = () => {
        setdocumenttype(null)
        reset2()
      }

    return(
        <div style={{paddingBottom:"20%", backgroundColor:"#EAEAEA"}} className="slide-in-left">
            <Link to="/biblioteca">
            <div style={{paddingTop: "3%",height:"130px"}}>
                <GoNormas >Ir a Biblioteca<RightOutlined /></GoNormas>
            </div>
            </Link>
           <div style={{display:'inline-flex', alignItems: "center", width:"100%", padding:"5% 28%"}}>
            <img src={LogoCluster}/>
            <InputBar heightbar="9rem" marginbar="0 10%" backgroundColor="#440A78" borderColor="#440A78"/>
            <GeneralTitle>Marco Normativo <br/> de las Enfermedades Raras</GeneralTitle>
           </div>

           <div>
            {/* <div style={{marginBottom:"3%"}}>
              <h1>Búsquedas frecuentes</h1>
              <div style={{display:"inline-flex"}}>
                <TagFrecuent onClick={(e)=>{TagAppereance(e.target.value)}} name="tag" value="corte" type="button" />
                <TagFrecuent onClick={(e)=>{TagAppereance(e.target.value)}} name="tag" value="Medicamentos" type="button" />
                <TagFrecuent onClick={(e)=>{TagAppereance(e.target.value)}} name="tag" value="derechos" type="button" />
                <TagFrecuent onClick={(e)=>{TagAppereance(e.target.value)}} name="tag" value="acceso" type="button" />
                <TagFrecuent onClick={(e)=>{TagAppereance(e.target.value)}} name="tag" value="vitales" type="button" />
              </div>
            </div> */}
            <form key={1} onSubmit={handleSubmit(onSubmitNormativa)}>

            <div style={{display:'inline-flex',width:"80%"}}>
                    <InputBar marginbar="0 0 0 15%" backgroundColor="#440A78" borderColor="#440A78"/>
                    <InputContent  width="80%" style={{borderRadius:"0px 15px 15px 0px", border:"none"}} name="searchbar" placeholder=" Busqueda" ref={register({ required: 'This is required' })}/>
            </div>
            <br/>
            
            <InputSubmit background="#440A78"  name="searchbar" margin="2rem 0" width="30%" height="30%" type="submit" value="BUSCAR NORMAS" />
            </form>
            <div style={{margin:"0px 30px"}}>
                <form key={2} name="Filters" onSubmit={handleSubmit2(filterGeneral)}>
                  <div style={{display:'inline-flex'}}>

                    <div className="input-checkbox">
                      <input  name="tipodedocumento" value="Ley" type="radio" ref={register2}/>
                      <LabelBiblioteca>Ley</LabelBiblioteca>
                    </div>
                    <div className="input-checkbox">
                      <input  name="tipodedocumento" value="Decreto" type="radio" ref={register2}/>
                      <LabelBiblioteca>Decreto</LabelBiblioteca>
                    </div>
                    <div className="input-checkbox">
                      <input  name="tipodedocumento" value="Resolucion" type="radio" ref={register2}/>
                      <LabelBiblioteca>Resolución</LabelBiblioteca>
                    </div>

                    <div className="input-checkbox">
                      <input  name="tipodedocumento" value="Circular" type="radio" ref={register2}/>
                      <LabelBiblioteca>Circular</LabelBiblioteca>
                    </div>
                    <div className="input-checkbox">
                      <input  name="tipodedocumento" value="Sentencia" type="radio" ref={register2}/>
                      <LabelBiblioteca>Sentencia</LabelBiblioteca>
                    </div>

                    <div className="input-checkbox">
                      <input  name="tipodedocumento" value="Acuerdo" type="radio" ref={register2}/>
                      <LabelBiblioteca>Acuerdo</LabelBiblioteca>
                    </div>

                    <div className="input-checkbox">
                      <input  name="tipodedocumento" value="Constitucion" type="radio" ref={register2}/>
                      <LabelBiblioteca>Constitución</LabelBiblioteca>
                    </div>

                    <div className="input-checkbox">
                      <input  name="tipodedocumento" value="Ordenanza" type="radio" ref={register2}/>
                      <LabelBiblioteca>Ordenanza</LabelBiblioteca>
                    </div>

                    <div className="input-checkbox">
                      <input  name="tipodedocumento" value="Otro" type="radio" ref={register2}/>
                      <LabelBiblioteca>Otro</LabelBiblioteca>
                    </div>
                  </div>
                    <InputSubmit background="#440A78" name="Filters" width="10%" height="10%" type="submit" value="FILTRAR" />
                    <InputSubmit borderdata="2px solid #E6384A" background="#E6384A" width="10%" height="10%" onClick={clearfilter}  name="eliminarfiltros" value="Eliminar Filtros" type="button" ref={register2}/>
                </form>
            </div>

            {
              Loading === false ?
                     searchbarresults.length !== 0 ?
                        <div style={{margin:"0 5%"}} className="site-card-wrapper cardbox-layout-general">
                          <MainTitle>Mostrando {searchbarresults.length} resultados de &nbsp; <strong>{searchbargeneral}</strong>{documenttype === null ? null :  <p> &nbsp; filtrados por <strong>{documenttype}</strong></p>}</MainTitle>
                            <Row gutter={16}>                
                                  {searchbarresults.map((result)=>{
                                    if(documenttype == null){
                                      return(
                                        <Col key={result.id} span={8}>
                                          <CardNormas  info={result}></CardNormas>
                                        </Col> 
                                       )
                                    }
                                    else{
                                      if(documenttype === result.TipoNorma_orig){
                                        return(
                                          <Col key={result.id} span={8}>
                                            <CardNormas  info={result}></CardNormas>
                                          </Col> 
                                         )
                                      }
                                      else{
                                        return null
                                      }
                                    }
                                         
                                  })}
                            </Row>
                        </div>
                        :
                        showresults ?
                        <div>
                        <Empty className="empty-box"  
                        description={
                          <span>
                           No hay Resultados actualmente
                          </span>
                        } />
                        </div>
                        :
                        <div>Haz tu primera busqueda</div>
                        :
                        <div>
                          <LoadingOutlined />
                        </div>
                }
            </div>
        </div>
    )
}