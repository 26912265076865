import React from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid';
import LogoCluster from '../assets/img/LogoClusterEnfermedades.png'
import LogoBIIB from '../assets/img/Footer_logoBIIB.png'
import LogoMerck from '../assets/img/Footer_logoMerck.png'
import LogoNovartis from '../assets/img/Footer_logoNovartis.png'
import LogoRoche from '../assets/img/Footer_logoRoche.png'
import LogoBoehringer from "../assets/img/Boehringer_Ingelheim_Logo.svg"

const GeneralFooterInt = styled.div`
  width:100%;
  height:100%;
  background-color: #240628;
  position:relative;
  bottom:0;
  left:0;
`

const LogoClusterBox = styled.img.attrs({src:LogoCluster})`
    width:30%;
    @media only screen and (max-width: 600px) {
      width:80%;
    }
    @media only screen and (min-width: 600px) {
      width:100%;
    }
    @media only screen and (min-width: 800px) {
      width:70%;
    }
    @media only screen and (min-width: 1440px) {
      width:60%;
    }
`

const DataArea = styled.div`
  width:90%;
  font-family:'Poppins';
  font-size:2rem;
  font-weight:'700';
  color: white;
  margin: 20% 0 20% 5%;
  text-align:left;
  @media only screen and (max-width: 600px) {
    text-align:center;
 }
`

const Patrocina = styled.p`
 font-family:"Acherus Grotesque";
 font-weight:900; 
 color:white;
 text-align:left;
 font-size:2rem;
 padding:2% 0 0 2%;
 @media only screen and (max-width: 941px) {
    text-align:center;
 }
`

const LogoBIIBBox = styled.img.attrs({src:LogoBIIB})`
width:80%;
@media only screen and (max-width: 941px) {
    text-align:center;
    width:60%;
 }
`

const LogoRocheBox = styled.img.attrs({src:LogoRoche})`
width:80%;
@media only screen and (max-width: 941px) {
    text-align:center;
    width:60%;
 }
`

const LogoNovartisBox = styled.img.attrs({src:LogoNovartis})`
width:80%;
@media only screen and (max-width: 941px) {
    text-align:center;
    width:60%;
 }
`

const LogoMerckBox = styled.img.attrs({src:LogoMerck})`
width:80%;
@media only screen and (max-width: 941px) {
    text-align:center;
    width:60%;
 }
`

const LogoBoehringerBox = styled.img.attrs({src:LogoBoehringer})`
width:80%;
@media only screen and (max-width: 941px) {
    text-align:center;
    width:60%;
 }
`


export default function GeneralFooter(){


    return(
        <GeneralFooterInt>
            <Grid style={{width:"100%"}} container spacing={3}>
                <Grid item xs={12} sm={12} md={4} lg={12}>
                <Patrocina style={{fontFamily:"Acherus Grotesque", fontWeight:"700", color:"white"}}>Patrocina</Patrocina>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={12}>
                    <Grid style={{width:"100%"}} container spacing={3} alignItems="center">
                        <Grid item xs={12} sm={12} md={4} lg={2}>
                                <LogoBIIBBox  />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={2}>
                                <LogoRocheBox />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                                <LogoBoehringerBox  />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                                <LogoNovartisBox  />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={2}>
                                <LogoMerckBox  />
                        </Grid>

                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={12} lg={12}>
                    <div style={{alignItems:"center", display:"inline-flex", width:"54%"}}>
                        <LogoClusterBox />
                        <DataArea>
                            <p>Clúster de Enfermedades Raras</p>
                            <p>Av. Cra. 9 No. 131 A - 02
                                <br/>
                            Línea gratuita: 018000 113033
                            <br/>
                            PBX: (571) 648 9000 Ext. 1910 - 1124
                            <br/>
                            Contáctanos en: cer@unbosque.edu.co
                            <br/>
                            Vigilada Mineducación</p>
                        </DataArea>
                    </div>
                </Grid>
            </Grid>
        </GeneralFooterInt>
    )
}