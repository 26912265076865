import React, {useContext, useState, useEffect, useRef} from 'react'
import { Button, Form, Input, Tag, InputNumber } from 'antd'
import Amplify, { API, graphqlOperation } from 'aws-amplify'
import { Alert, notification } from 'antd';
import { useForm } from "react-hook-form";
import { createNewPapers } from '../../graphql/mutations'
import {MainTitle, ErrorForm} from '../Datadisplay'
import {InputBar, InputContent,Label,InputSubmit, SelectElement,TextAreaElement,SelectOptionElement} from '../Input'

const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} no es un numero valido!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };
const { TextArea } = Input;

export default function PaperForm(event){

    //States and Hooks
    const [alert, setAlert] = useState()
    const {register, handleSubmit, errors} = useForm()


    //Validaciones
    const SuccessAlert = () => {notification["success"]({
        message: 'Enhorabuena!',
        description:
          'Se ha creado el paper con exito',
    });
    }
    const ErrorAlert = () => {notification["error"]({
        message: 'Lo sentimos',
        description:
          'Verifica tu conexión de red o intentalo mas tarde',
    });
    }
    function onFinishFailed(errorInfo){
        ErrorAlert()
    };

    const removeAccents = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    } 

    const GettingData = async (data,e) => {

        const result = {
            ...data,
            titulo: removeAccents(data.titulo.toLowerCase()),
            titulo_orig: data.titulo,
            titulo_secundario: removeAccents(data.titulo_secundario.toLowerCase()),
            titulo_secundario_orig: data.titulo_secundario,
            Paginas: removeAccents(data.Paginas.toLowerCase()),
            Paginas_orig: data.Paginas,
            Volumen: removeAccents(data.Volumen.toLowerCase()),
            Volumen_orig: data.Volumen,
            Numero: removeAccents(data.Numero.toLowerCase()),
            Numero_orig: data.Numero,
            PalabrasClave: removeAccents(data.PalabrasClave.toLowerCase()).split(";").map(x => x.trim()),
            PalabrasClave_orig: data.PalabrasClave,
            Url: removeAccents(data.Url.toLowerCase()),
            Url_orig: data.Url,
            Abstract: removeAccents(data.Abstract.toLowerCase()),
            Abstract_orig: data.Abstract,
            Autores: removeAccents(data.Autores.toLowerCase()).split(";").map(x => x.trim()),
            Autores_orig: data.Autores,
            Licencia: removeAccents(data.Licencia.toLowerCase()),
            Licencia_orig: data.Licencia,
            Idioma: removeAccents(data.Idioma.toLowerCase()),
            Idioma_orig: data.Idioma,
            TipoDocumental: removeAccents(data.TipoDocumental.toLowerCase()),
            TipoDocumental_orig: data.TipoDocumental,
        }
        await API.graphql(graphqlOperation(createNewPapers, {input: result}))
        setAlert(SuccessAlert)
        e.target.reset()
    }
    
    return(
        <div className="scale-in-hor-left">
            <MainTitle size="4rem" aligntext="left">Formulario de Papers</MainTitle>
            <form style={{paddingRight:"10%"}} onSubmit={handleSubmit(GettingData)}>

                <Label>Tipo de Documento</Label>

                <div style={{display:'inline-flex', width:"99%"}}>
                <InputBar/>
                    <SelectElement size="85%" name="TipoDocumental" defaultValue="Articulo" ref={register}>
                        <SelectOptionElement value="Articulo">Artículo</SelectOptionElement>
                        <SelectOptionElement value="Libro">Libro</SelectOptionElement>
                        <SelectOptionElement value="Paper">Paper</SelectOptionElement>
                        <SelectOptionElement value="Paper">Informe</SelectOptionElement>
                        <SelectOptionElement value="Paper">Otro</SelectOptionElement>
                    </SelectElement>
                </div>

                <Label>Título</Label>

                <div style={{display:'inline-flex', width:"99%"}}>
                    <InputBar/>
                    <InputContent name="titulo"  placeholder="Titulo" ref={register({ required: true })}/>
                </div>
                <div>{errors.titulo && <ErrorForm>Este campo es requerido</ErrorForm>}</div>

                <Label>Autores</Label>

                <div style={{display:'inline-flex', width:"99%"}}>
                    <InputBar/>
                    <InputContent name="Autores"  placeholder="Rodriguez,Andres ; Restrepo,Juliana" ref={register}/>
                </div>
                <div>{errors.Autores && <ErrorForm>Este campo es requerido</ErrorForm>}</div>

                <Label>Revista / Editorial </Label>

                <div style={{display:'inline-flex', width:"99%"}}>
                    <InputBar/>
                    <InputContent name="titulo_secundario"  placeholder="Titulo de Revista o Editorial" ref={register}/>
                </div>

                <Label>Fecha (Año)</Label>

                <div style={{display:'inline-flex', width:"99%"}}>
                    <InputBar/>
                    <InputContent name="Fecha" placeholder="1900-2099" min="1900" max="2099" step="1" ref={register}/>
                </div>

                <Label>Volúmen</Label>

                <div style={{display:'inline-flex', width:"99%"}}>
                    <InputBar/>
                    <InputContent name="Volumen" placeholder="Volumen" ref={register}/>
                </div>

                <Label>Número</Label>

                <div style={{display:'inline-flex', width:"99%"}}>
                    <InputBar/>
                    <InputContent name="Numero" placeholder="Numero" ref={register}/>
                </div>

                <Label>Páginas</Label>

                <div style={{display:'inline-flex', width:"99%"}}>
                    <InputBar/>
                    <InputContent name="Paginas" placeholder="Páginas" ref={register}/>
                </div>

                <Label>Abstract</Label>

                <div style={{display:'inline-flex', width:"99%"}}>
                    <InputBar/>
                    <TextAreaElement rows="10" cols="30" name="Abstract" placeholder="Abstract" ref={register} />
                </div>
                <div>{errors.Abstract && <ErrorForm>Este campo es requerido</ErrorForm>}</div>

                <Label>Palabras Clave (Separadas por ;)</Label>

                <div style={{display:'inline-flex', width:"99%"}}>
                    <InputBar/>
                    <InputContent name="PalabrasClave" placeholder="Palabras Clave 1, Palabras Clave 2, Palabras Clave 3" ref={register}/>
                </div>

                <Label>Url</Label>

                <div style={{display:'inline-flex', width:"99%"}}>
                    <InputBar/>
                    <InputContent name="Url" placeholder="Url" ref={register}/>
                </div>

                <Label>Licencia</Label>

                <div style={{display:'inline-flex', width:"99%"}}>
                    <InputBar/>
                    <InputContent name="Licencia" defaultValue="CC" placeholder="Licencia" ref={register}/>
                </div>

                <Label>Idioma</Label>

                <div style={{display:'inline-flex', width:"99%"}}>
                    <InputBar/>
                    <SelectElement name="Idioma" defaultValue="Ingles" ref={register}>
                        <SelectOptionElement value="Ingles">Inglés</SelectOptionElement>
                        <SelectOptionElement value="Espanol">Español</SelectOptionElement>
                        <SelectOptionElement value="Frances">Frances</SelectOptionElement>
                        <SelectOptionElement value="Portugues">Portugués</SelectOptionElement>
                        <SelectOptionElement value="Aleman">Aleman</SelectOptionElement>
                        <SelectOptionElement value="Otro">Otro</SelectOptionElement>
                    </SelectElement>
                </div>

                <InputSubmit width="30%" height="30%" type="submit" value="CREAR PAPER" />
            </form>
        </div>
    )
}