import React, {useContext, useState, useEffect, useRef} from 'react'
import { Button, Form, Input, Tag, InputNumber } from 'antd'
import Amplify, { API, graphqlOperation } from 'aws-amplify'
import { Alert, notification } from 'antd';
import { useForm } from "react-hook-form";
import { createNewNorma } from '../../graphql/mutations'
import {MainTitle, ErrorForm} from '../Datadisplay'
import {InputBar, InputContent,Label,InputSubmit, SelectElement,TextAreaElement,SelectOptionElement} from '../Input'

const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} no es un numero valido!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };
const { TextArea } = Input;

export default function NormaForm(event){

    //States and Hooks
    const [alert, setAlert] = useState()
    const {register, handleSubmit, errors} = useForm()


    //Validaciones
    const SuccessAlert = () => {notification["success"]({
        message: 'Enhorabuena!',
        description:
          'Se ha creado el paper con exito',
    });
    }
    const ErrorAlert = () => {notification["error"]({
        message: 'Lo sentimos',
        description:
          'Verifica tu conexión de red o intentalo mas tarde',
    });
    }
    function onFinishFailed(errorInfo){
        ErrorAlert()
    };
    const removeAccents = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }
    const GettingData = async (data,e) => {
        const result = {
            ...data,
            TipoNorma:removeAccents(data.TipoNorma.toLowerCase()),
            TipoNorma_orig:data.TipoNorma,
            Nombre:removeAccents(data.Nombre.toLowerCase()),
            Nombre_orig:data.Nombre,
            Descripcion:removeAccents(data.Descripcion.toLowerCase()),
            Descripcion_orig:data.Descripcion,
            Fecha:data.Fecha,
            Entidad:removeAccents(data.Entidad.toLowerCase()),
            Entidad_orig:data.Entidad,
            PalabrasClave:removeAccents(data.PalabrasClave.toLowerCase()).split(";").map(x => x.trim()),
            PalabrasClave_orig:data.PalabrasClave,
            URL:removeAccents(data.URL.toLowerCase()),
            URL_orig:data.URL
        }
        await API.graphql(graphqlOperation(createNewNorma, {input: result}))
        setAlert(SuccessAlert)
        e.target.reset()
    }
    
    return(
        <div className="scale-in-hor-left">
            <MainTitle size="4rem" aligntext="left">Formulario de Normas</MainTitle>
            <form style={{paddingRight:"10%"}} onSubmit={handleSubmit(GettingData)}>

                <Label>Tipo de Norma</Label>

                <div style={{display:'inline-flex', width:"99%"}}>
                <InputBar/>
                    <SelectElement name="TipoNorma" defaultValue="Ley" ref={register}>
                        <SelectOptionElement value="Ley">Ley</SelectOptionElement>
                        <SelectOptionElement value="Decreto">Decreto</SelectOptionElement>
                        <SelectOptionElement value="Resolucion">Resolución</SelectOptionElement>
                        <SelectOptionElement value="Circular">Circular</SelectOptionElement>
                        <SelectOptionElement value="Sentencia">Sentencia</SelectOptionElement>
                        <SelectOptionElement value="Acuerdo">Acuerdo</SelectOptionElement>
                        <SelectOptionElement value="Constitucion">Constitución</SelectOptionElement>
                        <SelectOptionElement value="Ordenanza">Ordenanza</SelectOptionElement>
                        <SelectOptionElement value="Otro">Otro</SelectOptionElement>
                    </SelectElement>
                </div>

                <Label>Nombre</Label>

                <div style={{display:'inline-flex', width:"99%"}}>
                    <InputBar/>
                    <InputContent name="Nombre"  placeholder="Nombre" ref={register({ required: true })}/>
                </div>
                <div>{errors.Nombre && <ErrorForm>Este campo es requerido</ErrorForm>}</div>

                <Label>Descripción</Label>

                <div style={{display:'inline-flex', width:"99%"}}>
                    <InputBar/>
                    <TextAreaElement rows="10" cols="30" name="Descripcion" placeholder="Descripcion" ref={register} />
                </div>
                <div>{errors.Descripcion && <ErrorForm>Este campo es requerido</ErrorForm>}</div>

                <Label>Fecha (Año)</Label>

                <div style={{display:'inline-flex', width:"99%"}}>
                    <InputBar/>
                    <InputContent name="Fecha" placeholder="1900-2099" min="1900" max="2099" step="1" ref={register}/>
                </div>
                
                <Label>Entidad</Label>

                <div style={{display:'inline-flex', width:"99%"}}>
                    <InputBar/>
                    <SelectElement name="Entidad" defaultValue="Presidencia de la Republica" ref={register}>
                        <SelectOptionElement value="Superintendencia de Salud">Superintendencia de Salud</SelectOptionElement>
                        <SelectOptionElement value="Ministerio de Salud">Ministerio de Salud</SelectOptionElement>
                        <SelectOptionElement value="Corte Constitucional">Corte Constitucional</SelectOptionElement>
                        <SelectOptionElement value="Presidencia de la Republica">Presidencia de la Republica</SelectOptionElement>
                        <SelectOptionElement value="Alcaldia">Alcaldia</SelectOptionElement>
                        <SelectOptionElement value="Gobernacion">Gobernación</SelectOptionElement>
                        <SelectOptionElement value="Contraloria General de la Republica">Contraloria General de la Republica</SelectOptionElement>
                        <SelectOptionElement value="Procuraduria General de la Nacion">Procuraduria General de la Nacion</SelectOptionElement>
                        <SelectOptionElement value="Congreso de la Republica">Congreso de la Republica</SelectOptionElement>
                        <SelectOptionElement value="Departamento Nacional de Planeacion">Departamento Nacional de Planeación</SelectOptionElement>
                        <SelectOptionElement value="Ministerio TICs">Ministerio TICs</SelectOptionElement>
                        <SelectOptionElement value="Ministerio de Trabajo">Ministerio de Trabajo</SelectOptionElement>
                        <SelectOptionElement value="Ministerio de Vivienda">Ministerio de Vivienda</SelectOptionElement>
                        <SelectOptionElement value="Ministerio de Educación">Ministerio de Educación</SelectOptionElement>
                        <SelectOptionElement value="SuperIntendencia de Industria y Comercio">SuperIntendencia de Industria y Comercio</SelectOptionElement>
                        <SelectOptionElement value="Invima">Invima</SelectOptionElement>
                        <SelectOptionElement value="IETS">IETS</SelectOptionElement>
                        <SelectOptionElement value="ADRES">ADRES</SelectOptionElement>
                        <SelectOptionElement value="INS">INS</SelectOptionElement>
                        <SelectOptionElement value="Minciencias">Minciencias</SelectOptionElement>
                        <SelectOptionElement value="Ministerio de Ciencia Tecnología e Innovación">Ministerio de Ciencia Tecnología e Innovación</SelectOptionElement>
                        <SelectOptionElement value="Consejo de Estado">Consejo de Estado</SelectOptionElement>
                        <SelectOptionElement value="Corte Suprema de Justicia">Corte Suprema de Justicia</SelectOptionElement>
                        <SelectOptionElement value="Otros">Otros</SelectOptionElement>
                    </SelectElement>
                </div>

                <Label>Temas </Label>

                <div style={{display:'inline-flex', width:"99%"}}>
                    <InputBar/>
                    <InputContent name="PalabrasClave" placeholder="Palabras Clave 1; Palabras Clave 2; Palabras Clave 3" ref={register}/>
                </div>

                <Label>Url</Label>

                <div style={{display:'inline-flex', width:"99%"}}>
                    <InputBar/>
                    <InputContent name="URL" placeholder="Url" ref={register}/>
                </div>

                <Label>Numero</Label>

                <div style={{display:'inline-flex', width:"99%"}}>
                    <InputBar/>
                    <InputContent name="Numero" placeholder="Numero" ref={register}/>
                </div>
                

                <InputSubmit width="30%" height="30%" type="submit" value="CREAR NORMA" />

            </form>
        </div>
    )
}