import React, {useEffect, useState} from 'react'
import {Typography, Divider, Button, Row, Col, notification} from 'antd'
import Amplify, { API, graphqlOperation } from 'aws-amplify'
import { getNewInvestigacion } from '../../../graphql/queries'
import {MainTitle, ErrorForm} from '../../Datadisplay'
import Paper  from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import styled from 'styled-components'
import {DoubleRightOutlined} from '@ant-design/icons'
import { updateNewInvestigacion } from '../../../graphql/mutations'
import { useForm } from "react-hook-form";
import {InputBar, InputContent,Label,InputSubmit, SelectElement,TextAreaElement,SelectOptionElement} from '../../Input'

const TitleDetail = styled.h1`
 font-size:2rem;
 font-family: "Roboto";
 color: #6C0E60;
 text-align: left;
`
const SubtitleDetail = styled.p`
 font-size:1rem;
 font-family: "Roboto";
 text-align: left;
 font-weight: 200
 font-variant: italic
`
const InfoDetailedTitle = styled.p`
 font-size:1rem;
 font-family: "Roboto";
 color: #6C0E60;
 text-align: left;
 font-weight: 700;
`

const dataInvestigacion = 
{
    id:"",
    Nombre: "",
    Nombre_orig: "",
    Entidad: "",
    Entidad_orig: "",
    Ciudad: "",
    Ciudad_orig: "",
    URL: "",
    URL_orig: "",
    URLS3: "",
    URLS3_orig: "",
}


const InvestigacionEditForm= (props)=>{
    const {register, handleSubmit, errors} = useForm()
    const [investigacionID, setinvestigacionID] = useState(props.idInvestigacion)
    let [info, setinfo] = useState(dataInvestigacion)
    const [alert, setAlert] = useState()
    let [investigacionElement,setinvestigacionElement] = useState({
        Nombre: "",
        Nombre_orig: "",
        Entidad: "",
        Entidad_orig: "",
        Ciudad: "",
        Ciudad_orig: "",
        URL: "",
        URL_orig: "",
        URLS3: "",
        URLS3_orig: "",
    })

    const SuccessAlert = () => {notification["success"]({
        message: 'Enhorabuena!',
        description:
          'Se ha creado el paper con exito',
    });
    }

    useEffect( async ()=>{
        const DataInvestigacion = await API.graphql(graphqlOperation(getNewInvestigacion, {id:investigacionID}))
        setinfo(DataInvestigacion.data.getNewInvestigacion)
      },[]) 
    
    useEffect(()=>{
        setinvestigacionElement({
            Nombre: info.Nombre_orig,
            Entidad: info.Entidad_orig,
            Ciudad: info.Ciudad_orig,
            URL: info.URL_orig,
        })
    },[info])

    const removeAccents = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    } 

    const UpdateData = async (data,e) => {
    const investigacionUpdateID = investigacionID.toString()
      await API.graphql({query:updateNewInvestigacion, variables: { input: {
        ...data,
        id:investigacionUpdateID,
        Nombre: removeAccents(data.Nombre).toLowerCase(),
        Nombre_orig: data.Nombre,
        Entidad: removeAccents(data.Entidad).toLowerCase(),
        Entidad_orig: data.Entidad,
        Ciudad: removeAccents(data.Ciudad).toLowerCase(),
        Ciudad_orig: data.Ciudad,
        URL: removeAccents(data.URL).toLowerCase(),
        URL_orig: data.URL,
      }}})
      setAlert(SuccessAlert)
      e.target.reset()
    }
    
    return(
        <div style={{padding: "3%"}}>
            <Paper style={{padding: "3%"}}  elevation={3}>
            <Row>
                <Col span={12} style={{padding:"0 4% 0 0"}}>
                <TitleDetail>{info.Nombre_orig}</TitleDetail>
                <div>
                    <Row>
                        <Col span={24}>
                            <List>
                                <ListItem>
                                    <Row className="detailpaper-row-list">
                                        <Col span={24}>
                                            <div style={{display: "inline-flex", alignContent:"center", alignItems:"baseline"}}>
                                                <DoubleRightOutlined style={{margin: "0px 10px 0px 0px"}} /><InfoDetailedTitle>URL</InfoDetailedTitle>
                                            </div>
                                            <p>{info.URL_orig}</p>
                                        </Col>
                                    </Row>
                                </ListItem>
                                <Divider className="detailpaper-divider-list"/>
                                <ListItem>
                                    <Row className="detailpaper-row-list">
                                        <Col span={24}>
                                            <div style={{display: "inline-flex", alignContent:"center", alignItems:"baseline"}}>
                                                <DoubleRightOutlined style={{margin: "0px 10px 0px 0px"}} /><InfoDetailedTitle>Entidad</InfoDetailedTitle>
                                            </div>
                                            <p>{info.Entidad_orig}</p>
                                        </Col>
                                    </Row>
                                </ListItem>
                                <Divider className="detailpaper-divider-list"/>
                                <ListItem>
                                    <Row className="detailpaper-row-list">
                                        <Col span={24}>
                                            <div style={{display: "inline-flex", alignContent:"center", alignItems:"baseline"}}>
                                                <DoubleRightOutlined style={{margin: "0px 10px 0px 0px"}} /><InfoDetailedTitle>Ciudad</InfoDetailedTitle>
                                            </div>
                                            <p>{info.Ciudad_orig}</p>
                                        </Col>
                                    </Row>
                                </ListItem>
                                <Divider className="detailpaper-divider-list"/>
                            </List>
                        </Col>
                        <Col span={6}><div></div></Col>
                    </Row> 
                </div>
                </Col>
                <Col span={12}>
                <div className="scale-in-hor-left">
                    <MainTitle size="4rem" aligntext="left">Formulario de Investigacion</MainTitle>
                    <form style={{paddingRight:"10%"}} onSubmit={handleSubmit(UpdateData)}>

                    <Label>Nombre</Label>

                    <div style={{display:'inline-flex', width:"99%"}}>
                        <InputBar/>
                        <InputContent name="Nombre"  onChange={(event)=>setinvestigacionElement({...investigacionElement, Nombre:event.target.value})} value={investigacionElement.Nombre} ref={register({ required: true })}/>
                    </div>
                    <div>{errors.titulo && <ErrorForm>Este campo es requerido</ErrorForm>}</div>

                    <Label>Entidades</Label>

                    <div style={{display:'inline-flex', width:"99%"}}>
                        <InputBar/>
                        <InputContent name="Entidad"  onChange={(event)=>setinvestigacionElement({...investigacionElement, Entidad:event.target.value})} value={investigacionElement.Entidad} ref={register({ required: true })}/>
                    </div>
                    <div>{errors.titulo && <ErrorForm>Este campo es requerido</ErrorForm>}</div>


                    <Label>Ciudad</Label>

                    <div style={{display:'inline-flex', width:"99%"}}>
                        <InputBar/>
                        <InputContent name="Ciudad"  onChange={(event)=>setinvestigacionElement({...investigacionElement, Ciudad:event.target.value})} value={investigacionElement.Ciudad} ref={register}/>
                    </div>

                    <Label>URL</Label>

                    <div style={{display:'inline-flex', width:"99%"}}>
                        <InputBar/>
                        <InputContent name="URL"  onChange={(event)=>setinvestigacionElement({...investigacionElement, URL:event.target.value})} value={investigacionElement.URL} ref={register}/>
                    </div>

                    <InputSubmit width="50%" height="30%" type="submit" value="PUBLICAR ACTUALIZAR DE INVESTIGACION" />

                    </form>
                </div>
                </Col>
            </Row>
            </Paper>
        </div>
    )
}

export default InvestigacionEditForm