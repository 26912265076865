import React, {useEffect, useState} from 'react'
import {Typography, Divider, Button, Row, Col} from 'antd'
import Amplify, { API, graphqlOperation } from 'aws-amplify'
import { getPapers } from '../../graphql/queries'
import { Table, Tag, Space } from 'antd';
import Paper  from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import styled from 'styled-components'
import {DoubleRightOutlined} from '@ant-design/icons'
import {Link} from 'react-router-dom'
import PaperEditForm from './editforms/PaperEditForm'
import NormaEditForm from './editforms/NormaEditForm'
import PlatformEditForm from './editforms/PlatformEditForm'
import AudioVisualEditForm from './editforms/AudioVisualEditForm'
import OrganizacionEditForm from './editforms/OrganizacionEditForm'
import ProfesionalEditForm from './editforms/ProfesionalEditForm'
import InvestigacionEditForm from './editforms/InvestigacionEditForm'

const TitleDetail = styled.h1`
 font-size:2rem;
 font-family: "Roboto";
 color: #6C0E60;
 text-align: left;
`
const SubtitleDetail = styled.p`
 font-size:1rem;
 font-family: "Roboto";
 text-align: left;
 font-weight: 200
 font-variant: italic
`
const InfoDetailedTitle = styled.p`
 font-size:1rem;
 font-family: "Roboto";
 color: #6C0E60;
 text-align: left;
 font-weight: 700;
`


const dataPaper = 
{
      id:"",
      titulo:"Cargando...",
      titulo_secundario:"",
      Journal:"",
      Paginas:"null",
      Volumen:"null",
      Numero:"null",
      PalabrasClave:[],
      Fecha:null,
      Url:"",
      Abstract:"",
      Autores:[],
}
export default function EditDetail(props){
    console.log(props)
    const [typedata, settypedata] = useState(props.location.state.idType)
    const [GeneralIDToken, setGeneralIDToken] = useState(props.match.params.generalID)
    let [info, setinfo] = useState(dataPaper)

    return(
        <div style={{margin:"9% 0"}}>
            <div>
                {
                    typedata === "Papers" ?
                    <div>
                        <PaperEditForm idPaper={GeneralIDToken}/>
                    </div>
                    :
                    typedata === "Normas" ?
                    <div>
                        <NormaEditForm idNorma={GeneralIDToken}/>
                    </div>
                    :
                    typedata === "Plataformas" ?
                    <div>
                        <PlatformEditForm idPlataforma={GeneralIDToken}/>
                    </div>
                    :
                    typedata === "AudioVisuales" ?
                    <div>
                        <AudioVisualEditForm idAudiovisual={GeneralIDToken}/>
                    </div>
                    :
                    typedata === "OrganizacionPacientes" ?
                    <div>
                        <OrganizacionEditForm idOrganizacion={GeneralIDToken}/>
                    </div>
                    :
                    typedata === "Profesionales" ?
                    <div>
                        <ProfesionalEditForm idProfesional={GeneralIDToken}/>
                    </div>
                    :
                    typedata === "Investigacion" ?
                    <div>
                        <InvestigacionEditForm idInvestigacion={GeneralIDToken}/>
                    </div>
                    :
                    <div></div>
                }
            </div>
        </div>
    )
}