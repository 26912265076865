import React from 'react'
import Swal from 'sweetalert2'
import Amplify, { API, graphqlOperation } from 'aws-amplify'
import { deleteNewPapers, deleteNewNorma, deleteNewPlataforma, deleteNewAudiovisual, deleteNewOrganizacionPaciente, deleteNewProfesional, deleteNewInvestigacion  } from '../graphql/mutations'

const deleteAlert = async (idDeleted, typeElement) => {
    let idGeneral = {
        id: idDeleted.id
    }
    Swal.fire({
        title: '¿Esta seguro que desea borrar este documento',
        text: "Esta acción no podra ser revertida",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Borrarlo'
      }).then(async (result) => {
        if (result.isConfirmed) {
          switch(typeElement){
              case "Papers":
                await API.graphql({query : deleteNewPapers , variables : {input: idGeneral}})
              break
              case "Normas":
                await API.graphql({query : deleteNewNorma , variables : {input: idGeneral}})
              break
              case "Plataformas":
                await API.graphql({query : deleteNewPlataforma , variables : {input: idGeneral}})
              break 
              case "AudioVisuales":
                await API.graphql({query : deleteNewAudiovisual , variables : {input: idGeneral}})
              break
              case "OrganizacionPacientes":
                await API.graphql({query : deleteNewOrganizacionPaciente , variables : {input: idGeneral}})
              break
              case "Profesionales":
                await API.graphql({query : deleteNewProfesional , variables : {input: idGeneral}})
              break
              case "Investigacion":
                await API.graphql({query : deleteNewInvestigacion , variables : {input: idGeneral}})
              break
          }
          Swal.fire({
            title:`El archivo ha sido borrado`,
            icon: 'success',
            html: `${idDeleted.titulo} ha sido borrado del sistema`,
          }
          )
        }
      })
}



export {deleteAlert}