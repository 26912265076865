import React,{useEffect, useState} from 'react'
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import styled from 'styled-components'
import {CardPlatform} from '../../components/CardsDisplay'
import { useForm } from "react-hook-form";
import Amplify, { API, graphqlOperation } from 'aws-amplify'
import { listNewPlataformas, syncPapers } from '../../graphql/queries'
import {InputSubmit, InputBar, LabelBiblioteca} from '../../components/Input'
import {Row, Col} from 'antd'


const FilterArea = styled.div`
    width:100%;
    height:200px;
    background-color: black;
    padding:10px;
`
const MainTitleGeneral = styled.h1`
    font-family:"Poppins";
    font-size:2rem;
    font-weight:700;
    text-align:center;
    margin-top:5%;
`

export default function Plataforma(){

    const {register, handleSubmit, errors, control, defaultChecked, reset} = useForm()
    const [platformData, setplatformData] = useState()

    useEffect(async ()=>{
        const GeneralList = await API.graphql({query:listNewPlataformas})
        setplatformData(GeneralList.data.listNewPlataformas.items)
    },[])
    
    return(
        <div style={{marginTop:"10%"}} className="slide-in-left">
            <MainTitleGeneral>Plataformas de Enfermedades Raras</MainTitleGeneral>
            <Divider style={{width:"80%", alignContent:"center", height:"6px", margin:"0 10%", backgroundColor:"#6C0E60" }} variant="middle" />
            <Grid container spacing={3} style={{padding:"5% 5%", width:"100%"}}>
                {/* <Grid item xs={12} sm={12} md={3} lg={3}  >
                <form onSubmit={handleSubmit(onSubmitPlataforma)}>
                    <div style={{paddingLeft:"15%"}}>
                        <div style={{textAlign:"start"}} className="input-checkbox">
                        <input
                            ref={register()}
                            name="InvestigacionMed"
                            value={false}
                            type="checkbox"
                        />
                        <LabelBiblioteca>Investigacion Medica</LabelBiblioteca>
                        </div>

                        <div style={{textAlign:"start"}} className="input-checkbox">
                        <input
                            ref={register()}
                            name="Patologias"
                            value={false}
                            type="checkbox"
                        />
                        <LabelBiblioteca>Patologias Raras</LabelBiblioteca>
                        </div>

                        <div style={{textAlign:"start"}} className="input-checkbox">
                        <input
                            ref={register()}
                            name="Iniciativas"
                            value={false}
                            type="checkbox"
                        />
                        <LabelBiblioteca>Iniciativas en Investigación</LabelBiblioteca>
                        </div>

                        <div style={{textAlign:"start"}} className="input-checkbox">
                        <input
                            ref={register()}
                            name="Pacientes"
                            value={false}
                            type="checkbox"
                        />
                        <LabelBiblioteca>Conexión de Pacientes</LabelBiblioteca>
                        </div>

                        <div style={{textAlign:"start"}} className="input-checkbox">
                        <input
                            ref={register()}
                            name="Medicamentos"
                            value={false}
                            type="checkbox"
                        />
                        <LabelBiblioteca>Medicamentos Huerfanos</LabelBiblioteca>
                        </div>
                    </div>
                    <br/>
                    <InputSubmit name="searchbar" margin="2rem 0" width="30%" height="30%" type="submit" value="FILTRAR" />
                    </form>
                </Grid>
                <Divider orientation="vertical" flexItem /> */}
                <Grid item xs={12} sm={12} md={12} lg={12} >
                    {
                        platformData !== undefined ?
                        <div>
                            <Row gutter={16}>                
                                  {platformData.map((result)=>{
                                    return(
                                    <Col span={8}>
                                      <CardPlatform info={result}></CardPlatform>
                                    </Col>
                                    )
                                  })}
                            </Row>
                        </div>
                        :
                        <div>Cargando....</div>
                    } 
                </Grid>
            </Grid>
        </div>
    )
}
