import React, {useState, useLayoutEffect, useEffect} from 'react'
import { Layout, Menu, Breadcrumb, Modal, Button, Space, message } from 'antd';
import {
  DownOutlined,
  MenuOutlined 
} from '@ant-design/icons';
import {Link} from 'react-router-dom'
import '../../styles/layout.css'
import {Redirect, useHistory} from 'react-router-dom'
import LogoFECOER from "../../assets/img/LogoFecoer.png"
import LogoFECOERyU from "../../assets/img/Ubosqueyfecoer.png"
import LogoUBosque from '../../assets/img/Logo_UBosque.svg'
import styled from 'styled-components'
import GeneralFooter from '../../components/Footer'
import Grid from '@material-ui/core/Grid';
import { totpQrcode } from '@aws-amplify/ui';
import TemporaryDrawer from '../../components/HeaderMovil';

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

const Logos = <div style={{display:"inline-flex"}}>
              <img src={LogoUBosque} style={{width:"10%"}}/> 
              <img src={LogoFECOER} style={{width:"10%"}} />
              </div>
const ScrollTopButton = styled.button`
width:100px;
height:100px;
background-color:#6C0E60;
position: fixed;
bottom: 15%;
right: 15%;
color: white;
z-index:100;
`

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export default function LayoutClient(props){
  const [widthGeneral, heightGeneral] = useWindowSize();
  useEffect(()=>{
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  },[])

  const topFunction = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }


    return (
      <div>
      <Layout style={{height:"100vh"}}>
      <Header className="header-general" style={{position:"fixed",display:"block", width:"100%", zIndex:"10", height:"auto", padding:"0 0px"}}>
        <Grid container style={{alignItems:"center"}}>
          <Grid item xs={6} sm={5} md={3} lg={3} >
          <Link to="/">
              <div className="logo-layout" style={{width:"100%", paddingLeft:"20px"}}>
                <img src={LogoFECOERyU} style={{width:"100%"}}/> 
              </div>
          </Link>
          </Grid>
          <Grid item xs={6} sm={7} md={9} lg={9} >
            {
              widthGeneral < 1180 ?
              <TemporaryDrawer/>
              :
              <Menu inlineIndent={30} overflowedIndicator={<MenuOutlined />} className="menu-general" mode="horizontal" style={{ textAlign:"right", paddingRight:"20px", margin:"4% 0 0 0" }} >
                <Menu.Item key="5">
                    <a className="home-link" href="https://www.cer.unbosque.edu.co/" >
                        HomeCER
                    </a>
                  </Menu.Item>
                  <Menu.Item key="1">
                    <Link className="home-link" to="/">
                        Inicio
                    </Link>
                  </Menu.Item>
                  <SubMenu icon={<DownOutlined />} className="submenu-options" key="sub0"  title="Biblioteca" mode="horizontal">
                      <Menu.Item key="10">
                        <Link to="/biblioteca">
                          Papers
                        </Link>
                      </Menu.Item>
                      <Menu.Item key="20">
                        <Link to="/normativa">
                        Marco Normativo
                        </Link>
                      </Menu.Item>
                  </SubMenu>

                  <SubMenu icon={<DownOutlined />} className="submenu-options" key="sub2"  title="Gente y Lugares" mode="horizontal">
                      <Menu.Item key="12">
                      <Link to={`/profesionales`}>
                          CER Especialista
                      </Link>
                      </Menu.Item>
                      <Menu.Item key="22">
                      <Link to={`/organizacion`}>
                        Organizaciones de Pacientes
                      </Link>
                      </Menu.Item>
                      <Menu.Item key="32">
                      <Link to={`/investigacion`}>
                        Grupos de Investigación
                      </Link>
                      </Menu.Item>
                  </SubMenu>
                  <Menu.Item key="11">
                      <Link className="home-link"  to={{pathname:`/plataforma`, state:{type:'Investigacion Medica'}}}>
                          Plataformas
                      </Link>
                  </Menu.Item>
                  <Menu.Item key="61">
                        <Link className="home-link"  to={{pathname:`/media`, state:{type:'Video'}}}> 
                          Recursos Digitales
                        </Link>
                  </Menu.Item>
                  
                  <Menu.Item key="4">
                    <Link to="/admin" style={{color:"white"}}>
                      Login
                    </Link>
                  </Menu.Item>
              </Menu>
            }
          </Grid>
        </Grid>
      </Header>
        <Layout className="site-layout">
          {/* <Header className="site-layout-background" style={{ padding: 0 }} /> */}
          <Content >
            <div className="site-layout-background" style={{marginTop:"70px"}}  >
              {props.children}
            </div> 
            <Footer style={{ textAlign: 'center', padding:"0" }}>
              <GeneralFooter/>
            </Footer>
          </Content>
        </Layout>
      </Layout>
      
    </div>
    );
}