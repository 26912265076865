import React from 'react'

const PalabrasClaveLoop = (objectAutor, arrsearch) => {
    let objCompare = objectAutor
    let TotalElement = []
    let newElement = [arrsearch[0]]
    for (let index = 1; index <= arrsearch.length; index++) {
      newElement.push(arrsearch[index])
      let Compared = newElement.join(" ")
      if(objCompare.PalabrasClave.includes(Compared)){
        TotalElement.push(objCompare)
      }
      if(objCompare.PalabrasClave.includes(Compared) === []){
        TotalElement = []
        break
      }
    }
    return TotalElement
}

const AbstractLoop = (objectAutor, arrsearch) => {
    let objCompare = objectAutor
    let TotalElement = []
    let newElement = [arrsearch[0]]

      for (let index = 1; index <= arrsearch.length; index++) {
        newElement.push(arrsearch[index])
        let Compared = newElement.join(" ")
        if(objCompare.Abstract.includes(Compared) && arrsearch.length === index){
          TotalElement.push(objCompare)
        }
        if(objCompare.titulo.includes(Compared) === []){
          TotalElement = []
          break
        }
      }
    return TotalElement

}


const AutorLoop = (objectAutor, arrsearch) => {
    let objCompare = objectAutor
    let TotalElement = []
    let newElement = [arrsearch[0]]

      for (let index = 1; index <= arrsearch.length; index++) {
        newElement.push(arrsearch[index])
        let Compared = newElement.join(" ")
        if(objCompare.Autores.includes(Compared) && arrsearch.length === index){
          TotalElement.push(objCompare)
        }
        if(objCompare.titulo.includes(Compared) === []){
          TotalElement = []
          break
        }
      }
    return TotalElement

}

const TituloLoop = (objectAutor, arrsearch) => {
  let objCompare = objectAutor
  let TotalElement = []
  let newElement = [arrsearch[0]]

    for (let index = 1; index <= arrsearch.length; index++) {
      newElement.push(arrsearch[index])
      let Compared = newElement.join(" ")
      if(objCompare.titulo.includes(Compared)){
        TotalElement.push(objCompare)
      }
      if(objCompare.titulo.includes(Compared) === []){
        TotalElement = []
        break
      }
    }
  return TotalElement

}

const RevistaLoop = (objectAutor, arrsearch) => {
    let objCompare = objectAutor
    let TotalElement = []
    let newElement = [arrsearch[0]]

      for (let index = 1; index <= arrsearch.length; index++) {
        newElement.push(arrsearch[index])
        let Compared = newElement.join(" ")
        if(objCompare.titulo_secundario.includes(Compared)){
          TotalElement.push(objCompare)
        }
        if(objCompare.titulo_secundario.includes(Compared) === []){
          TotalElement = []
          break
        }
      }
    return TotalElement

}

const NombreNormaLoop = (objectNombre, arrsearch) => {
  let objCompare = objectNombre
  let TotalElement = []

    for (let index = 1; index <= arrsearch.length; index++) {
      if(objCompare.Nombre.includes(arrsearch[index]) || objCompare.Nombre_orig.includes(arrsearch[index])){
        TotalElement.push(objCompare)
      }
    }
  
  return TotalElement

}

const DescripcionLoop = (objectAutor, arrsearch) => {
    let objCompare = objectAutor
    let TotalElement = []

      for (let index = 1; index <= arrsearch.length; index++) {
        if(objCompare.Descripcion.includes(arrsearch[index])){
          TotalElement.push(objCompare)
        }
      }
    
    return TotalElement
}


export {DescripcionLoop, PalabrasClaveLoop, AbstractLoop, AutorLoop, TituloLoop, RevistaLoop, NombreNormaLoop}