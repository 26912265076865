/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPapers = /* GraphQL */ `
  query GetPapers($id: ID!) {
    getPapers(id: $id) {
      id
      titulo
      titulo_secundario
      Journal
      Paginas
      Volumen
      Numero
      PalabrasClave
      Fecha
      Url
      Abstract
      Autores
      Licencia
      Idioma
      TipoDocumental
      createdAt
      updatedAt
    }
  }
`;
export const listPaperss = /* GraphQL */ `
  query ListPaperss(
    $filter: ModelPapersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPaperss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        titulo
        titulo_secundario
        Journal
        Paginas
        Volumen
        Numero
        PalabrasClave
        Fecha
        Url
        Abstract
        Autores
        Licencia
        Idioma
        TipoDocumental
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNewPapers = /* GraphQL */ `
  query GetNewPapers($id: ID!) {
    getNewPapers(id: $id) {
      id
      titulo
      titulo_orig
      titulo_secundario
      titulo_secundario_orig
      Journal
      Journal_orig
      Paginas
      Paginas_orig
      Volumen
      Volumen_orig
      Numero
      Numero_orig
      PalabrasClave
      PalabrasClave_orig
      Fecha
      Fecha_orig
      Url
      Url_orig
      Abstract
      Abstract_orig
      Autores
      Autores_orig
      Licencia
      Licencia_orig
      Idioma
      Idioma_orig
      TipoDocumental
      TipoDocumental_orig
      createdAt
      updatedAt
    }
  }
`;
export const listNewPaperss = /* GraphQL */ `
  query ListNewPaperss(
    $filter: ModelNewPapersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNewPaperss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        titulo
        titulo_orig
        titulo_secundario
        titulo_secundario_orig
        Journal
        Journal_orig
        Paginas
        Paginas_orig
        Volumen
        Volumen_orig
        Numero
        Numero_orig
        PalabrasClave
        PalabrasClave_orig
        Fecha
        Fecha_orig
        Url
        Url_orig
        Abstract
        Abstract_orig
        Autores
        Autores_orig
        Licencia
        Licencia_orig
        Idioma
        Idioma_orig
        TipoDocumental
        TipoDocumental_orig
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNorma = /* GraphQL */ `
  query GetNorma($id: ID!) {
    getNorma(id: $id) {
      id
      TipoNorma
      Nombre
      Descripcion
      Entidad
      PalabrasClave
      Fecha
      URL
      Numero
      createdAt
      updatedAt
    }
  }
`;
export const listNormas = /* GraphQL */ `
  query ListNormas(
    $filter: ModelNormaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNormas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        TipoNorma
        Nombre
        Descripcion
        Entidad
        PalabrasClave
        Fecha
        URL
        Numero
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNewNorma = /* GraphQL */ `
  query GetNewNorma($id: ID!) {
    getNewNorma(id: $id) {
      id
      TipoNorma
      TipoNorma_orig
      Nombre
      Nombre_orig
      Descripcion
      Descripcion_orig
      Entidad
      Entidad_orig
      PalabrasClave
      PalabrasClave_orig
      Fecha
      Fecha_orig
      URL
      URL_orig
      Numero
      Numero_orig
      createdAt
      updatedAt
    }
  }
`;
export const listNewNormas = /* GraphQL */ `
  query ListNewNormas(
    $filter: ModelNewNormaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNewNormas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        TipoNorma
        TipoNorma_orig
        Nombre
        Nombre_orig
        Descripcion
        Descripcion_orig
        Entidad
        Entidad_orig
        PalabrasClave
        PalabrasClave_orig
        Fecha
        Fecha_orig
        URL
        URL_orig
        Numero
        Numero_orig
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPlataforma = /* GraphQL */ `
  query GetPlataforma($id: ID!) {
    getPlataforma(id: $id) {
      id
      Nombre
      LogoURL
      LogoS3
      Descripcion
      URL
      Tipo
      createdAt
      updatedAt
    }
  }
`;
export const listPlataformas = /* GraphQL */ `
  query ListPlataformas(
    $filter: ModelPlataformaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlataformas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Nombre
        LogoURL
        LogoS3
        Descripcion
        URL
        Tipo
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNewPlataforma = /* GraphQL */ `
  query GetNewPlataforma($id: ID!) {
    getNewPlataforma(id: $id) {
      id
      Nombre
      Nombre_orig
      LogoURL
      LogoURL_orig
      LogoS3
      LogoS3_orig
      Descripcion
      Descripcion_orig
      URL
      URL_orig
      Tipo
      Tipo_orig
      createdAt
      updatedAt
    }
  }
`;
export const listNewPlataformas = /* GraphQL */ `
  query ListNewPlataformas(
    $filter: ModelNewPlataformaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNewPlataformas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Nombre
        Nombre_orig
        LogoURL
        LogoURL_orig
        LogoS3
        LogoS3_orig
        Descripcion
        Descripcion_orig
        URL
        URL_orig
        Tipo
        Tipo_orig
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAudiovisual = /* GraphQL */ `
  query GetAudiovisual($id: ID!) {
    getAudiovisual(id: $id) {
      id
      TipoAudiovisual
      Nombre
      Fecha
      URL
      URLS3
      createdAt
      updatedAt
    }
  }
`;
export const listAudiovisuals = /* GraphQL */ `
  query ListAudiovisuals(
    $filter: ModelAudiovisualFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAudiovisuals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        TipoAudiovisual
        Nombre
        Fecha
        URL
        URLS3
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNewAudiovisual = /* GraphQL */ `
  query GetNewAudiovisual($id: ID!) {
    getNewAudiovisual(id: $id) {
      id
      TipoAudiovisual
      TipoAudiovisual_orig
      Nombre
      Nombre_orig
      Fecha
      Fecha_orig
      URL
      URL_orig
      URLS3
      URLS3_orig
      createdAt
      updatedAt
    }
  }
`;
export const listNewAudiovisuals = /* GraphQL */ `
  query ListNewAudiovisuals(
    $filter: ModelNewAudiovisualFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNewAudiovisuals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        TipoAudiovisual
        TipoAudiovisual_orig
        Nombre
        Nombre_orig
        Fecha
        Fecha_orig
        URL
        URL_orig
        URLS3
        URLS3_orig
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrganizacionPaciente = /* GraphQL */ `
  query GetOrganizacionPaciente($id: ID!) {
    getOrganizacionPaciente(id: $id) {
      id
      Nombre
      Logo
      LogoS3
      Ciudad
      URL
      Tipo
      createdAt
      updatedAt
    }
  }
`;
export const listOrganizacionPacientes = /* GraphQL */ `
  query ListOrganizacionPacientes(
    $filter: ModelOrganizacionPacienteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizacionPacientes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Nombre
        Logo
        LogoS3
        Ciudad
        URL
        Tipo
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNewOrganizacionPaciente = /* GraphQL */ `
  query GetNewOrganizacionPaciente($id: ID!) {
    getNewOrganizacionPaciente(id: $id) {
      id
      Nombre
      Nombre_orig
      Logo
      Logo_orig
      LogoS3
      LogoS3_orig
      Ciudad
      Ciudad_orig
      URL
      URL_orig
      Tipo
      Tipo_orig
      createdAt
      updatedAt
    }
  }
`;
export const listNewOrganizacionPacientes = /* GraphQL */ `
  query ListNewOrganizacionPacientes(
    $filter: ModelNewOrganizacionPacienteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNewOrganizacionPacientes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Nombre
        Nombre_orig
        Logo
        Logo_orig
        LogoS3
        LogoS3_orig
        Ciudad
        Ciudad_orig
        URL
        URL_orig
        Tipo
        Tipo_orig
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProfesional = /* GraphQL */ `
  query GetProfesional($id: ID!) {
    getProfesional(id: $id) {
      id
      Nombre
      Descripcion
      URL
      URLS3
      createdAt
      updatedAt
    }
  }
`;
export const listProfesionals = /* GraphQL */ `
  query ListProfesionals(
    $filter: ModelProfesionalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfesionals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Nombre
        Descripcion
        URL
        URLS3
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNewProfesional = /* GraphQL */ `
  query GetNewProfesional($id: ID!) {
    getNewProfesional(id: $id) {
      id
      Nombre
      Nombre_orig
      Descripcion
      Descripcion_orig
      URL
      URL_orig
      URLS3
      URLS3_orig
      createdAt
      updatedAt
    }
  }
`;
export const listNewProfesionals = /* GraphQL */ `
  query ListNewProfesionals(
    $filter: ModelNewProfesionalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNewProfesionals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Nombre
        Nombre_orig
        Descripcion
        Descripcion_orig
        URL
        URL_orig
        URLS3
        URLS3_orig
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInvestigacion = /* GraphQL */ `
  query GetInvestigacion($id: ID!) {
    getInvestigacion(id: $id) {
      id
      Nombre
      Entidad
      Ciudad
      URL
      URLS3
      createdAt
      updatedAt
    }
  }
`;
export const listInvestigacions = /* GraphQL */ `
  query ListInvestigacions(
    $filter: ModelInvestigacionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvestigacions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Nombre
        Entidad
        Ciudad
        URL
        URLS3
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNewInvestigacion = /* GraphQL */ `
  query GetNewInvestigacion($id: ID!) {
    getNewInvestigacion(id: $id) {
      id
      Nombre
      Nombre_orig
      Entidad
      Entidad_orig
      Ciudad
      Ciudad_orig
      URL
      URL_orig
      URLS3
      URLS3_orig
      createdAt
      updatedAt
    }
  }
`;
export const listNewInvestigacions = /* GraphQL */ `
  query ListNewInvestigacions(
    $filter: ModelNewInvestigacionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNewInvestigacions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Nombre
        Nombre_orig
        Entidad
        Entidad_orig
        Ciudad
        Ciudad_orig
        URL
        URL_orig
        URLS3
        URLS3_orig
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
