import React, {useEffect} from 'react'
import logo from './logo.svg';
import './App.css';
import {AdminStatus} from './components/store/admin'
import { withAuthenticator } from '@aws-amplify/ui-react'
import LayoutClient from './views/layout/LayoutClient'
import {BrowserRouter as Router} from 'react-router-dom'
import BaseRouter from './Routes'
import 'antd/dist/antd.css';
import './assets/fonts/font/AcherusGrotesque.css'
import './styles/animations.css'
import 'react-h5-audio-player/lib/styles.css';
import ScrollToTop from './components/ScrollToTop'
const topFunction = () => {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}
function App() {
  return (
    <div className="App" onLoad={topFunction}>
      <AdminStatus>
        <Router>
          <LayoutClient>
            <BaseRouter/>
          </LayoutClient>
        </Router>
      </AdminStatus>
    </div>
  );
}

export default App

