import React, {useEffect, useState} from 'react'
import {Typography, Divider, Button, Row, Col, notification} from 'antd'
import Amplify, { API, graphqlOperation } from 'aws-amplify'
import { getNewAudiovisual } from '../../../graphql/queries'
import {MainTitle, ErrorForm} from '../../Datadisplay'
import Paper  from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import styled from 'styled-components'
import {DoubleRightOutlined} from '@ant-design/icons'
import { updateNewAudiovisual} from '../../../graphql/mutations'
import { useForm } from "react-hook-form";
import {InputBar, InputContent,Label,InputSubmit, SelectElement,TextAreaElement,SelectOptionElement} from '../../Input'

const TitleDetail = styled.h1`
 font-size:2rem;
 font-family: "Roboto";
 color: #6C0E60;
 text-align: left;
`
const SubtitleDetail = styled.p`
 font-size:1rem;
 font-family: "Roboto";
 text-align: left;
 font-weight: 200
 font-variant: italic
`
const InfoDetailedTitle = styled.p`
 font-size:1rem;
 font-family: "Roboto";
 color: #6C0E60;
 text-align: left;
 font-weight: 700;
`

const dataAudiovisual = 
{
    id:"",
    TipoAudiovisual:"",
    TipoAudiovisual_orig:"",
    Nombre: "",
    Nombre_orig: "",
    Fecha: "",
    Fecha_orig: "",
    URL: "",
    URL_orig: "",
    URLS3: "",
    URLS3_orig: "",
}


const AudioVisualEditForm= (props)=>{
    const {register, handleSubmit, errors} = useForm()
    const [AudiovisualID, setAudiovisualID] = useState(props.idAudiovisual)
    let [info, setinfo] = useState(dataAudiovisual)
    const [alert, setAlert] = useState()
    const [audiovisualElement, setaudiovisualElement] = useState({
        TipoAudiovisual:"",
        Nombre: "",   
        Fecha: "",
        URL: "",
        URLS3: "",
      })

    const SuccessAlert = () => {notification["success"]({
        message: 'Enhorabuena!',
        description:
          'Se ha creado el paper con exito',
    });
    }

    useEffect( async ()=>{
        const DataAudiovisual = await API.graphql(graphqlOperation(getNewAudiovisual, {id:AudiovisualID}))
        setinfo(DataAudiovisual.data.getNewAudiovisual)
      },[]) 

    useEffect(()=>{
        setaudiovisualElement({
            TipoAudiovisual:info.TipoAudiovisual_orig,
            Nombre: info.Nombre_orig,   
            Fecha: info.Fecha,
            URL: info.URL_orig,
        })
    },[info])

    const removeAccents = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    } 

    const UpdateData = async (data,e) => {
    const audiovisualUpdateID = AudiovisualID.toString()
      await API.graphql({query:updateNewAudiovisual, variables: { input: { 
        id:audiovisualUpdateID,
        TipoAudiovisual:removeAccents(data.TipoAudiovisual).toLowerCase(),
        TipoAudiovisual_orig:data.TipoAudiovisual,
        Nombre: removeAccents(data.Nombre).toLowerCase(),
        Nombre_orig: data.Nombre,
        Fecha: data.Fecha.toLowerCase(),
        Fecha_orig: data.Fecha,
        URL: removeAccents(data.URL).toLowerCase(),
        URL_orig: data.URL,
      }}})
      setAlert(SuccessAlert)
      e.target.reset()
    }
    
    return(
        <div style={{padding: "3%"}}>
            <Paper style={{padding: "3%"}}  elevation={3}>
            <Row>
                <Col span={12} style={{padding:"0 4% 0 0"}}>
                <TitleDetail>{info.Nombre_orig}</TitleDetail>
                <SubtitleDetail><i>{info.Tipo_orig}</i></SubtitleDetail>
                <SubtitleDetail><strong style={{color:"#6C0E60"}}>Fecha</strong></SubtitleDetail>
                <Divider className="detailpaper-divider"/>
                <div>
                    <Row>
                        <Col span={24}><p style={{textAlign:"left"}}>{info.Fecha}</p></Col>
                    </Row> 
                </div>
                <div>
                    <Row>
                        <Col span={24}>
                            <List>
                                <ListItem>
                                    <Row className="detailpaper-row-list">
                                        <Col span={24}>
                                            <div style={{display: "inline-flex", alignContent:"center", alignItems:"baseline"}}>
                                                <DoubleRightOutlined style={{margin: "0px 10px 0px 0px"}} /><InfoDetailedTitle>URL</InfoDetailedTitle>
                                            </div>
                                            <p>{info.URL}</p>
                                        </Col>
                                    </Row>
                                </ListItem>
                                <Divider className="detailpaper-divider-list"/>
                            </List>
                        </Col>
                        <Col span={6}><div></div></Col>
                    </Row> 
                </div>
                </Col>
                <Col span={12}>
                <div className="scale-in-hor-left">
                    <MainTitle size="4rem" aligntext="left">Formulario de Audiovisual</MainTitle>
                    <form style={{paddingRight:"10%"}} onSubmit={handleSubmit(UpdateData)}>

                        <Label>Tipo de AudioVisual</Label>

                    <div style={{display:'inline-flex', width:"99%"}}>
                        <InputBar/>
                        <SelectElement name="TipoAudiovisual" onChange={(event)=>setaudiovisualElement({...audiovisualElement, TipoAudiovisual:event.target.value})} value={audiovisualElement.TipoAudiovisual} ref={register}>
                            <SelectOptionElement value="Video">Video</SelectOptionElement>
                            <SelectOptionElement value="Imagen">Imagen</SelectOptionElement>
                            <SelectOptionElement value="Audio">Audio</SelectOptionElement>
                        </SelectElement>
                    </div>

                    <Label>Nombre</Label>

                    <div style={{display:'inline-flex', width:"99%"}}>
                        <InputBar/>
                        <InputContent name="Nombre"  onChange={(event)=>setaudiovisualElement({...audiovisualElement, Nombre:event.target.value})} value={audiovisualElement.Nombre} ref={register({ required: true })}/>
                    </div>
                    <div>{errors.titulo && <ErrorForm>Este campo es requerido</ErrorForm>}</div>

                    <Label>Fecha (Año)</Label>

                    <div style={{display:'inline-flex', width:"99%"}}>
                        <InputBar/>
                        <InputContent name="Fecha" onChange={(event)=>setaudiovisualElement({...audiovisualElement, Fecha:event.target.value})} value={audiovisualElement.Fecha} min="1900" max="2099" step="1" ref={register}/>
                    </div>
                    
                    <Label>URL del AudioVisual</Label>

                    <div style={{display:'inline-flex', width:"99%"}}>
                        <InputBar/>
                        <InputContent name="URL"  onChange={(event)=>setaudiovisualElement({...audiovisualElement, URL:event.target.value})} value={audiovisualElement.URL} ref={register}/>
                    </div>

                    {/* <Label>URL AudioVisual S3</Label>

                    METER INPUT upload DE LOGO 

                    <div style={{display:'inline-flex', width:"99%"}}>
                        <InputBar/>
                        <InputContent name="LogoURL"  placeholder="www.img.com" ref={register}/>
                    </div> */}

                    <InputSubmit width="30%" height="30%" type="submit" value="ACTUALIZAR ARCHIVO MEDIA" />

                    </form>
                </div>
                </Col>
            </Row>
            </Paper>
        </div>
    )
}

export default AudioVisualEditForm