import React, {useEffect, useState} from 'react'
import {Typography, Divider, Button, Row, Col, notification} from 'antd'
import Amplify, { API, graphqlOperation, Storage } from 'aws-amplify'
import { getNewProfesional, listNewProfesionals } from '../../../graphql/queries'
import {MainTitle, ErrorForm} from '../../Datadisplay'
import Paper  from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import styled from 'styled-components'
import {DoubleRightOutlined} from '@ant-design/icons'
import { updateNewProfesional } from '../../../graphql/mutations'
import { useForm } from "react-hook-form";
import {InputBar, InputContent,Label,InputSubmit, SelectElement,TextAreaElement,SelectOptionElement} from '../../Input'
import { v4 as uuid } from 'uuid'
import config from '../../../aws-exports'
import '../../../styles/styles.css'

const {
    aws_user_files_s3_bucket_region: region,
    aws_user_files_s3_bucket: bucket
} = config

const TitleDetail = styled.h1`
 font-size:2rem;
 font-family: "Roboto";
 color: #6C0E60;
 text-align: left;
`
const SubtitleDetail = styled.p`
 font-size:1rem;
 font-family: "Roboto";
 text-align: left;
 font-weight: 200
 font-variant: italic
`
const InfoDetailedTitle = styled.p`
 font-size:1rem;
 font-family: "Roboto";
 color: #6C0E60;
 text-align: left;
 font-weight: 700;
`

const dataProfesional = 
{
    id:"",
    Nombre:"",
    Nombre_orig:"",
    Descripcion:"",
    Descripcion_orig:"",
    URL:"",
    URL_orig:"",
    URLS3:"",
    URLS3_orig:"",
}


const ProfesionalEditForm= (props)=>{
    const {register, handleSubmit, errors} = useForm()
    const [profesionalID, setprofesionalID] = useState(props.idProfesional)
    let [info, setinfo] = useState(dataProfesional)
    const [alert, setAlert] = useState()
    let [profesionalElement,setprofesionalElement] = useState({
        Nombre:"",
        Nombre_orig:"",
        Descripcion:"",
        Descripcion_orig:"",
        URL:"",
        URL_orig:"",
        URLS3:"",
        URLS3_orig:"",
    })
    const [file, updateFile] = useState(null)
    const [ProfesionalName, updateProfesionalName] = useState('')
    const [Profesionals, updateProfesionals] = useState([])
    let [FilePreviewImg, setFilePreviewImg] = useState()
    let [extensionName, setextensionName] = useState('')

    const SuccessAlert = () => {notification["success"]({
        message: 'Enhorabuena!',
        description:
          'Se ha creado el paper con exito',
    });
    }

    useEffect(() => {
      listProfesionales()
    }, [])
  
    async function listProfesionales() {
        const profesionales = await API.graphql(graphqlOperation(listNewProfesionals))
        updateProfesionals(profesionales.data.listNewProfesionals.items)
    }

    useEffect( async ()=>{
        const DataProfesional = await API.graphql(graphqlOperation(getNewProfesional, {id:profesionalID}))
        setinfo(DataProfesional.data.getNewProfesional)
      },[]) 

    function handleChange(event) {
      const filePreview = event.target.files[0]
      const filePreviewCreate = window.webkitURL.createObjectURL(filePreview)
      setFilePreviewImg(filePreviewCreate)
      const { target: { value, files } } = event
      const fileForUpload = files[0]
      updateProfesionalName(fileForUpload.name.split(".")[0])
      setextensionName(fileForUpload.name)
      updateFile(fileForUpload || value)
    }
    
    useEffect(()=>{
        setprofesionalElement({
            Nombre: info.Nombre_orig,
            Descripcion: info.Descripcion_orig,
            URL: info.URL_orig,
        })
    },[info])

    const removeAccents = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    } 

    const UpdateData = async (data,e) => {
        let inputData = null
        if (file) {
            const extension = file.name.split(".")[1]
            const { type: mimeType } = file
            const key = `images/profesionales/${uuid()}${ProfesionalName}.${extension}`      
            const url = `https://${bucket}.s3.${region}.amazonaws.com/public/${key}`
            inputData = { name: ProfesionalName , image: url }
    
            try {
                await Storage.put(key, file, {
                contentType: mimeType
                })
            } catch (err) {
                console.log('error: ', err)
            }
        }

    const profesionalUpdateID = profesionalID.toString()
      await API.graphql({query:updateNewProfesional, variables: { input: { 
        id:profesionalUpdateID,
        Nombre: removeAccents(data.Nombre).toLowerCase(),
        Nombre_orig: data.Nombre,
        Descripcion: removeAccents(data.Descripcion).toLowerCase(),
        Descripcion_orig: data.Descripcion,
        URL: removeAccents(inputData.image).toLowerCase(),
        URL_orig: inputData.image,
      }}})
      setAlert(SuccessAlert)
      e.target.reset()
    }
    
    return(
        <div style={{padding: "3%"}}>
            <Paper style={{padding: "3%"}}  elevation={3}>
            <Row>
                <Col span={12} style={{padding:"0 4% 0 0"}}>
                <img src={info.URL_orig} style={{width:"50%"}}/>
                <TitleDetail>{info.Nombre_orig}</TitleDetail>
                <SubtitleDetail><strong style={{color:"#6C0E60"}}>Descripción</strong></SubtitleDetail>
                <Divider className="detailpaper-divider"/>
                <div>
                    <Row>
                        <Col span={24}><p style={{textAlign:"left"}}>{info.Descripcion_orig}</p></Col>
                    </Row> 
                </div>
                <div>
                    <Row>
                        <Col span={24}>
                            <List>
                                <ListItem>
                                    <Row className="detailpaper-row-list">
                                        <Col span={24}>
                                            <div style={{display: "inline-flex", alignContent:"center", alignItems:"baseline"}}>
                                                <DoubleRightOutlined style={{margin: "0px 10px 0px 0px"}} /><InfoDetailedTitle>URL</InfoDetailedTitle>
                                            </div>
                                            <p>{info.URL_orig}</p>
                                        </Col>
                                    </Row>
                                </ListItem>
                                <Divider className="detailpaper-divider-list"/>
                            </List>
                        </Col>
                        <Col span={6}><div></div></Col>
                    </Row> 
                </div>
                </Col>
                <Col span={12}>
                <div className="scale-in-hor-left">
                    <MainTitle size="4rem" aligntext="left">Formulario de Profesional</MainTitle>
                    <form style={{paddingRight:"10%"}} onSubmit={handleSubmit(UpdateData)}>

                        <Label>Nombre</Label>

                        <div style={{display:'inline-flex', width:"99%"}}>
                            <InputBar/>
                            <InputContent name="Nombre"  onChange={(event)=>setprofesionalElement({...profesionalElement, Nombre:event.target.value})} value={profesionalElement.Nombre} ref={register({ required: true })}/>
                        </div>
                        <div>{errors.titulo && <ErrorForm>Este campo es requerido</ErrorForm>}</div>

                        <Label>Foto URL</Label>

                        <div style={{display:"block",width:"99%"}}>
                        <input
                                placeholder='Nombre del archivo'
                                value={`${extensionName}`}
                                onChange={e => updateProfesionalName(e.target.files[0].name)}
                                style={{height:"100%", background:"transparent", border: "none"}}
                        />
                        <input
                                type="file"
                                onChange={handleChange}
                                style={{margin: '10px 0px'}}
                                ref={register({ required: true })}
                                id="org-upload"
                            />
                        <label htmlFor="org-upload">Cargar Archivo</label>
                        </div>

                        <img  src={FilePreviewImg} style={{width:"50%", marginTop:"3%"}}/>

                        <Label>Descripción</Label>

                        <div style={{display:'inline-flex', width:"99%"}}>
                            <InputBar/>
                            <TextAreaElement rows="10" cols="30" name="Descripcion" onChange={(event)=>setprofesionalElement({...profesionalElement, Descripcion:event.target.value})} value={profesionalElement.Descripcion} ref={register} />
                        </div>
                        <div>{errors.Autores && <ErrorForm>Este campo es requerido</ErrorForm>}</div>

                        <InputSubmit width="30%" height="30%" type="submit" value="ACTUALIZAR PROFESIONAL" />

                    </form>
                </div>
                </Col>
            </Row>
            </Paper>
        </div>
    )
}

export default ProfesionalEditForm