import React, {useState, useEffect} from 'react'
import { Input, Space, message, Button } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { useForm } from "react-hook-form";
import {Redirect} from 'react-router-dom'
import styled from 'styled-components'
import {InputBar, InputContent, InputSubmit, LabelBiblioteca} from '../../components/Input'
import { LoadingOutlined  } from '@ant-design/icons';

const Cryptr = require('cryptr');
const cryptr = new Cryptr('myTotalySecretKey');
var randomstring = require("randomstring");

const success = () => {
    message.success('Bienvenido Administrador');
  };
  
  const error = () => {
    message.error('Su contraseña no es valida. Intentelo nuevamente');
  };
  
  const warning = () => {
    message.warning('This is a warning message');
  };

  const PasswordZone = styled.div`
    width:100%;
    height:500px;
    margin-top: 10%;
  `

export default function PasswordArea(){

    const {register, handleSubmit, reset} = useForm()
    const decrypted = randomstring.generate();
    let [hex,sethex] = useState(false)
    let [Loading, setLoading] = useState(false)

    const onSubmitPassword = (values) => {

        setLoading(true)

        const encrypted ="c65798d1a891c3e0c6a37f8eaf0fe158b5546801fa99fc9b4c5f7f7e535ab602c36ba157ed1d169f128d8e4f2e303ea169798826d4c0d9939125873892faa961989b6458ae4b432573ca39bd59fc0344ab01a8797d958b55446f18e755e2d698d563aa359af28e1156be3889f1"
        const encryptedinput = cryptr.encrypt(values.password)
        const decryptedinput = cryptr.decrypt(encryptedinput)
        if(cryptr.decrypt(encrypted) === decryptedinput){
            success()
            sethex(true)
            setLoading(false)
        }
        else{
            error()
            setLoading(false)
        }
        reset()
    }
    return(
        <>
        {
            hex !== true ?
            <PasswordZone>
            <div>
            <LabelBiblioteca style={{marginBottom:"30px"}}>Bienvenido, por favor ingresa la contraseña</LabelBiblioteca>
            </div>
            <Space direction="vertical">
            <form onSubmit={handleSubmit(onSubmitPassword)}>
            <div style={{display:'inline-flex', width:"99%"}}>
                <InputBar/>
                <InputContent type="password" name="password"  placeholder="Contraseña" ref={register({ required: true })}/>
            </div> 
            <InputSubmit width="30%" height="30%" type="submit" value="Ingresar" />
            </form>
            {
                Loading !== true ?
                null
                :
                <div>
                    Pollo frito
                </div>

            }
            </Space>
            </PasswordZone>
            :
            <Redirect to={`/admin/${decrypted}/seeit`} />

        }
        </>
    )
}