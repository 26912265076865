import React, {useEffect, useState} from 'react'
import {Typography, Divider, Button, Row, Col, notification} from 'antd'
import Amplify, { API, graphqlOperation } from 'aws-amplify'
import { getNewNorma } from '../../../graphql/queries'
import {MainTitle, ErrorForm} from '../../Datadisplay'
import Paper  from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import styled from 'styled-components'
import {DoubleRightOutlined} from '@ant-design/icons'
import { updateNewNorma } from '../../../graphql/mutations'
import { useForm } from "react-hook-form";
import {InputBar, InputContent,Label,InputSubmit, SelectElement,TextAreaElement,SelectOptionElement} from '../../Input'

const TitleDetail = styled.h1`
 font-size:2rem;
 font-family: "Roboto";
 color: #6C0E60;
 text-align: left;
`
const SubtitleDetail = styled.p`
 font-size:1rem;
 font-family: "Roboto";
 text-align: left;
 font-weight: 200
 font-variant: italic
`
const InfoDetailedTitle = styled.p`
 font-size:1rem;
 font-family: "Roboto";
 color: #6C0E60;
 text-align: left;
 font-weight: 700;
`

const dataNorma = 
{
      id:"",
      TipoNorma:"Cargando...",
      Nombre:"",
      Descripcion:"",
      Fecha:"null",
      Entidad:"null",
      PalabrasClave:"null",
      URL:""
}


const NormaEditForm= (props)=>{
    const {register, handleSubmit, errors} = useForm()
    const [normaID, setnormaID] = useState(props.idNorma)
    let [info, setinfo] = useState(dataNorma)
    const [alert, setAlert] = useState()
    const [normaElement, setnormaElement] = useState({
      TipoNorma:"Cargando...",
      Nombre:"",
      Descripcion:"",
      Fecha:"null",
      Entidad:"null",
      PalabrasClave:"null",
      URL:""
    })
    const SuccessAlert = () => {notification["success"]({
        message: 'Enhorabuena!',
        description:
          'Se ha creado el paper con exito',
    });
    }

    useEffect( async ()=>{
        const DataNorma = await API.graphql(graphqlOperation(getNewNorma, {id:normaID}))
        setinfo(DataNorma.data.getNewNorma)
      },[])

    useEffect(()=>{
        setnormaElement({
            TipoNorma:info.TipoNorma_orig,
            Nombre:info.Nombre_orig,
            Descripcion:info.Descripcion_orig,
            Fecha:info.Fecha,
            Entidad:info.Entidad_orig,
            PalabrasClave:info.PalabrasClave_orig,
            URL:info.URL_orig
        })
    },[info])

    const removeAccents = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    } 
    const UpdateData = async (data,e) => {
    const normaUpdateID = normaID.toString()
      await API.graphql({query:updateNewNorma, variables: { input: { 
        id:normaUpdateID,
        TipoNorma:removeAccents(data.TipoNorma.toLowerCase()),
        TipoNorma_orig:data.TipoNorma,
        Nombre:removeAccents(data.Nombre.toLowerCase()),
        Nombre_orig:data.Nombre,
        Descripcion:removeAccents(data.Descripcion.toLowerCase()),
        Descripcion_orig:data.Descripcion,
        Fecha:data.Fecha,
        Entidad:removeAccents(data.Entidad.toLowerCase()),
        Entidad_orig:data.Entidad,
        PalabrasClave:removeAccents(data.PalabrasClave.toLowerCase()).split(";").map(x => x.trim()),
        PalabrasClave_orig:data.PalabrasClave,
        URL:removeAccents(data.URL.toLowerCase()),
        URL_orig:data.URL
      }}})
      setAlert(SuccessAlert)
      e.target.reset()
    }
    
    return(
        <div style={{padding: "3%"}}>
            <Paper style={{padding: "3%"}}  elevation={3}>
            <Row>
                <Col span={12} style={{padding:"0 4% 0 0"}}>
                <TitleDetail>{info.Nombre_orig}</TitleDetail>
                <SubtitleDetail><i>{info.TipoNorma_orig}</i></SubtitleDetail>
                <SubtitleDetail><i><strong style={{color:"#6C0E60"}}>Entidad </strong> {info.Entidad_orig}</i></SubtitleDetail>
                <SubtitleDetail><i><strong style={{color:"#6C0E60"}}>Palabras clave  </strong> {info.PalabrasClave_orig}</i></SubtitleDetail>
                <SubtitleDetail><strong style={{color:"#6C0E60"}}>Descripción</strong></SubtitleDetail>
                <Divider className="detailpaper-divider"/>
                <div>
                    <Row>
                        <Col span={24}><p style={{textAlign:"left"}}>{info.Descripcion_orig}</p></Col>
                    </Row> 
                </div>
                <div>
                    <Row>
                        <Col span={24}>
                            <List>
                                <ListItem>
                                    <Row className="detailpaper-row-list">
                                        <Col span={12}>
                                            <div style={{display: "inline-flex", alignContent:"center", alignItems:"baseline"}}>
                                                <DoubleRightOutlined style={{margin: "0px 10px 0px 0px"}} /><InfoDetailedTitle>Fecha</InfoDetailedTitle>
                                            </div>
                                            <p>{info.Fecha}</p>
                                        </Col>
                                    </Row>
                                </ListItem>

                                <Divider className="detailpaper-divider-list"/>

                                <ListItem>
                                    <Row className="detailpaper-row-list">
                                        <Col span={24}>
                                            <div style={{display: "inline-flex", alignContent:"center", alignItems:"baseline"}}>
                                                <DoubleRightOutlined style={{margin: "0px 10px 0px 0px"}} /><InfoDetailedTitle>URL</InfoDetailedTitle>
                                            </div>
                                            <p>{info.URL_orig}</p>
                                        </Col>
                                    </Row>
                                </ListItem>

                                <Divider className="detailpaper-divider-list"/>


                            </List>
                        </Col>
                        <Col span={6}><div></div></Col>
                    </Row> 
                </div>
                </Col>
                <Col span={12}>
                <div className="scale-in-hor-left">
                    <MainTitle size="4rem" aligntext="left">Formulario de Normas</MainTitle>
                    <form style={{paddingRight:"10%"}} onSubmit={handleSubmit(UpdateData)}>

                        <Label>Tipo de Norma</Label>

                        <div style={{display:'inline-flex', width:"99%"}}>
                        <InputBar/>
                            <SelectElement name="TipoNorma" onChange={(event)=>setnormaElement({...normaElement, TipoNorma:event.target.value})} value={normaElement.TipoNorma} ref={register}>
                                <SelectOptionElement value="Ley">Ley</SelectOptionElement>
                                <SelectOptionElement value="Decreto">Decreto</SelectOptionElement>
                                <SelectOptionElement value="Resolucion">Resolución</SelectOptionElement>
                                <SelectOptionElement value="Circular">Circular</SelectOptionElement>
                                <SelectOptionElement value="Sentencia">Sentencia</SelectOptionElement>
                                <SelectOptionElement value="Acuerdo">Acuerdo</SelectOptionElement>
                                <SelectOptionElement value="Constitucion">Constitución</SelectOptionElement>
                                <SelectOptionElement value="Ordenanza">Ordenanza</SelectOptionElement>
                                <SelectOptionElement value="Otro">Otro</SelectOptionElement>
                            </SelectElement>
                        </div>

                        <Label>Nombre</Label>

                        <div style={{display:'inline-flex', width:"99%"}}>
                            <InputBar/>
                            <InputContent name="Nombre"  onChange={(event)=>setnormaElement({...normaElement, Nombre:event.target.value})} value={normaElement.Nombre} ref={register({ required: true })}/>
                        </div>
                        <div>{errors.Nombre && <ErrorForm>Este campo es requerido</ErrorForm>}</div>

                        <Label>Descripción</Label>

                        <div style={{display:'inline-flex', width:"99%"}}>
                            <InputBar/>
                            <TextAreaElement rows="10" cols="30" name="Descripcion" onChange={(event)=>setnormaElement({...normaElement, Descripcion:event.target.value})} value={normaElement.Descripcion} ref={register} />
                        </div>
                        <div>{errors.Descripcion && <ErrorForm>Este campo es requerido</ErrorForm>}</div>

                        <Label>Fecha (Año)</Label>

                        <div style={{display:'inline-flex', width:"99%"}}>
                            <InputBar/>
                            <InputContent name="Fecha" onChange={(event)=>setnormaElement({...normaElement, Fecha:event.target.value})} value={normaElement.Fecha} min="1900" max="2099" step="1" ref={register}/>
                        </div>
                        
                        <Label>Entidad</Label>

                        <div style={{display:'inline-flex', width:"99%"}}>
                            <InputBar/>
                            <SelectElement name="Entidad" onChange={(event)=>setnormaElement({...normaElement, Entidad:event.target.value})} value={normaElement.Entidad} ref={register}>
                                <SelectOptionElement value="Superintendencia de Salud">Superintendencia de Salud</SelectOptionElement>
                                <SelectOptionElement value="Ministerio de Salud">Ministerio de Salud</SelectOptionElement>
                                <SelectOptionElement value="Corte Constitucional">Corte Constitucional</SelectOptionElement>
                                <SelectOptionElement value="Presidencia de la Republica">Presidencia de la Republica</SelectOptionElement>
                                <SelectOptionElement value="Alcaldia">Alcaldia</SelectOptionElement>
                                <SelectOptionElement value="Gobernacion">Gobernación</SelectOptionElement>
                                <SelectOptionElement value="Contraloria General de la Republica">Contraloria General de la Republica</SelectOptionElement>
                                <SelectOptionElement value="Procuraduria General de la Nacion">Procuraduria General de la Nacion</SelectOptionElement>
                                <SelectOptionElement value="Congreso de la Republica">Congreso de la Republica</SelectOptionElement>
                                <SelectOptionElement value="Departamento Nacional de Planeacion">Departamento Nacional de Planeación</SelectOptionElement>
                                <SelectOptionElement value="Ministerio TICs">Ministerio TICs</SelectOptionElement>
                                <SelectOptionElement value="Ministerio de Trabajo">Ministerio de Trabajo</SelectOptionElement>
                                <SelectOptionElement value="Ministerio de Vivienda">Ministerio de Vivienda</SelectOptionElement>
                                <SelectOptionElement value="Ministerio de Educación">Ministerio de Educación</SelectOptionElement>
                                <SelectOptionElement value="SuperIntendencia de Industria y Comercio">SuperIntendencia de Industria y Comercio</SelectOptionElement>
                                <SelectOptionElement value="Invima">Invima</SelectOptionElement>
                                <SelectOptionElement value="IETS">IETS</SelectOptionElement>
                                <SelectOptionElement value="ADRES">ADRES</SelectOptionElement>
                                <SelectOptionElement value="INS">INS</SelectOptionElement>
                                <SelectOptionElement value="Minciencias">Minciencias</SelectOptionElement>
                                <SelectOptionElement value="Ministerio de Ciencia Tecnología e Innovación">Ministerio de Ciencia Tecnología e Innovación</SelectOptionElement>
                                <SelectOptionElement value="Consejo de Estado">Consejo de Estado</SelectOptionElement>
                                <SelectOptionElement value="Corte Suprema de Justicia">Corte Suprema de Justicia</SelectOptionElement>
                                <SelectOptionElement value="Otros">Otros</SelectOptionElement>
                            </SelectElement>
                        </div>

                        <Label>Temas (Separados por ;) </Label>

                        <div style={{display:'inline-flex', width:"99%"}}>
                            <InputBar/>
                            <InputContent name="PalabrasClave" onChange={(event)=>setnormaElement({...normaElement, PalabrasClave:event.target.value})} value={normaElement.PalabrasClave} ref={register}/>
                        </div>

                        <Label>Url</Label>

                        <div style={{display:'inline-flex', width:"99%"}}>
                            <InputBar/>
                            <InputContent name="URL" onChange={(event)=>setnormaElement({...normaElement, URL:event.target.value})} value={normaElement.URL} ref={register}/>
                        </div>

                        <InputSubmit width="30%" height="30%" type="submit" value="ACTUALIZAR NORMA" />

                    </form>
                </div>
                </Col>
            </Row>
            </Paper>
        </div>
    )
}

export default NormaEditForm