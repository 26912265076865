/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPapers = /* GraphQL */ `
  mutation CreatePapers(
    $input: CreatePapersInput!
    $condition: ModelPapersConditionInput
  ) {
    createPapers(input: $input, condition: $condition) {
      id
      titulo
      titulo_secundario
      Journal
      Paginas
      Volumen
      Numero
      PalabrasClave
      Fecha
      Url
      Abstract
      Autores
      Licencia
      Idioma
      TipoDocumental
      createdAt
      updatedAt
    }
  }
`;
export const updatePapers = /* GraphQL */ `
  mutation UpdatePapers(
    $input: UpdatePapersInput!
    $condition: ModelPapersConditionInput
  ) {
    updatePapers(input: $input, condition: $condition) {
      id
      titulo
      titulo_secundario
      Journal
      Paginas
      Volumen
      Numero
      PalabrasClave
      Fecha
      Url
      Abstract
      Autores
      Licencia
      Idioma
      TipoDocumental
      createdAt
      updatedAt
    }
  }
`;
export const deletePapers = /* GraphQL */ `
  mutation DeletePapers(
    $input: DeletePapersInput!
    $condition: ModelPapersConditionInput
  ) {
    deletePapers(input: $input, condition: $condition) {
      id
      titulo
      titulo_secundario
      Journal
      Paginas
      Volumen
      Numero
      PalabrasClave
      Fecha
      Url
      Abstract
      Autores
      Licencia
      Idioma
      TipoDocumental
      createdAt
      updatedAt
    }
  }
`;
export const createNewPapers = /* GraphQL */ `
  mutation CreateNewPapers(
    $input: CreateNewPapersInput!
    $condition: ModelNewPapersConditionInput
  ) {
    createNewPapers(input: $input, condition: $condition) {
      id
      titulo
      titulo_orig
      titulo_secundario
      titulo_secundario_orig
      Journal
      Journal_orig
      Paginas
      Paginas_orig
      Volumen
      Volumen_orig
      Numero
      Numero_orig
      PalabrasClave
      PalabrasClave_orig
      Fecha
      Fecha_orig
      Url
      Url_orig
      Abstract
      Abstract_orig
      Autores
      Autores_orig
      Licencia
      Licencia_orig
      Idioma
      Idioma_orig
      TipoDocumental
      TipoDocumental_orig
      createdAt
      updatedAt
    }
  }
`;
export const updateNewPapers = /* GraphQL */ `
  mutation UpdateNewPapers(
    $input: UpdateNewPapersInput!
    $condition: ModelNewPapersConditionInput
  ) {
    updateNewPapers(input: $input, condition: $condition) {
      id
      titulo
      titulo_orig
      titulo_secundario
      titulo_secundario_orig
      Journal
      Journal_orig
      Paginas
      Paginas_orig
      Volumen
      Volumen_orig
      Numero
      Numero_orig
      PalabrasClave
      PalabrasClave_orig
      Fecha
      Fecha_orig
      Url
      Url_orig
      Abstract
      Abstract_orig
      Autores
      Autores_orig
      Licencia
      Licencia_orig
      Idioma
      Idioma_orig
      TipoDocumental
      TipoDocumental_orig
      createdAt
      updatedAt
    }
  }
`;
export const deleteNewPapers = /* GraphQL */ `
  mutation DeleteNewPapers(
    $input: DeleteNewPapersInput!
    $condition: ModelNewPapersConditionInput
  ) {
    deleteNewPapers(input: $input, condition: $condition) {
      id
      titulo
      titulo_orig
      titulo_secundario
      titulo_secundario_orig
      Journal
      Journal_orig
      Paginas
      Paginas_orig
      Volumen
      Volumen_orig
      Numero
      Numero_orig
      PalabrasClave
      PalabrasClave_orig
      Fecha
      Fecha_orig
      Url
      Url_orig
      Abstract
      Abstract_orig
      Autores
      Autores_orig
      Licencia
      Licencia_orig
      Idioma
      Idioma_orig
      TipoDocumental
      TipoDocumental_orig
      createdAt
      updatedAt
    }
  }
`;
export const createNorma = /* GraphQL */ `
  mutation CreateNorma(
    $input: CreateNormaInput!
    $condition: ModelNormaConditionInput
  ) {
    createNorma(input: $input, condition: $condition) {
      id
      TipoNorma
      Nombre
      Descripcion
      Entidad
      PalabrasClave
      Fecha
      URL
      Numero
      createdAt
      updatedAt
    }
  }
`;
export const updateNorma = /* GraphQL */ `
  mutation UpdateNorma(
    $input: UpdateNormaInput!
    $condition: ModelNormaConditionInput
  ) {
    updateNorma(input: $input, condition: $condition) {
      id
      TipoNorma
      Nombre
      Descripcion
      Entidad
      PalabrasClave
      Fecha
      URL
      Numero
      createdAt
      updatedAt
    }
  }
`;
export const deleteNorma = /* GraphQL */ `
  mutation DeleteNorma(
    $input: DeleteNormaInput!
    $condition: ModelNormaConditionInput
  ) {
    deleteNorma(input: $input, condition: $condition) {
      id
      TipoNorma
      Nombre
      Descripcion
      Entidad
      PalabrasClave
      Fecha
      URL
      Numero
      createdAt
      updatedAt
    }
  }
`;
export const createNewNorma = /* GraphQL */ `
  mutation CreateNewNorma(
    $input: CreateNewNormaInput!
    $condition: ModelNewNormaConditionInput
  ) {
    createNewNorma(input: $input, condition: $condition) {
      id
      TipoNorma
      TipoNorma_orig
      Nombre
      Nombre_orig
      Descripcion
      Descripcion_orig
      Entidad
      Entidad_orig
      PalabrasClave
      PalabrasClave_orig
      Fecha
      Fecha_orig
      URL
      URL_orig
      Numero
      Numero_orig
      createdAt
      updatedAt
    }
  }
`;
export const updateNewNorma = /* GraphQL */ `
  mutation UpdateNewNorma(
    $input: UpdateNewNormaInput!
    $condition: ModelNewNormaConditionInput
  ) {
    updateNewNorma(input: $input, condition: $condition) {
      id
      TipoNorma
      TipoNorma_orig
      Nombre
      Nombre_orig
      Descripcion
      Descripcion_orig
      Entidad
      Entidad_orig
      PalabrasClave
      PalabrasClave_orig
      Fecha
      Fecha_orig
      URL
      URL_orig
      Numero
      Numero_orig
      createdAt
      updatedAt
    }
  }
`;
export const deleteNewNorma = /* GraphQL */ `
  mutation DeleteNewNorma(
    $input: DeleteNewNormaInput!
    $condition: ModelNewNormaConditionInput
  ) {
    deleteNewNorma(input: $input, condition: $condition) {
      id
      TipoNorma
      TipoNorma_orig
      Nombre
      Nombre_orig
      Descripcion
      Descripcion_orig
      Entidad
      Entidad_orig
      PalabrasClave
      PalabrasClave_orig
      Fecha
      Fecha_orig
      URL
      URL_orig
      Numero
      Numero_orig
      createdAt
      updatedAt
    }
  }
`;
export const createPlataforma = /* GraphQL */ `
  mutation CreatePlataforma(
    $input: CreatePlataformaInput!
    $condition: ModelPlataformaConditionInput
  ) {
    createPlataforma(input: $input, condition: $condition) {
      id
      Nombre
      LogoURL
      LogoS3
      Descripcion
      URL
      Tipo
      createdAt
      updatedAt
    }
  }
`;
export const updatePlataforma = /* GraphQL */ `
  mutation UpdatePlataforma(
    $input: UpdatePlataformaInput!
    $condition: ModelPlataformaConditionInput
  ) {
    updatePlataforma(input: $input, condition: $condition) {
      id
      Nombre
      LogoURL
      LogoS3
      Descripcion
      URL
      Tipo
      createdAt
      updatedAt
    }
  }
`;
export const deletePlataforma = /* GraphQL */ `
  mutation DeletePlataforma(
    $input: DeletePlataformaInput!
    $condition: ModelPlataformaConditionInput
  ) {
    deletePlataforma(input: $input, condition: $condition) {
      id
      Nombre
      LogoURL
      LogoS3
      Descripcion
      URL
      Tipo
      createdAt
      updatedAt
    }
  }
`;
export const createNewPlataforma = /* GraphQL */ `
  mutation CreateNewPlataforma(
    $input: CreateNewPlataformaInput!
    $condition: ModelNewPlataformaConditionInput
  ) {
    createNewPlataforma(input: $input, condition: $condition) {
      id
      Nombre
      Nombre_orig
      LogoURL
      LogoURL_orig
      LogoS3
      LogoS3_orig
      Descripcion
      Descripcion_orig
      URL
      URL_orig
      Tipo
      Tipo_orig
      createdAt
      updatedAt
    }
  }
`;
export const updateNewPlataforma = /* GraphQL */ `
  mutation UpdateNewPlataforma(
    $input: UpdateNewPlataformaInput!
    $condition: ModelNewPlataformaConditionInput
  ) {
    updateNewPlataforma(input: $input, condition: $condition) {
      id
      Nombre
      Nombre_orig
      LogoURL
      LogoURL_orig
      LogoS3
      LogoS3_orig
      Descripcion
      Descripcion_orig
      URL
      URL_orig
      Tipo
      Tipo_orig
      createdAt
      updatedAt
    }
  }
`;
export const deleteNewPlataforma = /* GraphQL */ `
  mutation DeleteNewPlataforma(
    $input: DeleteNewPlataformaInput!
    $condition: ModelNewPlataformaConditionInput
  ) {
    deleteNewPlataforma(input: $input, condition: $condition) {
      id
      Nombre
      Nombre_orig
      LogoURL
      LogoURL_orig
      LogoS3
      LogoS3_orig
      Descripcion
      Descripcion_orig
      URL
      URL_orig
      Tipo
      Tipo_orig
      createdAt
      updatedAt
    }
  }
`;
export const createAudiovisual = /* GraphQL */ `
  mutation CreateAudiovisual(
    $input: CreateAudiovisualInput!
    $condition: ModelAudiovisualConditionInput
  ) {
    createAudiovisual(input: $input, condition: $condition) {
      id
      TipoAudiovisual
      Nombre
      Fecha
      URL
      URLS3
      createdAt
      updatedAt
    }
  }
`;
export const updateAudiovisual = /* GraphQL */ `
  mutation UpdateAudiovisual(
    $input: UpdateAudiovisualInput!
    $condition: ModelAudiovisualConditionInput
  ) {
    updateAudiovisual(input: $input, condition: $condition) {
      id
      TipoAudiovisual
      Nombre
      Fecha
      URL
      URLS3
      createdAt
      updatedAt
    }
  }
`;
export const deleteAudiovisual = /* GraphQL */ `
  mutation DeleteAudiovisual(
    $input: DeleteAudiovisualInput!
    $condition: ModelAudiovisualConditionInput
  ) {
    deleteAudiovisual(input: $input, condition: $condition) {
      id
      TipoAudiovisual
      Nombre
      Fecha
      URL
      URLS3
      createdAt
      updatedAt
    }
  }
`;
export const createNewAudiovisual = /* GraphQL */ `
  mutation CreateNewAudiovisual(
    $input: CreateNewAudiovisualInput!
    $condition: ModelNewAudiovisualConditionInput
  ) {
    createNewAudiovisual(input: $input, condition: $condition) {
      id
      TipoAudiovisual
      TipoAudiovisual_orig
      Nombre
      Nombre_orig
      Fecha
      Fecha_orig
      URL
      URL_orig
      URLS3
      URLS3_orig
      createdAt
      updatedAt
    }
  }
`;
export const updateNewAudiovisual = /* GraphQL */ `
  mutation UpdateNewAudiovisual(
    $input: UpdateNewAudiovisualInput!
    $condition: ModelNewAudiovisualConditionInput
  ) {
    updateNewAudiovisual(input: $input, condition: $condition) {
      id
      TipoAudiovisual
      TipoAudiovisual_orig
      Nombre
      Nombre_orig
      Fecha
      Fecha_orig
      URL
      URL_orig
      URLS3
      URLS3_orig
      createdAt
      updatedAt
    }
  }
`;
export const deleteNewAudiovisual = /* GraphQL */ `
  mutation DeleteNewAudiovisual(
    $input: DeleteNewAudiovisualInput!
    $condition: ModelNewAudiovisualConditionInput
  ) {
    deleteNewAudiovisual(input: $input, condition: $condition) {
      id
      TipoAudiovisual
      TipoAudiovisual_orig
      Nombre
      Nombre_orig
      Fecha
      Fecha_orig
      URL
      URL_orig
      URLS3
      URLS3_orig
      createdAt
      updatedAt
    }
  }
`;
export const createOrganizacionPaciente = /* GraphQL */ `
  mutation CreateOrganizacionPaciente(
    $input: CreateOrganizacionPacienteInput!
    $condition: ModelOrganizacionPacienteConditionInput
  ) {
    createOrganizacionPaciente(input: $input, condition: $condition) {
      id
      Nombre
      Logo
      LogoS3
      Ciudad
      URL
      Tipo
      createdAt
      updatedAt
    }
  }
`;
export const updateOrganizacionPaciente = /* GraphQL */ `
  mutation UpdateOrganizacionPaciente(
    $input: UpdateOrganizacionPacienteInput!
    $condition: ModelOrganizacionPacienteConditionInput
  ) {
    updateOrganizacionPaciente(input: $input, condition: $condition) {
      id
      Nombre
      Logo
      LogoS3
      Ciudad
      URL
      Tipo
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrganizacionPaciente = /* GraphQL */ `
  mutation DeleteOrganizacionPaciente(
    $input: DeleteOrganizacionPacienteInput!
    $condition: ModelOrganizacionPacienteConditionInput
  ) {
    deleteOrganizacionPaciente(input: $input, condition: $condition) {
      id
      Nombre
      Logo
      LogoS3
      Ciudad
      URL
      Tipo
      createdAt
      updatedAt
    }
  }
`;
export const createNewOrganizacionPaciente = /* GraphQL */ `
  mutation CreateNewOrganizacionPaciente(
    $input: CreateNewOrganizacionPacienteInput!
    $condition: ModelNewOrganizacionPacienteConditionInput
  ) {
    createNewOrganizacionPaciente(input: $input, condition: $condition) {
      id
      Nombre
      Nombre_orig
      Logo
      Logo_orig
      LogoS3
      LogoS3_orig
      Ciudad
      Ciudad_orig
      URL
      URL_orig
      Tipo
      Tipo_orig
      createdAt
      updatedAt
    }
  }
`;
export const updateNewOrganizacionPaciente = /* GraphQL */ `
  mutation UpdateNewOrganizacionPaciente(
    $input: UpdateNewOrganizacionPacienteInput!
    $condition: ModelNewOrganizacionPacienteConditionInput
  ) {
    updateNewOrganizacionPaciente(input: $input, condition: $condition) {
      id
      Nombre
      Nombre_orig
      Logo
      Logo_orig
      LogoS3
      LogoS3_orig
      Ciudad
      Ciudad_orig
      URL
      URL_orig
      Tipo
      Tipo_orig
      createdAt
      updatedAt
    }
  }
`;
export const deleteNewOrganizacionPaciente = /* GraphQL */ `
  mutation DeleteNewOrganizacionPaciente(
    $input: DeleteNewOrganizacionPacienteInput!
    $condition: ModelNewOrganizacionPacienteConditionInput
  ) {
    deleteNewOrganizacionPaciente(input: $input, condition: $condition) {
      id
      Nombre
      Nombre_orig
      Logo
      Logo_orig
      LogoS3
      LogoS3_orig
      Ciudad
      Ciudad_orig
      URL
      URL_orig
      Tipo
      Tipo_orig
      createdAt
      updatedAt
    }
  }
`;
export const createProfesional = /* GraphQL */ `
  mutation CreateProfesional(
    $input: CreateProfesionalInput!
    $condition: ModelProfesionalConditionInput
  ) {
    createProfesional(input: $input, condition: $condition) {
      id
      Nombre
      Descripcion
      URL
      URLS3
      createdAt
      updatedAt
    }
  }
`;
export const updateProfesional = /* GraphQL */ `
  mutation UpdateProfesional(
    $input: UpdateProfesionalInput!
    $condition: ModelProfesionalConditionInput
  ) {
    updateProfesional(input: $input, condition: $condition) {
      id
      Nombre
      Descripcion
      URL
      URLS3
      createdAt
      updatedAt
    }
  }
`;
export const deleteProfesional = /* GraphQL */ `
  mutation DeleteProfesional(
    $input: DeleteProfesionalInput!
    $condition: ModelProfesionalConditionInput
  ) {
    deleteProfesional(input: $input, condition: $condition) {
      id
      Nombre
      Descripcion
      URL
      URLS3
      createdAt
      updatedAt
    }
  }
`;
export const createNewProfesional = /* GraphQL */ `
  mutation CreateNewProfesional(
    $input: CreateNewProfesionalInput!
    $condition: ModelNewProfesionalConditionInput
  ) {
    createNewProfesional(input: $input, condition: $condition) {
      id
      Nombre
      Nombre_orig
      Descripcion
      Descripcion_orig
      URL
      URL_orig
      URLS3
      URLS3_orig
      createdAt
      updatedAt
    }
  }
`;
export const updateNewProfesional = /* GraphQL */ `
  mutation UpdateNewProfesional(
    $input: UpdateNewProfesionalInput!
    $condition: ModelNewProfesionalConditionInput
  ) {
    updateNewProfesional(input: $input, condition: $condition) {
      id
      Nombre
      Nombre_orig
      Descripcion
      Descripcion_orig
      URL
      URL_orig
      URLS3
      URLS3_orig
      createdAt
      updatedAt
    }
  }
`;
export const deleteNewProfesional = /* GraphQL */ `
  mutation DeleteNewProfesional(
    $input: DeleteNewProfesionalInput!
    $condition: ModelNewProfesionalConditionInput
  ) {
    deleteNewProfesional(input: $input, condition: $condition) {
      id
      Nombre
      Nombre_orig
      Descripcion
      Descripcion_orig
      URL
      URL_orig
      URLS3
      URLS3_orig
      createdAt
      updatedAt
    }
  }
`;
export const createInvestigacion = /* GraphQL */ `
  mutation CreateInvestigacion(
    $input: CreateInvestigacionInput!
    $condition: ModelInvestigacionConditionInput
  ) {
    createInvestigacion(input: $input, condition: $condition) {
      id
      Nombre
      Entidad
      Ciudad
      URL
      URLS3
      createdAt
      updatedAt
    }
  }
`;
export const updateInvestigacion = /* GraphQL */ `
  mutation UpdateInvestigacion(
    $input: UpdateInvestigacionInput!
    $condition: ModelInvestigacionConditionInput
  ) {
    updateInvestigacion(input: $input, condition: $condition) {
      id
      Nombre
      Entidad
      Ciudad
      URL
      URLS3
      createdAt
      updatedAt
    }
  }
`;
export const deleteInvestigacion = /* GraphQL */ `
  mutation DeleteInvestigacion(
    $input: DeleteInvestigacionInput!
    $condition: ModelInvestigacionConditionInput
  ) {
    deleteInvestigacion(input: $input, condition: $condition) {
      id
      Nombre
      Entidad
      Ciudad
      URL
      URLS3
      createdAt
      updatedAt
    }
  }
`;
export const createNewInvestigacion = /* GraphQL */ `
  mutation CreateNewInvestigacion(
    $input: CreateNewInvestigacionInput!
    $condition: ModelNewInvestigacionConditionInput
  ) {
    createNewInvestigacion(input: $input, condition: $condition) {
      id
      Nombre
      Nombre_orig
      Entidad
      Entidad_orig
      Ciudad
      Ciudad_orig
      URL
      URL_orig
      URLS3
      URLS3_orig
      createdAt
      updatedAt
    }
  }
`;
export const updateNewInvestigacion = /* GraphQL */ `
  mutation UpdateNewInvestigacion(
    $input: UpdateNewInvestigacionInput!
    $condition: ModelNewInvestigacionConditionInput
  ) {
    updateNewInvestigacion(input: $input, condition: $condition) {
      id
      Nombre
      Nombre_orig
      Entidad
      Entidad_orig
      Ciudad
      Ciudad_orig
      URL
      URL_orig
      URLS3
      URLS3_orig
      createdAt
      updatedAt
    }
  }
`;
export const deleteNewInvestigacion = /* GraphQL */ `
  mutation DeleteNewInvestigacion(
    $input: DeleteNewInvestigacionInput!
    $condition: ModelNewInvestigacionConditionInput
  ) {
    deleteNewInvestigacion(input: $input, condition: $condition) {
      id
      Nombre
      Nombre_orig
      Entidad
      Entidad_orig
      Ciudad
      Ciudad_orig
      URL
      URL_orig
      URLS3
      URLS3_orig
      createdAt
      updatedAt
    }
  }
`;
