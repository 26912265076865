import React,{useState, useContext, useEffect} from 'react'
import { Layout, Menu, Breadcrumb } from 'antd';
import {AdminContext} from '../../components/store/admin'
import { UserOutlined, LaptopOutlined, NotificationOutlined } from '@ant-design/icons';

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

export default function LayoutAdmin(props){

    const {results, resultsDispatch}  =  useContext(AdminContext)
    let [datatype, setdatatype] = useState("Home")
    let [datamethod, setdatamethod] = useState("None")

    const topFunction = () => {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    useEffect(()=>{
      resultsDispatch({ type: 'Home' });
    },[])

    useEffect(() => {
      
      if( datatype == "Papers"){
        resultsDispatch({ type: 'Papers', payload:{editID:datamethod}});
      }
      if( datatype == "Normas"){
        resultsDispatch({ type: 'Normas', payload:{editID:datamethod}});
      }
      if( datatype == "Plataformas"){
        resultsDispatch({ type: 'Plataformas', payload:{editID:datamethod}});
      }
      if( datatype == "AudioVisuales"){
        resultsDispatch({ type: 'AudioVisual', payload:{editID:datamethod}});
      }
      if( datatype == "OrganizacionPacientes"){
        resultsDispatch({ type: 'OrganizacionPacientes', payload:{editID:datamethod}});
      }
      if( datatype == "Profesionales"){
        resultsDispatch({ type: 'Profesionales', payload:{editID:datamethod}});
      }
      if( datatype == "Investigacion"){
        resultsDispatch({ type: 'Investigacion', payload:{editID:datamethod}});
      }

    }, [datatype, datamethod])
/*defaultSelectedKeys={['1']} */
    return(
        <Layout>
        <Sider style={{height:"100vh", position:"fixed", display:"block", width:"100%", paddingTop:"5%"}} collapsible>
          <div className="logo" />
          <Menu theme="dark"  mode="inline">
          <SubMenu key="sub1" icon={<UserOutlined />} title="Añadir">
                    <Menu.Item key="1" onClick={()=>{setdatatype("Papers")}}>Papers</Menu.Item>
                    <Menu.Item key="2" onClick={()=>{setdatatype("Normas")}}>Normas</Menu.Item>
                    <Menu.Item key="3" onClick={()=>{setdatatype("Plataformas")}}>Plataformas (Medicas y EnfermedadesRaras)</Menu.Item>
                    <Menu.Item key="4" onClick={()=>{setdatatype("AudioVisuales")}}>RecursoAudiovisual</Menu.Item>
                    <Menu.Item key="5" onClick={()=>{setdatatype("OrganizacionPacientes")}}>Organización de Pacientes</Menu.Item>
                    <Menu.Item key="6" onClick={()=>{setdatatype("Profesionales")}}>Profesionales de la Salud</Menu.Item>
                    <Menu.Item key="7" onClick={()=>{setdatatype("Investigacion")}}>Grupos de Investigación</Menu.Item>
                </SubMenu>
                <SubMenu key="sub2" icon={<LaptopOutlined />} title="Editar" >
                    <Menu.Item key="8" onClick={()=>{
                        setdatamethod("Papers") 
                        setdatatype("Papers")
                    }}>Papers</Menu.Item>
                    <Menu.Item key="9" onClick={()=>{
                      setdatamethod("Normas") 
                      setdatatype("Normas")
                    }}>Normas</Menu.Item>
                    <Menu.Item key="10" onClick={()=>{
                        setdatamethod("Plataformas") 
                        setdatatype("Plataformas")
                    }}>Plataformas (Medicas y EnfermedadesRaras)</Menu.Item>
                    <Menu.Item key="11" onClick={()=>{
                        setdatamethod("AudioVisuales") 
                        setdatatype("AudioVisuales")
                    }}>Recurso Audiovisual</Menu.Item>
                    <Menu.Item key="12" onClick={()=>{
                        setdatamethod("OrganizacionPacientes") 
                        setdatatype("OrganizacionPacientes")
                    }}>Organización de Pacientes</Menu.Item>
                    <Menu.Item key="13" onClick={()=>{
                        setdatamethod("Profesionales") 
                        setdatatype("Profesionales")
                    }}>Profesionales de la Salud</Menu.Item>
                    <Menu.Item key="14" onClick={()=>{
                        setdatamethod("Investigacion") 
                        setdatatype("Investigacion")
                    }}>Grupos de Investigación</Menu.Item>
                </SubMenu>
          </Menu>
        </Sider>
        <Layout style={{backgroundColor:"#EAEAEA"}} className="site-layout">
          <Content>
            <div className="site-layout-background" style={{ paddingLeft: "20%",paddingTop: "7%", minHeight: 1080 }}>
              {results}
            </div>
          </Content>
        </Layout>
      </Layout>
    )
}