import React, {useEffect, useState} from 'react'
import {Typography, Divider, Button, Row, Col, notification} from 'antd'
import Amplify, { API, graphqlOperation, Storage } from 'aws-amplify'
import { getNewPlataforma } from '../../../graphql/queries'
import {MainTitle, ErrorForm} from '../../Datadisplay'
import Paper  from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import styled from 'styled-components'
import {DoubleRightOutlined} from '@ant-design/icons'
import { updateNewPlataforma } from '../../../graphql/mutations'
import { useForm } from "react-hook-form";
import {InputBar, InputContent,Label,InputSubmit, SelectElement,TextAreaElement,SelectOptionElement} from '../../Input'
import { v4 as uuid } from 'uuid'
import config from '../../../aws-exports'
import '../../../styles/styles.css'

const {
    aws_user_files_s3_bucket_region: region,
    aws_user_files_s3_bucket: bucket
} = config

const TitleDetail = styled.h1`
 font-size:2rem;
 font-family: "Roboto";
 color: #6C0E60;
 text-align: left;
`
const SubtitleDetail = styled.p`
 font-size:1rem;
 font-family: "Roboto";
 text-align: left;
 font-weight: 200
 font-variant: italic
`
const InfoDetailedTitle = styled.p`
 font-size:1rem;
 font-family: "Roboto";
 color: #6C0E60;
 text-align: left;
 font-weight: 700;
`

const dataPlataforma = 
{
    id:"",
    Nombre: "",
    Nombre_orig: "",
    LogoURL: "",
    LogoURL_orig: "",
    LogoS3: "",
    LogoS3_orig: "",
    Descripcion: "",
    Descripcion_orig: "",
    URL: "",
    URL_orig: "",
    Tipo: "",
    Tipo_orig: ""
}


const PlatformEditForm= (props)=>{
    const {register, handleSubmit, errors} = useForm()
    const [plataformaID, setplataformaID] = useState(props.idPlataforma)
    let [info, setinfo] = useState(dataPlataforma)
    const [alert, setAlert] = useState()
    let [plataformaElement,setplataformaElement] = useState({
        Nombre: "",
        Nombre_orig: "",
        LogoURL: "",
        LogoURL_orig: "",
        Descripcion: "",
        Descripcion_orig: "",
        URL: "",
        URL_orig: "",
        Tipo: "",
        Tipo_orig: ""
    })
    const [file, updateFile] = useState(null)
    const [PlataformaName, updatePlataformaName] = useState('')
    const [Plataformas, updatePlataformas] = useState([])
    let [FilePreviewImg, setFilePreviewImg] = useState()
    let [extensionName, setextensionName] = useState('')

    const SuccessAlert = () => {notification["success"]({
        message: 'Enhorabuena!',
        description:
          'Se ha creado el paper con exito',
    });
    }

    useEffect( async ()=>{
        const DataPlataforma = await API.graphql(graphqlOperation(getNewPlataforma, {id:plataformaID}))
        setinfo(DataPlataforma.data.getNewPlataforma)
      },[]) 
    
    useEffect(()=>{
        setplataformaElement({
            Nombre: info.Nombre_orig,
            LogoURL: info.LogoURL_orig,
            Descripcion: info.Descripcion_orig,
            URL: info.URL_orig,
            Tipo: info.Tipo_orig,
        })
    },[info])

    function handleChange(event) {
        const filePreview = event.target.files[0]
        const filePreviewCreate = window.webkitURL.createObjectURL(filePreview)
        setFilePreviewImg(filePreviewCreate)
        const { target: { value, files } } = event
        const fileForUpload = files[0]
        updatePlataformaName(fileForUpload.name.split(".")[0])
        setextensionName(fileForUpload.name)
        updateFile(fileForUpload || value)
      }

    const removeAccents = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    } 

    const UpdateData = async (data,e) => {
        let inputData = null
        if (file) {
            const extension = file.name.split(".")[1]
            const { type: mimeType } = file
            const key = `images/plataformas/${uuid()}${PlataformaName}.${extension}`      
            const url = `https://${bucket}.s3.${region}.amazonaws.com/public/${key}`
            inputData = { name: PlataformaName , image: url }
    
            try {
                await Storage.put(key, file, {
                contentType: mimeType
                })
            } catch (err) {
                console.log('error: ', err)
            }
        }
    const plataformaUpdateID = plataformaID.toString()
      await API.graphql({query:updateNewPlataforma, variables: { input: { 
        id:plataformaUpdateID,
        Nombre: removeAccents(data.Nombre).toLowerCase(),
        Nombre_orig: data.Nombre,
        LogoURL: removeAccents(inputData.image).toLowerCase(),
        LogoURL_orig: inputData.image,
        Descripcion: removeAccents(data.Descripcion).toLowerCase(),
        Descripcion_orig: data.Descripcion,
        URL: removeAccents(data.URL).toLowerCase(),
        URL_orig: data.URL,
        Tipo: removeAccents(data.TipoPlataforma).toLowerCase(),
        Tipo_orig: data.TipoPlataforma
      }}})
      setAlert(SuccessAlert)
      e.target.reset()
    }
    
    return(
        <div style={{padding: "3%"}}>
            <Paper style={{padding: "3%"}}  elevation={3}>
            <Row>
                <Col span={12} style={{padding:"0 4% 0 0"}}>
                <img src={info.LogoURL_orig} style={{width:"50%"}}/>
                <TitleDetail>{info.Nombre_orig}</TitleDetail>
                <SubtitleDetail><i>{info.Tipo_orig}</i></SubtitleDetail>
                <SubtitleDetail><strong style={{color:"#6C0E60"}}>Descripción</strong></SubtitleDetail>
                <Divider className="detailpaper-divider"/>
                <div>
                    <Row>
                        <Col span={24}><p style={{textAlign:"left"}}>{info.Descripcion_orig}</p></Col>
                    </Row> 
                </div>
                <div>
                    
                    <Row>
                        <Col span={24}>
                            <List>
                                <ListItem>
                                    <Row className="detailpaper-row-list">
                                        <Col span={24}>
                                            <div style={{display: "inline-flex", alignContent:"center", alignItems:"baseline"}}>
                                                <DoubleRightOutlined style={{margin: "0px 10px 0px 0px"}} /><InfoDetailedTitle>URL</InfoDetailedTitle>
                                            </div>
                                            <p>{info.URL_orig}</p>
                                        </Col>
                                    </Row>
                                </ListItem>
                                <Divider className="detailpaper-divider-list"/>
                            </List>
                        </Col>
                        <Col span={6}><div></div></Col>
                    </Row> 
                </div>
                </Col>
                <Col span={12}>
                <div className="scale-in-hor-left">
                    <MainTitle size="4rem" aligntext="left">Formulario de Plataforma</MainTitle>
                    <form style={{paddingRight:"10%"}} onSubmit={handleSubmit(UpdateData)}>

                        <Label>Tipo de Plataformas</Label>

                        <div style={{display:'inline-flex', width:"99%"}}>
                        <InputBar/>
                            <SelectElement name="TipoPlataforma" onChange={(event)=>setplataformaElement({...plataformaElement, Tipo:event.target.value})} value={plataformaElement.Tipo} ref={register}>
                                <SelectOptionElement value="Investigación Médica">Investigación Médica</SelectOptionElement>
                                <SelectOptionElement value="Patologias Raras">Patologias Raras</SelectOptionElement>
                                <SelectOptionElement value="Iniciativas en Investigacion">Iniciativas en Investigación</SelectOptionElement>
                                <SelectOptionElement value="Conexion de Pacientes">Conexión de Pacientes</SelectOptionElement>
                                <SelectOptionElement value="Medicamentos Huerfanos">Medicamentos Huerfanos</SelectOptionElement>
                            </SelectElement>
                        </div>

                        <Label>Nombre</Label>

                        <div style={{display:'inline-flex', width:"99%"}}>
                            <InputBar/>
                            <InputContent name="Nombre"  onChange={(event)=>setplataformaElement({...plataformaElement, Nombre:event.target.value})} value={plataformaElement.Nombre} ref={register({ required: true })}/>
                        </div>
                        <div>{errors.Nombre && <ErrorForm>Este campo es requerido</ErrorForm>}</div>

                        <Label>Descripción</Label>

                        <div style={{display:'inline-flex', width:"99%"}}>
                            <InputBar/>
                            <TextAreaElement rows="10" cols="30" name="Descripcion" onChange={(event)=>setplataformaElement({...plataformaElement, Descripcion:event.target.value})} value={plataformaElement.Descripcion} ref={register} />
                        </div>
                        <div>{errors.Descripcion && <ErrorForm>Este campo es requerido</ErrorForm>}</div>

                        <Label>Logo (Url) </Label>

                        <div style={{display:"block",width:"99%"}}>
                            <input
                                    placeholder='Nombre del archivo'
                                    value={`${extensionName}`}
                                    onChange={e => updatePlataformaName(e.target.files[0].name)}
                                    style={{height:"100%", background:"transparent", border: "none"}}
                            />
                            <input
                                    type="file"
                                    onChange={handleChange}
                                    style={{margin: '10px 0px'}}
                                    ref={register({ required: true })}
                                    id="org-upload"
                                />
                            <label htmlFor="org-upload">Cargar Archivo</label>
                        </div>

                        <img  src={FilePreviewImg} style={{width:"50%", marginTop:"3%"}}/>
                        <Label>Url</Label>

                        <div style={{display:'inline-flex', width:"99%"}}>
                            <InputBar/>
                            <InputContent name="URL" onChange={(event)=>setplataformaElement({...plataformaElement, URL:event.target.value})} value={plataformaElement.URL} ref={register}/>
                        </div>

                        <InputSubmit width="30%" height="30%" type="submit" value="ACTUALIZAR PLATAFORMA" />

                    </form>
                </div>
                </Col>
            </Row>
            </Paper>
        </div>
    )
}

export default PlatformEditForm