import React from 'react'
import {Route} from 'react-router-dom'
import Landing from './views/client/Landing'
import { withAuthenticator } from '@aws-amplify/ui-react'
import HomeAdmin from './views/admin/HomeAdmin'
import Biblioteca from './views/client/Biblioteca'
import Normativa from './views/client/Normativa'
import Plataforma from './views/client/Plataforma'
import Profesionales from './views/client/Profesionales'
import Investigacion from './views/client/Investigacion'
import Media from './views/client/Media'
import OrganizacionPaciente from './views/client/OrganizacionPaciente'
import DetailLibrary from './views/client/detail/LibraryDetailDisplay'
import DetailNorma from './views/client/detail/NormaDetailDisplay'
import EditDetail from './components/edit/EditDetail'
import PasswordArea from './views/admin/PS'

export default function BaseRouter(){
    return(
        <div>
            <Route  path='/' exact component={Landing}/>
            <Route  path='/admin/' exact component={PasswordArea}/>
            <Route  path='/admin/:passed/seeit' exact component={HomeAdmin}/>

            <Route  path='/biblioteca' exact component={Biblioteca}/>
            <Route  path='/normativa' exact component={Normativa}/>
            <Route  path='/plataforma' exact component={Plataforma}/>
            <Route  path='/organizacion' exact component={OrganizacionPaciente}/>
            <Route  path='/profesionales' exact component={Profesionales}/>
            <Route  path='/investigacion' exact component={Investigacion}/>
            <Route  path='/media' exact component={Media}/>

            <Route  path='/biblioteca/:bookID' exact component={DetailLibrary}/>
            <Route  path='/normativa/:normativaID' exact component={DetailNorma}/>

            <Route  path='/biblioteca/edit/:generalID' exact component={EditDetail}/>
            <Route  path='/normativa/edit/:generalID' exact component={EditDetail}/>
            <Route  path='/plataforma/edit/:generalID' exact component={EditDetail}/>
            <Route  path='/media/edit/:generalID' exact component={EditDetail}/>
            <Route  path='/organizacion/edit/:generalID' exact component={EditDetail}/>
            <Route  path='/profesional/edit/:generalID' exact component={EditDetail}/>
            <Route  path='/investigacion/edit/:generalID' exact component={EditDetail}/>
        </div>
    )
}