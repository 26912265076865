import React from 'react'
import styled from 'styled-components'

const ButtonPrimary = styled.button`
  width:${props => props.width || "200px"};
  height:${props => props.height || "100px"};
  background: ${props => props.background || "transparent"};
  border-radius: 3px;
  border: 2px solid #6C0E60;
  color: ${props => props.color || "#6C0E60"};
  margin:${props => props.margin || "0 1em"}; 
  padding: 0.25em 1em;
  &:hover{
    background-color: ${props => props.color || "#6C0E60"};
    color:${props => props.background || "transparent"};
  }
`

const ButtonGeneralBackground = styled.button`
  width:${props => props.widthball || "400px"};
  height:${props => props.heightball || "400px"};
  background-image: linear-gradient(to right, rgba(120, 17, 119, 0.5), rgba(52, 52, 122, 0.5), rgba(93, 27, 93, 0.5)),url(${props => props.image || "none"});
  background-size: cover;
  clip-path: circle(50% at 50% 50%);
  &:hover{
    filter: blur(4px)
  };
  @media only screen and (max-width: 600px) {
    width:150px;
    height:150px;
  }

`

const ButtonGeneralText = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  color:white;
  transform: translate(-50%, -50%);
  background-image: none;
  background-color: transparent;
  border: none;
  z-index:2000;
  font-size:${props => props.fontSize || "2rem"};
  font-weight:bold;
  @media only screen and (max-width: 600px) {
    font-size:1rem;
  }
  `

export {ButtonPrimary,ButtonGeneralBackground,ButtonGeneralText}