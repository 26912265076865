import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import {Link} from 'react-router-dom'
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';
import {AmplifyAuthenticator, AmplifySignOut} from "@aws-amplify/ui-react";
import '../styles/layout.css'

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

export default function TemporaryDrawer(props) {
  const classes = useStyles();
  const [state, setState] = useState({left: false});
  const [accordionoption, setaccordionoption] = useState(false)
  const [accordionoptioncharge, setaccordionoptioncharge] = useState(false)
  const [isAuthenticated, setisAuthenticated] = useState(props.signIn)


  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  useEffect(()=>{
      if(accordionoptioncharge == "true"){
        setaccordionoption(true)
      }
      else{
        setaccordionoption(false)
      }
  },[accordionoptioncharge])

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
          <ListItem button><a className="home-link-movil" href="https://www.cer.unbosque.edu.co/" >Home CER</a></ListItem>
          <Divider />
          <ListItem button><Link className="home-link-movil" to="/">Inicio</Link></ListItem>
          <Divider />
          <ListItem button><Link className="home-link-movil" to="/biblioteca">Biblioteca</Link></ListItem>
          <Divider />
          <ListItem button><Link className="home-link-movil" to="/normativa">Marco Normativo</Link></ListItem>
          <Divider />
          <ListItem button><Link className="home-link-movil" to="/profesionales">Organizaciones de Pacientes</Link></ListItem>
          <Divider />
          <ListItem button><Link className="home-link-movil" to="/organizacion">CER Especialista</Link></ListItem>
          <Divider />
          <ListItem button><Link className="home-link-movil" to="/investigacion">Grupos de Investigación</Link></ListItem>
          <Divider />
          <ListItem button><Link className="home-link-movil" to={{pathname:`/plataforma`, state:{type:'Investigacion Medica'}}} >Plataformas</Link></ListItem>
          <Divider />
          <ListItem button><Link className="home-link-movil" to={{pathname:`/media`, state:{type:'Video'}}}>Recursos Digitales</Link></ListItem>
          <Divider />
          <ListItem button>
            {
              isAuthenticated ?
              <AmplifySignOut />
              :
              <Link to="/">Ingresar</Link>
            }
          </ListItem>
      </List>
    </div>
  );

  return (
    <div>
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
            <div style={{padding:"0 20px 0 0", textAlign:"end"}} key={"left"}>
                <button style={{color:"white", backgroundColor:"transparent", border:"none"}} onClick={toggleDrawer("left", true)}><MenuIcon /></button>
                <Drawer anchor={"left"} open={state["left"]} onClose={toggleDrawer("left", false)}>
                    {list("left")}
                </Drawer>
            </div>
            </Grid>
        </Grid>
    </div>
  );
}