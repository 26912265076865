import React, {useState, useEffect} from 'react'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { ButtonPrimary, ButtonGeneralBackground, ButtonGeneralText } from '../../components/Button';
import {InputContent,InputBar} from '../../components/Input';
import {MainTitle} from '../../components/Datadisplay'
import { BookFilled } from '@ant-design/icons';
import Biblioteca from "../../assets/img/Biblioteca.jpeg"
import Norma from "../../assets/img/Normas.jpg"
import Media from "../../assets/img/Media.jpg"
import Organizacion from "../../assets/img/Organizacion.jpg"
import InvestigacionImg from "../../assets/img/Investigacion.jpg"
import PlataformasImg from "../../assets/img/Plataformas.jpg"
import ProfesionalImg from "../../assets/img/Profesional.jpg"
import VideoImg from "../../assets/img/Video.jpg"
import { Collapse, Carousel} from 'antd';
import '../../styles/animations.css'
import '../../styles/styles.css'
import styled from 'styled-components'
import { RightOutlined } from '@ant-design/icons';
import LogoFECOER from "../../assets/img/LogoFecoer.png"
import {Link} from "react-router-dom"
import Hero from '../../assets/img/Hero.jpg'
import LogoFECOERCarousel from '../../assets/img/Landing_Logo_Fecoer.png'
import LogoUBosqueRCarousel from '../../assets/img/Landing_Logo_UBosque.png'
import LogoIexCarousel from '../../assets/img/Landing_Logo_Iex.png'
import LogoAmchamCarousel from '../../assets/img/Landing_Logo_Amcham.png'
import LogoIsporCarousel from '../../assets/img/Landing_Logo_Ispor.png'
import LogoIEIMCarousel from '../../assets/img/Landing_Logo_IEIM.png'
import LogoGeneticaCarousel from '../../assets/img/Landing_Logo_GeneticaHumana.png'
import LogoMovistarCarousel from '../../assets/img/Landing_Logo_Movistar.png'
import LogoHomecenterCarousel from '../../assets/img/Landing_Logo_Homecenter.png'
import LogoUAntioquiaCarousel from '../../assets/img/Landing_Logo_UDEA.png'
import LogoMovistarArenaCarousel from '../../assets/img/Landing_Logo_MovistarArena.png'




const { Panel } = Collapse;

const PanelHeader = styled.p`
    display: block;
    font-size: 2rem;
    font-family: 'Acherus Grotesque';
    text-align: left;
    margin-left: 10px;
`
const HeroDiv = styled.div`
    display:block;
    width:100%;
    height:800px;
    background-image: url(${Hero}) ;
    background-position: 25% 50%;
    background-size:cover;
    z-index:10;
`
const PrincipalTitle = styled.h1`
    display:block;
    font-family: 'Poppins';
    font-size: ${props => props.fontsize || "1rem"};
    text-align: right;
    color: white;
    /* padding: 30% 60% 0% 0%; */
    position: inherit;
    top: 100px;
    left: 100px;
    z-index:20;
`

export default function LandingClient(){

    
    return(
        <div style={{paddingBottom:"10%"}}>
            <img>
            
            </img>
            <HeroDiv className="HeroDiv">
                <PrincipalTitle className="title-hero" fontsize="3rem"><span className="title-hero-span" style={{fontSize:"1.4rem", fontWeight:"300"}}>Clúster de Enfermedades Raras</span><br/>Biblioteca</PrincipalTitle>
            </HeroDiv>
            <Collapse className="CollapseFECOER" accordion style={{margin:"4% 10% 4% 10%"}} bordered={false} expandIcon={({ isActive }) => <RightOutlined style={{fontSize:"1em", padding:"1% 0% 0% 0%"}} rotate={isActive ? 90 : 0} />}>
            <Panel style={{margin:"10px 0"}} header={<PanelHeader>Biblioteca</PanelHeader>} key="1">
                <Grid style={{margin:"10px 0"}} container spacing={3}>
                    <Grid item xs={6} sm={6} md={4} lg={4}>
                        <div className="slide-in-elliptic-top-fwd">
                        <Link to="/biblioteca" style={{textDecoration:"none"}}>
                            <ButtonGeneralText fontSize="1.4rem">Biblioteca</ButtonGeneralText>
                            <ButtonGeneralBackground widthball="15rem" heightball="15rem" image={Biblioteca}/>
                        </Link>
                        </div>
                    </Grid>
                    <Grid item xs={6} sm={6} md={2} lg={4}>
                        <div className="slide-in-elliptic-top-fwd">
                        <Link to="/normativa" style={{textDecoration:"none"}}>
                            <ButtonGeneralText fontSize="1.4rem">Marco Normativo</ButtonGeneralText>
                            <ButtonGeneralBackground widthball="15rem" heightball="15rem" image={Norma}/>
                        </Link>
                        </div>
                    </Grid>
                </Grid>
            </Panel>
            <Panel style={{margin:"10px 0"}} header={<PanelHeader>Recursos Audiovisuales</PanelHeader>} key="2">
                <Grid style={{margin:"10px 0"}} container spacing={3}>
                    <Grid item xs={6} sm={6} md={2} lg={4}>
                        <div className="slide-in-elliptic-top-fwd">
                        <Link to="/media" style={{textDecoration:"none"}}>
                            <ButtonGeneralText fontSize="1.4rem">Videos<br/> Institucionales</ButtonGeneralText>
                            <ButtonGeneralBackground widthball="15rem" heightball="15rem" image={VideoImg}/>
                        </Link>
                        </div>
                    </Grid>
                    {/* <Grid item xs={6} sm={6} md={2} lg={4}>
                        <div className="slide-in-elliptic-top-fwd">
                        <Link to="/media" style={{textDecoration:"none"}}>
                            <ButtonGeneralText fontSize="1.4rem">PodCast</ButtonGeneralText>
                            <ButtonGeneralBackground widthball="15rem" heightball="15rem" image={Media}/>
                        </Link>
                        </div>
                    </Grid> */}
                </Grid>
            </Panel>
            <Panel style={{margin:"10px 0"}} header={<PanelHeader>Gente y Lugares</PanelHeader>} key="3">
                <Grid style={{margin:"10px 0"}} container spacing={3}>
                    <Grid item xs={6} sm={6} md={4} lg={4}>
                        <div className="slide-in-elliptic-top-fwd">
                        <Link to="/organizacion" style={{textDecoration:"none"}}>
                            <ButtonGeneralText fontSize="1.3rem">Organizaciones <br/> de Pacientes</ButtonGeneralText>
                            <ButtonGeneralBackground widthball="15rem" heightball="15rem" image={Organizacion}/>
                        </Link>
                        </div>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={4}>
                        <div className="slide-in-elliptic-top-fwd">
                        <Link to="/profesionales" style={{textDecoration:"none"}}>
                            <ButtonGeneralText fontSize="1.4rem">Profesionales</ButtonGeneralText>
                            <ButtonGeneralBackground widthball="15rem" heightball="15rem" image={ProfesionalImg}/>
                        </Link>
                        </div>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={4}>
                        <div className="slide-in-elliptic-top-fwd">
                        <Link to="/investigacion" style={{textDecoration:"none"}}>
                            <ButtonGeneralText fontSize="1.4rem">Grupos <br/> de Investigación</ButtonGeneralText>
                            <ButtonGeneralBackground widthball="15rem" heightball="15rem" image={InvestigacionImg}/>
                        </Link>
                        </div>
                    </Grid>
                </Grid>
            </Panel>
            <Panel style={{margin:"10px 0"}} header={<PanelHeader>Recursos Digitales</PanelHeader>} key="4">
                <Grid style={{margin:"10px 0"}} container spacing={3}>
                    <Grid item xs={6} sm={6} md={4} lg={4}>
                        <div className="slide-in-elliptic-top-fwd">
                        <Link to="/plataforma" style={{textDecoration:"none"}}>
                            <ButtonGeneralText fontSize="1.1rem">Plataformas <br/> de Enfermedades Raras</ButtonGeneralText>
                            <ButtonGeneralBackground widthball="15rem" heightball="15rem" image={PlataformasImg}/>
                        </Link>
                        </div>
                    </Grid>
                    {/* <Grid item xs={6} sm={6} md={4} lg={4}>
                        <div className="slide-in-elliptic-top-fwd">
                        <Link to={{pathname:`/plataforma`, state:{type:'Investigacion Medica'}}}>
                            <ButtonGeneralText fontSize="1.4rem">Investigación <br/> Medica</ButtonGeneralText>
                            <ButtonGeneralBackground widthball="15rem" heightball="15rem" image={Media}/>
                        </Link>
                        </div>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={4}>
                        <div className="slide-in-elliptic-top-fwd">
                        <Link to={{pathname:`/plataforma`, state:{type:'Patologias Raras'}}}>
                            <ButtonGeneralText fontSize="1.4rem">Patologias <br/> Raras</ButtonGeneralText>
                            <ButtonGeneralBackground widthball="15rem" heightball="15rem" image={Media}/>
                        </Link>
                        </div>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={4}>
                        <div className="slide-in-elliptic-top-fwd">
                        <Link to={{pathname:`/plataforma`, state:{type:'Iniciativas en Investigacion'}}}>
                            <ButtonGeneralText fontSize="1.4rem">Iniciativas <br/> en Investigacion</ButtonGeneralText>
                            <ButtonGeneralBackground widthball="15rem" heightball="15rem" image={Media}/>
                        </Link>
                        </div>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={4}>
                        <div className="slide-in-elliptic-top-fwd">
                        <Link to={{pathname:`/plataforma`, state:{type:'Conexión de Pacientes'}}}>
                            <ButtonGeneralText fontSize="1.4rem">Conexión de <br/> Pacientes</ButtonGeneralText>
                            <ButtonGeneralBackground widthball="15rem" heightball="15rem" image={Media}/>
                        </Link>
                        </div>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={4}>
                        <div className="slide-in-elliptic-top-fwd">
                        <Link to={{pathname:`/plataforma`, state:{type:'Medicamentos Huerfanos'}}}>
                            <ButtonGeneralText fontSize="1.4rem">Medicamentos<br/> Huerfanos</ButtonGeneralText>
                            <ButtonGeneralBackground widthball="15rem" heightball="15rem" image={Media}/>
                        </Link>
                        </div>
                    </Grid> */}
                </Grid>
            </Panel>
            </Collapse>
            <Grid style={{width:"80%", margin:"0 10%"}} container spacing={3}>
                <Grid item xs={12} sm={4} md={4} lg={2} >
                    <img src={LogoUBosqueRCarousel}/>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={3}>
                    <img style={{width:"60%"}} src={LogoFECOERCarousel}/>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={3}>
                    <img style={{width:"60%"}} src={LogoAmchamCarousel}/>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={2}>
                    <img style={{width:"60%"}} src={LogoIexCarousel}/>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={2}>
                    <img style={{width:"60%"}} src={LogoIsporCarousel}/>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={2}>
                    <img style={{width:"60%"}} src={LogoIEIMCarousel}/>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={2}>
                    <img style={{width:"60%"}} src={LogoGeneticaCarousel}/>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={2}>
                    <img style={{width:"60%"}} src={LogoMovistarCarousel}/>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={2}>
                    <img style={{width:"60%"}} src={LogoHomecenterCarousel}/>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={2}>
                    <img style={{width:"60%"}} src={LogoUAntioquiaCarousel}/>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={2}>
                    <img style={{width:"60%"}} src={LogoMovistarArenaCarousel}/>
                </Grid>
            </Grid>
        </div>
    )
}

