import React, {useEffect, useState} from 'react'
import {Typography, Divider, Button, Row, Col, notification} from 'antd'
import Amplify, { API, graphqlOperation } from 'aws-amplify'
import { getNewPapers } from '../../../graphql/queries'
import {MainTitle, ErrorForm} from '../../Datadisplay'
import Paper  from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import styled from 'styled-components'
import {DoubleRightOutlined} from '@ant-design/icons'
import { updateNewPapers } from '../../../graphql/mutations'
import { useForm } from "react-hook-form";
import {ButtonPrimary} from '../../Button'
import {InputBar, InputContent,Label,InputSubmit, SelectElement,TextAreaElement,SelectOptionElement} from '../../Input'

const TitleDetail = styled.h1`
 font-size:2rem;
 font-family: "Roboto";
 color: #6C0E60;
 text-align: left;
`
const SubtitleDetail = styled.p`
 font-size:1rem;
 font-family: "Roboto";
 text-align: left;
 font-weight: 200
 font-variant: italic
`
const InfoDetailedTitle = styled.p`
 font-size:1rem;
 font-family: "Roboto";
 color: #6C0E60;
 text-align: left;
 font-weight: 700;
`

const dataPaper = 
{
      id:"",
      titulo:"Cargando...",
      titulo_orig:"Cargando",
      titulo_secundario:"",
      titulo_secundario_orig:"",
      Journal:"",
      Journal_orig:"",
      Paginas:"null",
      Paginas_orig:"null",
      Volumen:"null",
      Volumen_orig:"null",
      Numero:"null",
      Numero_orig:"null",
      PalabrasClave:[],
      PalabrasClave_orig:[],
      Fecha:null,
      Fecha_orig:null,
      Url:"",
      Url_orig:"",
      Abstract:"",
      Abstract_orig:"",
      Autores:[],
      Autores_orig:[],
      Idioma:"",
      Idioma_orig:""
}


const PaperEditForm= (props)=>{
    const {register, handleSubmit, errors} = useForm()
    const [paperID, setPaperID] = useState(props.idPaper)
    let [info, setinfo] = useState(dataPaper)
    const [alert, setAlert] = useState()
    const [paperElement, setpaperElement] = useState({
        titulo:"Cargando...",
        titulo_orig:"Cargando",
        titulo_secundario:"",
        titulo_secundario_orig:"",
        Journal:"",
        Journal_orig:"",
        Paginas:"null",
        Paginas_orig:"null",
        Volumen:"null",
        Volumen_orig:"null",
        Numero:"null",
        Numero_orig:"null",
        PalabrasClave:"",
        PalabrasClave_orig:"",
        Fecha:null,
        Fecha_orig:null,
        Url:"",
        Url_orig:"",
        Abstract:"",
        Abstract_orig:"",
        Autores:"",
        Autores_orig:"",
        TipoDocumental: "",
        TipoDocumental_orig: "",
        Licencia:"",
        Licencia_orig:"",
        Idioma:"",
        Idioma_orig:""
    })

    const SuccessAlert = () => {notification["success"]({
        message: 'Enhorabuena!',
        description:
          'Se ha creado el paper con exito',
    });
    }

    useEffect( async ()=>{
        const DataPapers = await API.graphql(graphqlOperation(getNewPapers, {id:paperID}))
        console.log(DataPapers)
        setinfo(DataPapers.data.getNewPapers)
    },[]) 

    useEffect(()=>{
        setpaperElement({
            titulo:info.titulo_orig,
            titulo_secundario:info.titulo_secundario_orig,
            Journal:"",
            Journal_orig:"",
            Paginas:info.Paginas_orig,
            Volumen:info.Volumen_orig,
            Numero:info.Numero_orig,
            PalabrasClave:info.PalabrasClave_orig,
            Fecha:info.Fecha,
            Url:info.Url_orig,
            Abstract:info.Abstract_orig,
            Autores:info.Autores_orig,
            TipoDocumental: info.TipoDocumental_orig,
            Idioma:info.Idioma_orig,
            Licencia: info.Licencia_orig
        })
    },[info])

    const removeAccents = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    } 
    const UpdateData = async (data,e) => {
    const paperUpdateID = paperID.toString()
      await API.graphql({query:updateNewPapers, variables: { input: { 
            id:paperUpdateID,
            titulo: removeAccents(data.titulo.toLowerCase()),
            titulo_orig: data.titulo,
            titulo_secundario: removeAccents(data.titulo_secundario.toLowerCase()),
            titulo_secundario_orig: data.titulo_secundario,
            Paginas: removeAccents(data.Paginas.toLowerCase()),
            Paginas_orig: data.Paginas,
            Volumen: removeAccents(data.Volumen.toLowerCase()),
            Volumen_orig: data.Volumen,
            Numero: removeAccents(data.Numero.toLowerCase()),
            Numero_orig: data.Numero,
            PalabrasClave: removeAccents(data.PalabrasClave.toLowerCase()).split(";").map(x => x.trim()),
            PalabrasClave_orig: data.PalabrasClave,
            Url: removeAccents(data.Url.toLowerCase()),
            Url_orig: data.Url,
            Abstract: removeAccents(data.Abstract.toLowerCase()),
            Abstract_orig: data.Abstract,
            Autores: removeAccents(data.Autores.toLowerCase()).split(";").map(x => x.trim()),
            Autores_orig: data.Autores,
            Licencia: removeAccents(data.Licencia.toLowerCase()),
            Licencia_orig: data.Licencia,
            Idioma: removeAccents(data.Idioma.toLowerCase()),
            Idioma_orig: data.Idioma,
            TipoDocumental: removeAccents(data.TipoDocumental.toLowerCase()),
            TipoDocumental_orig: data.TipoDocumental,
      }}})
      setAlert(SuccessAlert)
      e.target.reset()
    }
    
    return(
        <div style={{padding: "3%"}}>
            <Paper style={{padding: "3%"}}  elevation={3}>
            <Row>
                <Col span={12} style={{padding:"0 4% 0 0"}}>
                <TitleDetail>{info.titulo_orig}</TitleDetail>
                <SubtitleDetail><i>{info.titulo_secundario_orig}</i></SubtitleDetail>
                <SubtitleDetail><i><strong style={{color:"#6C0E60"}}>Autores : </strong> {info.Autores_orig}</i></SubtitleDetail>
                <SubtitleDetail><strong style={{color:"#6C0E60"}}>Abstract</strong></SubtitleDetail>
                <Divider className="detailpaper-divider"/>
                <div>
                    <Row>
                        <Col span={24}><p style={{textAlign:"left"}}>{info.Abstract_orig}</p></Col>
                    </Row> 
                </div>
                <div>
                    <Row>
                        <Col span={24}>
                            <List>
                                <ListItem>
                                    <Row className="detailpaper-row-list">
                                        <Col span={12}>
                                            <div style={{display: "inline-flex", alignContent:"center", alignItems:"baseline"}}>
                                                <DoubleRightOutlined style={{margin: "0px 10px 0px 0px"}} /><InfoDetailedTitle>Volumen</InfoDetailedTitle>
                                            </div>
                                            <p>{info.Volumen}</p>
                                        </Col>
                                    </Row>
                                </ListItem>

                                <Divider className="detailpaper-divider-list"/>

                                <ListItem>
                                    <Row className="detailpaper-row-list">
                                        <Col span={24}>
                                            <div style={{display: "inline-flex", alignContent:"center", alignItems:"baseline"}}>
                                                <DoubleRightOutlined style={{margin: "0px 10px 0px 0px"}} /><InfoDetailedTitle>Numero</InfoDetailedTitle>
                                            </div>
                                            <p>{info.Numero}</p>
                                        </Col>
                                    </Row>
                                </ListItem>

                                <Divider className="detailpaper-divider-list"/>

                                <ListItem>
                                    <Row className="detailpaper-row-list">
                                        <Col span={24}>
                                            <div style={{display: "inline-flex", alignContent:"center", alignItems:"baseline"}}>
                                                <DoubleRightOutlined style={{margin: "0px 10px 0px 0px"}} /><InfoDetailedTitle>Fecha</InfoDetailedTitle>
                                            </div>
                                            <p>{info.Fecha}</p>
                                        </Col>
                                    </Row>
                                </ListItem>

                                <Divider className="detailpaper-divider-list"/>

                                <ListItem>
                                    <Row className="detailpaper-row-list">
                                        <Col span={24}>
                                            <div style={{display: "inline-flex", alignContent:"center", alignItems:"baseline"}}>
                                                <DoubleRightOutlined style={{margin: "0px 10px 0px 0px"}} /><InfoDetailedTitle>Páginas</InfoDetailedTitle>
                                            </div>
                                            <p>{info.Paginas}</p>
                                        </Col>
                                    </Row>
                                </ListItem>

                                <Divider className="detailpaper-divider-list"/>

                                <ListItem>
                                    <Row className="detailpaper-row-list">
                                        <Col span={24}>
                                            <div style={{display: "inline-flex", alignContent:"center", alignItems:"baseline"}}>
                                                <DoubleRightOutlined style={{margin: "0px 10px 0px 0px"}} /><InfoDetailedTitle>URL</InfoDetailedTitle>
                                            </div>
                                            <p><a href={info.Url_orig}>{info.Url}</a></p>
                                        </Col>
                                    </Row>
                                </ListItem>

                                <Divider className="detailpaper-divider-list"/>

                                <ListItem>
                                    <Row className="detailpaper-row-list">
                                        <Col span={24}>
                                            <div style={{display: "inline-flex", alignContent:"center", alignItems:"baseline"}}>
                                                <DoubleRightOutlined style={{margin: "0px 10px 0px 0px"}} /><InfoDetailedTitle>Licencia</InfoDetailedTitle>
                                            </div>
                                            <p className="detailpaper-datavalue">{info.Licencia === "" ? "CC" : info.Licencia_orig }</p>
                                        </Col>
                                    </Row>
                                </ListItem>

                                <ListItem>
                                    <Row className="detailpaper-row-list">
                                        <Col span={24}>
                                        <div style={{display: "inline-flex", alignContent:"center", alignItems:"baseline"}}>
                                                <DoubleRightOutlined style={{margin: "0px 10px 0px 0px"}} /><InfoDetailedTitle>Idioma</InfoDetailedTitle>
                                            </div>
                                            <p>{info.Idioma_orig}</p>
                                        </Col>
                                    </Row>
                                </ListItem>

                            </List>
                        </Col>
                    </Row> 
                </div>
                </Col>
                <Col span={12}>
                    <div className="scale-in-hor-left">
                        <MainTitle size="4rem" aligntext="left">Formulario de Papers</MainTitle>
                        <form style={{paddingRight:"10%"}} onSubmit={handleSubmit(UpdateData)}>

                            <Label>Tipo de Documento</Label>

                            <div style={{display:'inline-flex', width:"99%"}}>
                            <InputBar/>
                                <SelectElement name="TipoDocumental" onChange={(event)=>setpaperElement({...paperElement, TipoDocumental:event.target.value})} value={paperElement.TipoDocumental} ref={register}>
                                    <SelectOptionElement value="Articulo">Artículo</SelectOptionElement>
                                    <SelectOptionElement value="Libro">Libro</SelectOptionElement>
                                    <SelectOptionElement value="Paper">Paper</SelectOptionElement>
                                    <SelectOptionElement value="Paper">Informe</SelectOptionElement>
                                    <SelectOptionElement value="Paper">Otro</SelectOptionElement>
                                </SelectElement>
                            </div>

                            <Label>Título</Label>

                            <div style={{display:'inline-flex', width:"99%"}}>
                                <InputBar/>
                                <InputContent name="titulo" onChange={(event)=>setpaperElement({...paperElement, titulo:event.target.value})} value={paperElement.titulo}   ref={register({ required: true })}/>
                            </div>
                            <div>{errors.titulo && <ErrorForm>Este campo es requerido</ErrorForm>}</div>

                            <Label>Autores</Label>

                            <div style={{display:'inline-flex', width:"99%"}}>
                                <InputBar/>
                                <InputContent name="Autores"  onChange={(event)=>setpaperElement({...paperElement, Autores:event.target.value})} value={paperElement.Autores} ref={register}/>
                            </div>
                            <div>{errors.Autores && <ErrorForm>Este campo es requerido</ErrorForm>}</div>

                            <Label>Revista / Editorial </Label>

                            <div style={{display:'inline-flex', width:"99%"}}>
                                <InputBar/>
                                <InputContent name="titulo_secundario"  onChange={(event)=>setpaperElement({...paperElement, titulo_secundario:event.target.value})} value={paperElement.titulo_secundario} ref={register}/>
                            </div>

                            <Label>Fecha (Año)</Label>

                            <div style={{display:'inline-flex', width:"99%"}}>
                                <InputBar/>
                                <InputContent name="Fecha" onChange={(event)=>setpaperElement({...paperElement, Fecha:event.target.value})} value={paperElement.Fecha} min="1900" max="2099" step="1" ref={register}/>
                            </div>

                            <Label>Volúmen</Label>

                            <div style={{display:'inline-flex', width:"99%"}}>
                                <InputBar/>
                                <InputContent name="Volumen" onChange={(event)=>setpaperElement({...paperElement, Volumen:event.target.value})} value={paperElement.Volumen} ref={register}/>
                            </div>

                            <Label>Número</Label>

                            <div style={{display:'inline-flex', width:"99%"}}>
                                <InputBar/>
                                <InputContent name="Numero"  onChange={(event)=>setpaperElement({...paperElement, Numero:event.target.value})} value={paperElement.Numero} ref={register}/>
                            </div>

                            <Label>Páginas</Label>

                            <div style={{display:'inline-flex', width:"99%"}}>
                                <InputBar/>
                                <InputContent name="Paginas" onChange={(event)=>setpaperElement({...paperElement, Paginas:event.target.value})} value={paperElement.Paginas} ref={register}/>
                            </div>

                            <Label>Abstract</Label>

                            <div style={{display:'inline-flex', width:"99%"}}>
                                <InputBar/>
                                <TextAreaElement rows="10" cols="30" name="Abstract" value={paperElement.Abstract} onChange={(event)=>setpaperElement({...paperElement, Abstract:event.target.value})} ref={register} />
                            </div>
                            <div>{errors.Abstract && <ErrorForm>Este campo es requerido</ErrorForm>}</div>

                            <Label>Palabras Clave (Separadas por ;)</Label>

                            <div style={{display:'inline-flex', width:"99%"}}>
                                <InputBar/>
                                <InputContent name="PalabrasClave" value={paperElement.PalabrasClave} onChange={(event)=>setpaperElement({...paperElement, PalabrasClave:event.target.value})} ref={register}/>
                            </div>

                            <Label>Url</Label>

                            <div style={{display:'inline-flex', width:"99%"}}>
                                <InputBar/>
                                <InputContent name="Url" value={paperElement.Url} onChange={(event)=>setpaperElement({...paperElement, Url:event.target.value})}  ref={register}/>
                            </div>

                            <Label>Licencia</Label>

                            <div style={{display:'inline-flex', width:"99%"}}>
                                <InputBar/>
                                <InputContent name="Licencia" value={paperElement.Licencia} onChange={(event)=>setpaperElement({...paperElement, Licencia:event.target.value})} ref={register}/>
                            </div>

                            <Label>Idioma</Label>

                            <div style={{display:'inline-flex', width:"99%"}}>
                                <InputBar/>
                                <SelectElement name="Idioma" onChange={(event)=>setpaperElement({...paperElement, Idioma:event.target.value})} value={paperElement.Idioma} ref={register}>
                                    <SelectOptionElement value="Ingles">Inglés</SelectOptionElement>
                                    <SelectOptionElement value="Espanol">Español</SelectOptionElement>
                                    <SelectOptionElement value="Frances">Frances</SelectOptionElement>
                                    <SelectOptionElement value="Portugues">Portugués</SelectOptionElement>
                                    <SelectOptionElement value="Aleman">Aleman</SelectOptionElement>
                                    <SelectOptionElement value="Otro">Otro</SelectOptionElement>
                                </SelectElement>
                            </div>

                            <InputSubmit width="30%" height="30%" type="submit" value="ACTUALIZAR PAPER" />
                        </form>
                    </div>
                </Col>
            </Row>
            </Paper>
        </div>
    )
}

export default PaperEditForm