import React from 'react'
import styled from 'styled-components'

const MainTitle = styled.p`
  font-family:"Roboto";
  font-size:${props=>props.size || "1rem"};
  color: #6C0E60;
  margin:${props=>props.margin || "10px"};
  text-align:${props=>props.aligntext || "center"};
  font-weight:100;
`
const ErrorForm = styled.span`
  font-family:"Roboto";
  color:#ff0000;
  text-align:${props=>props.aligntext || "left"};
  font-size:${props=>props.size || "1rem"};
  display:block;
  width:99%;
`

export {MainTitle, ErrorForm}