import React, {useState, useEffect} from 'react'
import Amplify, { API, graphqlOperation } from 'aws-amplify'
import { listNewPaperss, syncPapers } from '../../graphql/queries'
/* import awsmobile from '../../aws-exports' */
import {Link} from 'react-router-dom'
import { Card, Col, Row, Divider, Modal, Empty, Tag, Switch } from 'antd';
import {InputBar, InputContent, InputCheckbox, InputSubmit, LabelBiblioteca} from '../../components/Input'
import LogoCluster from '../../assets/img/LogoClusterEnfermedades.png'
import { useForm } from "react-hook-form";
import {CardLibrary} from '../../components/CardsDisplay'
import { RightOutlined, LoadingOutlined  } from '@ant-design/icons';
import { Tabs } from 'antd';
import styled from 'styled-components'
import {PalabrasClaveLoop, AbstractLoop, AutorLoop, TituloLoop, RevistaLoop} from '../../components/Filters'
import Grid from '@material-ui/core/Grid';


const GeneralTitle = styled.p`
    font-family: "Roboto";
    font-size:2.5rem;
    font-weight:200;
    width:100%;
    text-align:left;
    padding-left:5%;
    @media only screen and (max-width: 320px) {
      font-family: "Roboto";
      font-size:12pt;
      font-weight:200;
      width:100%;
      text-align:left;
    }
    @media only screen and (max-width: 600px) {
      font-family: "Roboto";
      font-size:1rem;
      font-weight:200;
      width:100%;
      text-align:left;
    }
    @media only screen and (max-width: 1060px) {
      font-family: "Roboto";
      font-size:1.8rem;
      font-weight:200;
      width:100%;
      text-align:left;
    }
    @media only screen and (max-width: 1221px) {
      font-family: "Roboto";
      font-size:2rem;
      font-weight:200;
      width:100%;
      text-align:left;
    }
`

const LogoClusterBox = styled.img.attrs({src:LogoCluster})`
    width:70%;
    @media only screen and (max-width: 600px) {
      width:80%;
    }
    @media only screen and (min-width: 600px) {
      width:100%;
    }
    @media only screen and (min-width: 800px) {
      width:70%;
    }
    @media only screen and (min-width: 1440px) {
      width:60%;
    }
`

const GoNormas = styled.button`
    background: none;
    font-family: "Roboto";
    font-size:1rem;
    font-weight:200;
    width:100%;
    text-align:right;
    border: none;
    width:96%;
    margin: 2% 10% 0% 0%;
`
const MainTitle = styled.p`
  font-family:"Roboto";
  font-size:${props=>props.size || "3rem"};
  color: #6C0E60;
  margin:${props=>props.margin || "10px"};
  text-align:${props=>props.aligntext || "left"};
  font-weight:100;
`
const TagFrecuent = styled.input`
  font-family:"Poppins";
  font-size:1rem;
  color: #6C0E60;
  text-align:center;
  font-weight:400;
  border: 2px solid #6C0E60;
  margin:0% 3%;
  &:hover{
    background-color: #6C0E60;
    color:white;
  }
`
export default function Biblioteca(){


    const {register, handleSubmit, errors, control, defaultChecked, reset} = useForm()
    let [checked, setchecked] = useState(false)
    let [searchbarresults, setsearchbarresults] = useState([])
    const [showresults, setshowresults] = useState(false)
    const [newhook, setnewhook] = useState([])
    let [initialState, setInitialState] = useState(true)
    let [empty_general, setempty_general] = useState(<div>Funciona</div>)
    let [Loading, setLoading] = useState(false)
    let [searchbargeneral, setsearchgeneral] = useState("")
    let [taggeneral , settaggeneral] = useState()
    let [advancedsearch, setadvancedsearch] = useState(false)

    let Total=[]

    function camelCase(str) { 
        var regex = /[A-Z\xC0-\xD6\xD8-\xDE]?[a-z\xDF-\xF6\xF8-\xFF]+|[A-Z\xC0-\xD6\xD8-\xDE]+(?![a-z\xDF-\xF6\xF8-\xFF])|\d+/g;
        var match = str.match(regex);
        let result = "";
        

          for(let i = 0 , len = match.length; i < len; i++) {
            let currentStr = match[i];
            let tempStr = currentStr.toLowerCase();
            if(i != 0 || i != 1) {
              // convert first letter to upper case (the word is in lowercase) 
                tempStr = tempStr.substr(0, 1).toUpperCase() + tempStr.substr(1);
            }
            result +=tempStr;
          }
          return result.replace(/([a-z])([A-Z])/g, '$1 $2')
          //.replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3')
    } 
    const ResetSystem = () =>{
        searchbarresults = []
    }
    function getUniqueListBy(arr, key) {
      return [...new Map(arr.map(item => [item[key], item])).values()]
    }
    const onSubmitLibrary = async (data) =>{
        setLoading(true)
        setsearchgeneral(data.searchbar)

        const searchinputData = data.searchbar.toLowerCase().split(" ")
        let searchinputDataCamel = camelCase(data.searchbar).split(" ")
        let searchinputDataUpper = data.searchbar.toUpperCase().split(" ")
        let searchsplitedGeneral = data.searchbar.split(" ")

        //const searchinputDataProve = [data.searchbar, data.searchbar.toLowerCase(), data.searchbar.toUpperCase(), camelCase(data.searchbar).replace(/([a-z])([A-Z])/g, '$1 $2'), camelCase(data.searchbar).replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3') ]
        if(advancedsearch === false){
        const PalabrasClave_Comparacion_Lower = await API.graphql({query:listNewPaperss, variables : { filter : { PalabrasClave : { contains : searchinputData[0]} }}})
        const PalabrasClave_Comparacion_Camel = await API.graphql({query:listNewPaperss, variables : { filter : { PalabrasClave : { contains : searchinputDataCamel[0]} }}})
        const PalabrasClave_Comparacion_Upper = await API.graphql({query:listNewPaperss, variables : { filter : { PalabrasClave : { contains : searchinputDataUpper[0]} }}})
        const PalabrasClave_Comparacion_Lower_Orig = await API.graphql({query:listNewPaperss, variables : { filter : { PalabrasClave_orig : { contains : searchinputData[0]} }}})
        const PalabrasClave_Comparacion_Camel_Orig = await API.graphql({query:listNewPaperss, variables : { filter : { PalabrasClave_orig : { contains : searchinputDataCamel[0]} }}})
        const PalabrasClave_Comparacion_Upper_Orig = await API.graphql({query:listNewPaperss, variables : { filter : { PalabrasClave_orig : { contains : searchinputDataUpper[0]} }}})

        let PalabrasClave_Comparacion = PalabrasClave_Comparacion_Lower.data.listNewPaperss.items.concat(
          PalabrasClave_Comparacion_Camel.data.listNewPaperss.items, 
          PalabrasClave_Comparacion_Upper.data.listNewPaperss.items,
          PalabrasClave_Comparacion_Lower_Orig.data.listNewPaperss.items,
          PalabrasClave_Comparacion_Camel_Orig.data.listNewPaperss.items,
          PalabrasClave_Comparacion_Upper_Orig.data.listNewPaperss.items
        )

        let PalabrasClave_Comparacion_Puro = PalabrasClave_Comparacion.filter((valorActual, indiceActual, arreglo) => {
          return arreglo.findIndex(valorDelArreglo => JSON.stringify(valorDelArreglo) === JSON.stringify(valorActual)) === indiceActual
        });

        const Abstract_Comparacion_Lower = await API.graphql({query:listNewPaperss, variables : { filter : { Abstract : { contains : searchinputData[0]} }}})
        const Abstract_Comparacion_Camel = await API.graphql({query:listNewPaperss, variables : { filter : { Abstract : { contains : searchinputDataCamel[0]} }}})
        const Abstract_Comparacion_Upper = await API.graphql({query:listNewPaperss, variables : { filter : { Abstract : { contains : searchinputDataUpper[0]} }}})
        const Abstract_Comparacion_Lower_Orig = await API.graphql({query:listNewPaperss, variables : { filter : { Abstract_orig : { contains : searchinputData[0]} }}})
        const Abstract_Comparacion_Camel_Orig = await API.graphql({query:listNewPaperss, variables : { filter : { Abstract_orig : { contains : searchinputDataCamel[0]} }}})
        const Abstract_Comparacion_Upper_Orig = await API.graphql({query:listNewPaperss, variables : { filter : { Abstract_orig : { contains : searchinputDataUpper[0]} }}})
          
        let Abstract_Comparacion = Abstract_Comparacion_Lower.data.listNewPaperss.items.concat(
          Abstract_Comparacion_Camel.data.listNewPaperss.items, 
          Abstract_Comparacion_Upper.data.listNewPaperss.items,
          Abstract_Comparacion_Lower_Orig.data.listNewPaperss.items,
          Abstract_Comparacion_Camel_Orig.data.listNewPaperss.items,
          Abstract_Comparacion_Upper_Orig.data.listNewPaperss.items,
        )

        let Abstract_Comparacion_Puro = Abstract_Comparacion.filter((valorActual, indiceActual, arreglo) => {
            return arreglo.findIndex(valorDelArreglo => JSON.stringify(valorDelArreglo) === JSON.stringify(valorActual)) === indiceActual
        });
        var idsPA = new Set(PalabrasClave_Comparacion_Puro.map(d => d.id));
        
        Total = [...PalabrasClave_Comparacion_Puro, ...Abstract_Comparacion_Puro.filter(d => !idsPA.has(d.id))];
      }

        if(advancedsearch === true){
          if(data.Autores === "Autores"){ 
  
            let searchinputDataAutor = data.searchbar.toLowerCase().split(" ")
            let searchinputDataAutorCamel = camelCase(data.searchbar).split(" ")
            let searchinputDataAutorUpper = data.searchbar.toUpperCase().split(" ")
            let TotalAutorArray =  []
            let searchsplited = data.searchbar.toLowerCase().split(" ")
    
            const Autores_Comparacion = await API.graphql({query:listNewPaperss, variables : { filter : { Autores : { contains : searchinputDataAutor[0]} }}})
            const Autores_Comparacion_Camel = await API.graphql({query:listNewPaperss, variables : { filter : { Autores : { contains : searchinputDataAutorCamel[0]} }}})
            const Autores_Comparacion_Upper = await API.graphql({query:listNewPaperss, variables : { filter : { Autores : { contains : searchinputDataAutorUpper[0]} }}})
            
            let Autores_Comparacion_Arr = Autores_Comparacion.data.listNewPaperss.items.concat(Autores_Comparacion_Camel.data.listNewPaperss.items,Autores_Comparacion_Upper.data.listNewPaperss.items)
    
            let Autores_Comparacion_Arr_Puro = getUniqueListBy(Autores_Comparacion_Arr, 'id')
            
            if(searchinputDataAutor.length === 1 ){
              var idsTotalAutores = new Set(Total.map(d => d.id));
              Total = [...Total, ...Autores_Comparacion_Arr_Puro.filter(d => !idsTotalAutores.has(d.id))];
            }
            else{
              if(Autores_Comparacion_Arr_Puro.length === 0){
                return null
              }
                Autores_Comparacion_Arr_Puro.map((item)=>{
                let AutoresComparation = getUniqueListBy(AutorLoop(item, searchinputData), 'id')
                TotalAutorArray = [...TotalAutorArray, AutoresComparation] 
              })
    
              let TotalFilteredArray = TotalAutorArray.filter(item => item.length !== 0)
              let Final = getUniqueListBy(TotalFilteredArray)
  
              for (let index = 0; index < Final.length; index++) {
                const element = Final[index];
                let GenericArray = []
                let GeneralFinal = GenericArray.concat(element)
                Total = [...Total, ...GeneralFinal]
              }
            }
            } 
            
            if(data.Titulos === "Titulos"){
              let searchinputDataTitulos = data.searchbar.toLowerCase().split(" ")
              let searchinputDataTitulosCamel = camelCase(data.searchbar).split(" ")
              let searchinputDataTitulosUpper = data.searchbar.toUpperCase().split(" ")
              let TotalTituloArray =  []
    
              const Titulos_Comparacion_Lower = await API.graphql({query:listNewPaperss, variables : { filter : { titulo : { contains : searchinputDataTitulos[0]} }}})
              const Titulos_Comparacion_Camel = await API.graphql({query:listNewPaperss, variables : { filter : { titulo : { contains : searchinputDataTitulosCamel[0]} }}})
              const Titulos_Comparacion_Upper = await API.graphql({query:listNewPaperss, variables : { filter : { titulo : { contains : searchinputDataTitulosUpper[0]} }}})
              
              let Titulos_Comparacion_Arr = Titulos_Comparacion_Lower.data.listNewPaperss.items.concat(Titulos_Comparacion_Camel.data.listNewPaperss.items,Titulos_Comparacion_Upper.data.listNewPaperss.items)
              
              let Titulos_Comparacion_Arr_Puro = getUniqueListBy(Titulos_Comparacion_Arr, 'id')
  
              if(searchinputDataTitulos.length === 1 ){
                var idsTotalTitulos = new Set(Total.map(d => d.id));
                Total = [...Total, ...Titulos_Comparacion_Arr_Puro.filter(d => !idsTotalTitulos.has(d.id))];
              }
  
              else{
  
                if(Titulos_Comparacion_Arr_Puro.length === 0){
                  return null
                }
  
                  Titulos_Comparacion_Arr_Puro.map((item)=>{
                  let TitulosComparation = getUniqueListBy(TituloLoop(item, searchinputData), 'id')
                  TotalTituloArray = [...TotalTituloArray, TitulosComparation]                  
                })
                let TotalFilteredArray = TotalTituloArray.filter(item => item.length !== 0)
                let Final = getUniqueListBy(TotalFilteredArray)
  
                for (let index = 0; index < Final.length; index++) {
                  const element = Final[index];
                  let GenericArray = []
                  let GeneralFinal = GenericArray.concat(element)
                  Total = [...Total, ...GeneralFinal]
                }
              }
            } 
            
            if(data.Revistas === "Revistas"){ 
              let searchinputDataRevistas = data.searchbar.toLowerCase().split(" ")
              let searchinputDataRevistasCamel = camelCase(data.searchbar).split(" ")
              let searchinputDataRevistasUpper = data.searchbar.toUpperCase().split(" ")
              let TotalRevistaArray =  []
              
              const Revistas_Comparacion_Lower = await API.graphql({query:listNewPaperss, variables : { filter : { titulo_secundario : { contains : searchinputDataRevistas[0]} }}})
              const Revistas_Comparacion_Camel = await API.graphql({query:listNewPaperss, variables : { filter : { titulo_secundario : { contains : searchinputDataRevistasCamel[0]} }}})
              const Revistas_Comparacion_Upper = await API.graphql({query:listNewPaperss, variables : { filter : { titulo_secundario : { contains : searchinputDataRevistasUpper[0]} }}})
  
              let Revistas_Comparacion_Arr = Revistas_Comparacion_Lower.data.listNewPaperss.items.concat(Revistas_Comparacion_Camel.data.listNewPaperss.items, Revistas_Comparacion_Upper.data.listNewPaperss.items)
  
              let Revistas_Comparacion_Arr_Puro = getUniqueListBy(Revistas_Comparacion_Arr, 'id')
    
              if(searchinputDataRevistas.length === 1 ){
  
                var idsTotalRevistas = new Set(Total.map(d => d.id));
                Total = [...Total, ...Revistas_Comparacion_Arr_Puro.filter(d => !idsTotalRevistas.has(d.id))];
  
              }
              else{
  
                  if(Revistas_Comparacion_Arr_Puro.length === 0){
                    return null
                  }
  
                  Revistas_Comparacion_Arr_Puro.map((item)=>{
                    let RevistasComparation = getUniqueListBy(RevistaLoop(item, searchinputData), 'id')
                    TotalRevistaArray = [...TotalRevistaArray, RevistasComparation]
                  })
  
                  let TotalFilteredArray = TotalRevistaArray.filter(item => item.length !== 0)
                  let Final = getUniqueListBy(TotalFilteredArray)
  
                  for (let index = 0; index < Final.length; index++) {
                    const element = Final[index];
                    let GenericArray = []
                    let GeneralFinal = GenericArray.concat(element)
                    Total = [...Total, ...GeneralFinal]
                  }
  
              }
            }
  
            if(data.Abstract === "Abstract"){ 
              let searchinputDataAbstract = data.searchbar.toLowerCase().split(" ")
              let searchinputDataAbstractCamel = camelCase(data.searchbar).split(" ")
              let searchinputDataAbstractUpper = data.searchbar.toUpperCase().split(" ")
              let TotalAbstractArray =  []
              
              const Abstract_Comparacion_Lower = await API.graphql({query:listNewPaperss, variables : { filter : { Abstract : { contains : searchinputDataAbstract[0]} }}})
              const Abstract_Comparacion_Camel = await API.graphql({query:listNewPaperss, variables : { filter : { Abstract : { contains : searchinputDataAbstractCamel[0]} }}})
              const Abstract_Comparacion_Upper = await API.graphql({query:listNewPaperss, variables : { filter : { Abstract : { contains : searchinputDataAbstractUpper[0]} }}})
  
              let Abstract_Comparacion_Arr = Abstract_Comparacion_Lower.data.listNewPaperss.items.concat(Abstract_Comparacion_Camel.data.listNewPaperss.items, Abstract_Comparacion_Upper.data.listNewPaperss.items)
  
              let Abstract_Comparacion_Arr_Puro = getUniqueListBy(Abstract_Comparacion_Arr, 'id')
    
              if(searchinputDataAbstract.length === 1 ){
  
                var idsTotalAbstract = new Set(Total.map(d => d.id));
                Total = [...Total, ...Abstract_Comparacion_Arr_Puro.filter(d => !idsTotalAbstract.has(d.id))];
  
              }
              else{
  
                  if(Abstract_Comparacion_Arr_Puro.length === 0){
                    return null
                  }
  
                  Abstract_Comparacion_Arr_Puro.map((item)=>{
                    let AbstractComparation = getUniqueListBy(AbstractLoop(item, searchinputData), 'id')
                    TotalAbstractArray = [...TotalAbstractArray, AbstractComparation]
                  })
  
                  let TotalFilteredArray = TotalAbstractArray.filter(item => item.length !== 0)
                  let Final = getUniqueListBy(TotalFilteredArray)
  
                  for (let index = 0; index < Final.length; index++) {
                    const element = Final[index];
                    let GenericArray = []
                    let GeneralFinal = GenericArray.concat(element)
                    Total = [...Total, ...GeneralFinal]
                  }
  
              }
            }
  
            if(data.PalabrasClave === "PalabrasClave"){ 
              let searchinputDataPalabrasClave = data.searchbar.toLowerCase().split(" ")
              let searchinputDataPalabrasClaveCamel = camelCase(data.searchbar).split(" ")
              let searchinputDataPalabrasClaveUpper = data.searchbar.toUpperCase().split(" ")
              let TotalPalabrasClaveArray =  []
              
              const PalabrasClave_Comparacion_Lower = await API.graphql({query:listNewPaperss, variables : { filter : { PalabrasClave : { contains : searchinputDataPalabrasClave[0]} }}})
              const PalabrasClave_Comparacion_Camel = await API.graphql({query:listNewPaperss, variables : { filter : { PalabrasClave : { contains : searchinputDataPalabrasClaveCamel[0]} }}})
              const PalabrasClave_Comparacion_Upper = await API.graphql({query:listNewPaperss, variables : { filter : { PalabrasClave : { contains : searchinputDataPalabrasClaveUpper[0]} }}})
  
              let PalabrasClave_Comparacion_Arr = PalabrasClave_Comparacion_Lower.data.listNewPaperss.items.concat(PalabrasClave_Comparacion_Camel.data.listNewPaperss.items, PalabrasClave_Comparacion_Upper.data.listNewPaperss.items)
  
              let PalabrasClave_Comparacion_Arr_Puro = getUniqueListBy(PalabrasClave_Comparacion_Arr, 'id')
    
              if(searchinputDataPalabrasClave.length === 1 ){
  
                var idsTotalPalabrasClave = new Set(Total.map(d => d.id));
                Total = [...Total, ...PalabrasClave_Comparacion_Arr_Puro.filter(d => !idsTotalPalabrasClave.has(d.id))];
  
              }
              else{
  
                  if(PalabrasClave_Comparacion_Arr_Puro.length === 0){
                    return null
                  }
  
                  PalabrasClave_Comparacion_Arr_Puro.map((item)=>{
                    let PalabrasClaveComparation = getUniqueListBy(PalabrasClaveLoop(item, searchinputData), 'id')
                    TotalPalabrasClaveArray = [...TotalPalabrasClaveArray, PalabrasClaveComparation]
                  })
  
                  let TotalFilteredArray = TotalPalabrasClaveArray.filter(item => item.length !== 0)
                  let Final = getUniqueListBy(TotalFilteredArray)
  
                  for (let index = 0; index < Final.length; index++) {
                    const element = Final[index];
                    let GenericArray = []
                    let GeneralFinal = GenericArray.concat(element)
                    Total = [...Total, ...GeneralFinal]
                  }
  
              }
            }
  
            Total = getUniqueListBy(Total)
          }        
        setempty_general(<div>No hay resultados</div>)
        setsearchbarresults(Total)
        setInitialState(false)
        setshowresults(true)
        setLoading(false)
        /* e.target.reset(); */
      }

      useEffect(() => {
        /* ResetSystem() */
      }, [onSubmitLibrary])



    return(
        <div style={{paddingBottom:"20%", backgroundColor:"#EAEAEA"}} className="slide-in-left">
            <Link to="/normativa">
            <div style={{paddingTop: "3%", height:"130px"}}>
                <GoNormas>Ir a Normativa<RightOutlined /></GoNormas>
            </div>
            </Link>
           <div style={{alignItems: "center", width:"100%", padding:"5% 28%"}}>
             <Grid container spacing={2} style={{alignItems: "center"}}>
               <Grid item xs={10} sm={7} md={6} lg={6}>
                  <LogoClusterBox />
               </Grid>
               <Grid item xs={12} sm={5} md={6} lg={6}>
                 <div style={{display:'inline-flex'}}>
                  <InputBar heightbar="9rem" marginbar="5% 0%"/>
                  <GeneralTitle>Biblioteca de <br/>Enfermedades Raras</GeneralTitle>
                 </div>
               </Grid>
             </Grid>
           </div>

           <div style={{alignContent:"center"}}>
            <form onSubmit={handleSubmit(onSubmitLibrary)}>
            <br/>
            <div style={{ padding:"0% 5%",display:'inline-flex',width:"100%"}}>
                  <InputBar marginbar="0 0 0 3%"/>
                  <InputContent width="80%" style={{borderRadius:"0px 15px 15px 0px", border:"none"}} name="searchbar" placeholder=" Búsqueda" ref={register({ required: 'This is required' })}/>
              </div>
            <br/>
            <div>
              <Switch checked={advancedsearch} onChange={()=>{setadvancedsearch(!advancedsearch)}} />
              <LabelBiblioteca>Búsqueda Avanzada</LabelBiblioteca>
            </div>
            {
              advancedsearch === true ?
              <div>
                      <LabelBiblioteca>Buscar por:</LabelBiblioteca>
                    <Grid style={{display:'inline-flex', padding:"1% 0% 0 5%"}} container spacing={3}>
                      <Grid item xs={6} sm={3} md={2} lg={2}>
                      <div className="input-checkbox">
                        <LabelBiblioteca displayLabel="block" textAlign="center">Autores</LabelBiblioteca>
                        <input  name="Autores" value="Autores" type="checkbox" ref={register}/>
                      </div>
                      </Grid>
                      <Grid item xs={6} sm={3} md={2} lg={2}>
                      <div className="input-checkbox">
                        <LabelBiblioteca displayLabel="block" textAlign="center">Titulo de Articulo</LabelBiblioteca>
                        <input  name="Titulos" value="Titulos" type="checkbox" ref={register}/>
                      </div>
                      </Grid>
                      <Grid item xs={6} sm={3} md={2} lg={2}>
                      <div className="input-checkbox">
                        <LabelBiblioteca displayLabel="block" textAlign="center">Titulo de Revista</LabelBiblioteca>
                        <input  name="Revistas" value="Revistas" type="checkbox" ref={register}/>
                      </div>
                      </Grid>
                      <Grid item xs={6} sm={3} md={2} lg={2}>
                      <div className="input-checkbox">
                        <LabelBiblioteca displayLabel="block" textAlign="center">Abstract</LabelBiblioteca>
                        <input  name="Abstract" value="Abstract" type="checkbox" ref={register}/>
                      </div>
                      </Grid>
                      <Grid item xs={6} sm={3} md={2} lg={2}>
                      <div className="input-checkbox">
                        <LabelBiblioteca displayLabel="block" textAlign="center">Palabras Clave</LabelBiblioteca>
                        <input  name="PalabrasClave" value="PalabrasClave" type="checkbox" ref={register}/>
                      </div>
                      </Grid>
                    </Grid>
              </div>
              :
              null
            }
            <div>
            <InputSubmit name="searchbarsubmit" margin="2rem 0" type="submit" value="BUSCAR ARTICULO" />
            </div>
            </form>
            <div style={{margin:"0px 30px"}}>
            {
              Loading === false ?
                     searchbarresults.length !== 0 ?
                        <div className="site-card-wrapper cardbox-layout-general">
                          <MainTitle>Mostrando {searchbarresults.length} resultados de <strong>{searchbargeneral}</strong></MainTitle>
                            <Row gutter={16}>                
                                  {searchbarresults.map((result)=>{
                                    return(
                                    <Col span={8}>
                                      <CardLibrary info={result} key={result.id}></CardLibrary>
                                    </Col>
                                    )
                                  })}
                            </Row>
                        </div>
                        :
                        showresults ?
                        <div>
                        <Empty className="empty-box"  
                        description={
                          <span>
                           No hay Resultados actualmente
                          </span>
                        } />
                        </div>
                        :
                        <div>Haz tu primera busqueda</div>
                        :
                        <div>
                          <LoadingOutlined />
                        </div>
                }
            </div>
        </div>
        </div>
    )
}