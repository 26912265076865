import React, {useContext, useState, useEffect, useRef} from 'react'
import { Button, Form, Input, Tag, InputNumber } from 'antd'
import Amplify, { API, graphqlOperation, input, Storage } from 'aws-amplify'
import { Alert, notification } from 'antd';
import { useForm } from "react-hook-form";
import { createNewPlataforma } from '../../graphql/mutations'
import { listNewPlataformas } from '../../graphql/queries'
import {MainTitle, ErrorForm} from '../Datadisplay'
import {InputBar, InputContent,Label,InputSubmit, SelectElement,TextAreaElement,SelectOptionElement} from '../Input'
import { v4 as uuid } from 'uuid'
import config from '../../aws-exports'
import '../../styles/styles.css'

const {
    aws_user_files_s3_bucket_region: region,
    aws_user_files_s3_bucket: bucket
  } = config

const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} no es un numero valido!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };
const { TextArea } = Input;

export default function PlataformaForm(event){

    //States and Hooks
    const [alert, setAlert] = useState()
    const {register, handleSubmit, errors} = useForm()
    const [file, updateFile] = useState(null)
    const [PlataformaName, updatePlataformaName] = useState('')
    const [Plataformas, updatePlataformas] = useState([])
    let [FilePreviewImg, setFilePreviewImg] = useState()
    let [extensionName, setextensionName] = useState('')

    //Validaciones
    const SuccessAlert = () => {notification["success"]({
        message: 'Enhorabuena!',
        description:
          'Se ha creado el paper con exito',
    });
    }
    const ErrorAlert = () => {notification["error"]({
        message: 'Lo sentimos',
        description:
          'Verifica tu conexión de red o intentalo mas tarde',
    });
    }
    function onFinishFailed(errorInfo){
        ErrorAlert()
    };
    const removeAccents = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }

    useEffect(() => {
        listPlataforma()
    }, [])
    
    async function listPlataforma() {
        const plataformas = await API.graphql(graphqlOperation(listNewPlataformas))
        updatePlataformas(plataformas.data.listNewPlataformas.items)
    }
    
    function handleChange(event) {
      const filePreview = event.target.files[0]
      const filePreviewCreate = window.webkitURL.createObjectURL(filePreview)
      setFilePreviewImg(filePreviewCreate)
      const { target: { value, files } } = event
      const fileForUpload = files[0]
      updatePlataformaName(fileForUpload.name.split(".")[0])
      setextensionName(fileForUpload.name)
      updateFile(fileForUpload || value)
    }

    const GettingData = async (data,e) => {
        let inputData = null
        if (file) {
            const extension = file.name.split(".")[1]
            const { type: mimeType } = file
            const key = `images/plataformas/${uuid()}${PlataformaName}.${extension}`      
            const url = `https://${bucket}.s3.${region}.amazonaws.com/public/${key}`
            inputData = { name: PlataformaName , image: url }
    
            try {
                await Storage.put(key, file, {
                contentType: mimeType
                })
            } catch (err) {
                console.log('error: ', err)
            }
        }

        const result = {
            ...data,
            Nombre: removeAccents(data.Nombre).toLowerCase(),
            Nombre_orig: data.Nombre,
            Descripcion: removeAccents(data.Descripcion).toLowerCase(),
            Descripcion_orig: data.Descripcion,
            URL: removeAccents(data.URL).toLowerCase(),
            URL_orig: data.URL,
            Tipo: removeAccents(data.Tipo).toLowerCase(),
            Tipo_orig: data.Tipo,
            LogoURL: inputData.image,
            LogoURL_orig: inputData.image
        }
        await API.graphql(graphqlOperation(createNewPlataforma, {input: result}))
        setAlert(SuccessAlert)
        e.target.reset()
    }
    
    return(
        <div className="scale-in-hor-left">
            <MainTitle size="4rem" aligntext="left">Formulario de Plataforma</MainTitle>
            <form style={{paddingRight:"10%"}} onSubmit={handleSubmit(GettingData)}>

                <Label>Tipo de Plataforma</Label>

                <div style={{display:'inline-flex', width:"99%"}}>
                <InputBar/>
                    <SelectElement name="Tipo" defaultValue="Investigación Médica" ref={register}>
                        <SelectOptionElement value="Investigación Médica">Investigación Médica</SelectOptionElement>
                        <SelectOptionElement value="Patologias Raras">Patologias Raras</SelectOptionElement>
                        <SelectOptionElement value="Iniciativas en Investigacion">Iniciativas en Investigación</SelectOptionElement>
                        <SelectOptionElement value="Conexion de Pacientes">Conexión de Pacientes</SelectOptionElement>
                        <SelectOptionElement value="Medicamentos Huerfanos">Medicamentos Huerfanos</SelectOptionElement>
                    </SelectElement>
                </div>

                <Label>Nombre</Label>

                <div style={{display:'inline-flex', width:"99%"}}>
                    <InputBar/>
                    <InputContent name="Nombre"  placeholder="Nombre de la Plataforma" ref={register({ required: true })}/>
                </div>
                <div>{errors.Nombre && <ErrorForm>Este campo es requerido</ErrorForm>}</div>

                <Label>Logo</Label>

                <div style={{display:"block",width:"99%"}}>
                <input
                        placeholder='Nombre del archivo'
                        value={`${extensionName}`}
                        onChange={e => updatePlataformaName(e.target.files[0].name)}
                        style={{height:"100%", background:"transparent", border: "none"}}
                        name="LogoName"
                />
                <input
                        type="file"
                        onChange={handleChange}
                        style={{margin: '10px 0px'}}
                        ref={register({ required: true })}
                        id="org-upload"
                        name="LogoURL"
                    />
                <label htmlFor="org-upload">Cargar Archivo</label>
                </div>

                <img  src={FilePreviewImg} style={{width:"50%", marginTop:"3%"}}/>

                <Label>Descripción</Label>

                <div style={{display:'inline-flex', width:"99%"}}>
                    <InputBar/>
                    <TextAreaElement rows="10" cols="30" name="Descripcion" placeholder="Descripcion" ref={register} />
                </div>
                <div>{errors.Descripcion && <ErrorForm>Este campo es requerido</ErrorForm>}</div>

                <Label>URL</Label>

                <div style={{display:'inline-flex', width:"99%"}}>
                    <InputBar/>
                    <InputContent name="URL"  placeholder="url de sitio web y/o red social" ref={register}/>
                </div>

                <InputSubmit width="30%" height="30%" type="submit" value="CREAR PLATAFORMA" />
            </form>
        </div>
    )
}