import React,{useEffect, useState} from 'react'
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import styled from 'styled-components'
import {CardAudio, CardAudiovisualVideo} from '../../components/CardsDisplay'
import { useForm } from "react-hook-form";
import Amplify, { API, graphqlOperation } from 'aws-amplify'
import { listNewAudiovisuals, syncPapers } from '../../graphql/queries'
import {InputSubmit, InputBar, LabelBiblioteca} from '../../components/Input'
import {Row, Col} from 'antd'


const FilterArea = styled.div`
    width:100%;
    height:200px;
    background-color: black;
    padding:10px;
`
const MainTitleGeneral = styled.h1`
    font-family:"Poppins";
    font-size:2rem;
    font-weight:700;
    text-align:center;
    margin-top:5%;
`

export default function Media(){

    const {register, handleSubmit, errors, control, defaultChecked, reset} = useForm()
    const [mediaData, setmediaData] = useState()

    useEffect(async ()=>{
        const GeneralList = await API.graphql({query:listNewAudiovisuals})
        setmediaData(GeneralList.data.listNewAudiovisuals.items)
    },[])
    
    return(
        <div style={{marginTop:"10%"}} className="slide-in-left">
            <MainTitleGeneral>Recursos Audiovisuales</MainTitleGeneral>
            <Divider style={{width:"80%", alignContent:"center", height:"6px", margin:"0 10%", backgroundColor:"#6C0E60" }} variant="middle" />
            <Grid  container spacing={3} style={{padding:"0% 5% 5% 5%", width:"100%"}}>
                <MainTitleGeneral>Videos</MainTitleGeneral>
                <Divider style={{width:"100%", alignContent:"center", height:"6px", margin:"0% 0% 3% 0%", backgroundColor:"#6C0E60" }} variant="middle" />
                <Grid item xs={12} sm={12} md={12} lg={12} >
                    {
                        mediaData !== undefined ?
                        <div>
                            <Row gutter={16}>                
                                  {mediaData.map((result)=>{
                                    if(result.TipoAudiovisual_orig == "Video"){
                                    return(
                                    <Col span={8}>
                                      <CardAudiovisualVideo info={result}></CardAudiovisualVideo >
                                    </Col>
                                    )
                                    }
                                    else{return null}
                                  })}
                            </Row>
                        </div>
                        :
                        <div>Cargando....</div>
                    } 
                </Grid>
                <MainTitleGeneral>Audios</MainTitleGeneral>
                <Divider style={{width:"100%", alignContent:"center", height:"6px", margin:"3% 0%", backgroundColor:"#6C0E60" }} variant="middle" />
                <Grid item xs={12} sm={12} md={12} lg={12} >
                    {
                        mediaData !== undefined ?
                        <div>
                            <Row gutter={16}>                
                                  {mediaData.map((result)=>{
                                    if(result.TipoAudiovisual_orig == "Audio"){
                                    return(
                                    <Col span={8}>
                                      <CardAudio info={result}></CardAudio >
                                    </Col>
                                    )
                                    }
                                  })}
                            </Row>
                        </div>
                        :
                        <div>Cargando....</div>
                    } 
                </Grid>
            </Grid>
        </div>
    )
}
