import React, {useContext, useState, useEffect, useRef} from 'react'
import { Button, Form, Input, Tag, InputNumber } from 'antd'
import Amplify, { API, graphqlOperation } from 'aws-amplify'
import { Alert, notification } from 'antd';
import { useForm } from "react-hook-form";
import { createNewAudiovisual } from '../../graphql/mutations'
import {MainTitle, ErrorForm} from '../Datadisplay'
import {InputBar, InputContent,Label,InputSubmit, SelectElement,TextAreaElement,SelectOptionElement} from '../Input'

const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} no es un numero valido!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };
const { TextArea } = Input;

export default function AudioVisualForm(event){

    //States and Hooks
    const [alert, setAlert] = useState()
    const {register, handleSubmit, errors} = useForm()


    //Validaciones
    const SuccessAlert = () => {notification["success"]({
        message: 'Enhorabuena!',
        description:
          'Se ha creado el paper con exito',
    });
    }
    const ErrorAlert = () => {notification["error"]({
        message: 'Lo sentimos',
        description:
          'Verifica tu conexión de red o intentalo mas tarde',
    });
    }
    function onFinishFailed(errorInfo){
        ErrorAlert()
    };
    const removeAccents = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }

    const GettingData = async (data,e) => {
        const result = {
            ...data,
            TipoAudiovisual: removeAccents(data.TipoAudiovisual).toLowerCase(),
            TipoAudiovisual_orig: data.TipoAudiovisual,
            Nombre: removeAccents(data.Nombre).toLowerCase(),
            Nombre_orig: data.Nombre,
            Fecha: data.Fecha,
            Fecha_orig: data.Fecha,
            URL: removeAccents(data.URL).toLowerCase(),
            URL_orig: data.URL
        }
        await API.graphql(graphqlOperation(createNewAudiovisual, {input: result}))
        setAlert(SuccessAlert)
        e.target.reset()
    }
    
    return(
        <div className="scale-in-hor-left">
            <MainTitle size="4rem" aligntext="left">Formulario Recursos Audiovisuales</MainTitle>
            <form style={{paddingRight:"10%"}} onSubmit={handleSubmit(GettingData)}>

                <Label>Tipo de AudioVisual</Label>

                <div style={{display:'inline-flex', width:"99%"}}>
                    <InputBar/>
                    <SelectElement name="TipoAudiovisual" defaultValue="Video" ref={register}>
                        <SelectOptionElement value="Video">Video</SelectOptionElement>
                        <SelectOptionElement value="Imagen">Imagen</SelectOptionElement>
                        <SelectOptionElement value="Audio">Audio</SelectOptionElement>
                    </SelectElement>
                </div>

                <Label>Nombre</Label>

                <div style={{display:'inline-flex', width:"99%"}}>
                    <InputBar/>
                    <InputContent name="Nombre"  placeholder="Nombre del Video/Audio/Imagen" ref={register({ required: true })}/>
                </div>
                <div>{errors.titulo && <ErrorForm>Este campo es requerido</ErrorForm>}</div>

                <Label>Fecha (Año)</Label>

                <div style={{display:'inline-flex', width:"99%"}}>
                    <InputBar/>
                    <InputContent name="Fecha" placeholder="1900-2099" min="1900" max="2099" step="1" ref={register}/>
                </div>
                
                <Label>URL del AudioVisual</Label>

                <div style={{display:'inline-flex', width:"99%"}}>
                    <InputBar/>
                    <InputContent name="URL"  placeholder="www.img.com" ref={register}/>
                </div>

                {/* <Label>URL AudioVisual S3</Label>

                 METER INPUT upload DE LOGO 

                <div style={{display:'inline-flex', width:"99%"}}>
                    <InputBar/>
                    <InputContent name="LogoURL"  placeholder="www.img.com" ref={register}/>
                </div> */}

                <InputSubmit width="30%" height="30%" type="submit" value="CREAR ARCHIVO MEDIA" />
            </form>
        </div>
    )
}